import React from "react";
import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import useStyles, { Wrapper } from "./styles";

const CustomLoading = (props) => {
  const {
    size = 32,
    isOpen,
    zIndex = "drawer",
    isElement = false,
    fullScreen = false,
    backdropFilter = "blur(5px)",
    backgroundColor,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  if (fullScreen) {
    return (
      <Backdrop className={classes.backdrop} open={isOpen}>
        <CircularProgress size={theme.coefficient * 50} />
      </Backdrop>
    );
  }

  if (isElement && isOpen)
    return (
      <CircularProgress
        className={classes.root}
        size={theme.coefficient * size}
      />
    );

  return isOpen ? (
    <Wrapper
      $backdrop_filter={backdropFilter}
      backgroundColor={backgroundColor}
      $z_index={zIndex}
    >
      <Wrapper.Inner>
        <CircularProgress size={theme.coefficient * 40} />
      </Wrapper.Inner>
    </Wrapper>
  ) : null;
};

export default CustomLoading;
