import { IGetManyParams } from "@/shared/types";
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

export const SLICE_NAME = "select";

export interface ISelectData<T> {
  items: T[]; // LoincBiomarkers for example or any other type
  count: number;
}

export type InitialStateType<T> = {
  data: ISelectData<T>;
  params: IGetManyParams;
  isLoading: boolean;
};

type DataPayload<T> = {
  data: ISelectData<T>;
};

type ParamsPayload = {
  params: IGetManyParams;
};

type LoadingPayload = {
  isLoading: boolean;
};

export type CaseReducers<State, T> = {
  setData: CaseReducer<State, PayloadAction<DataPayload<T>>>;
  setParams: CaseReducer<State, PayloadAction<ParamsPayload>>;
  setIsLoading: CaseReducer<State, PayloadAction<LoadingPayload>>;
};
