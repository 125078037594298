import React from "react";
import { Helmet as ReactHelmet, HelmetProvider } from "react-helmet-async";

type HelmetProps = {
  title?: string;
  defer?: boolean;
};

export function Helmet({ title = "MRN", defer = false }: HelmetProps) {
  return (
    <HelmetProvider>
      <ReactHelmet defer={defer}>
        <link rel="icon" type="image/ico" href="public/favicon.ico" />
        <link rel="apple-touch-icon" sizes="76x76" href="public/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="public/favicon.ico"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="public/favicon.ico"
        />
        <link rel="mask-icon" href="public/favicon.ico" color="#5bbad5" />
        <title>{title}</title>
      </ReactHelmet>
    </HelmetProvider>
  );
}
