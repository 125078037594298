import { call } from "redux-saga/effects";
import { UUID } from "crypto";

import { API_URL } from "@/shared/api/apiUrl";
import { sendRequest } from "@/shared/api/requestSender";
import { HttpMethod, IStorageFacility } from "@/shared/types";

import {
  GetStorageFacilityResponse,
  CreateStorageFacilityResponse,
  DeleteStorageFacilityResponse,
  UpdateStorageFacilityResponse,
  GetManyStorageFacilitiesResponse,
} from "./types/types";
import { IGetManyStorageFacilitiesParams } from "../model/types/interfaces";

const sendCreateStorageFacilityRequest = (
  body: Partial<IStorageFacility> | unknown
) =>
  sendRequest({
    path: API_URL.storageFacilities.createOne,
    method: HttpMethod.POST,
    data: body,
  });

const sendGetStorageFacilityRequest = (facilityId: UUID) =>
  sendRequest({
    path: `${API_URL.storageFacilities.getOne}/${facilityId}`,
    method: HttpMethod.GET,
  });

const sendUpdateStorageFacilityRequest = (
  facilityId: UUID,
  body: Partial<IStorageFacility> | unknown
) =>
  sendRequest({
    path: `${API_URL.storageFacilities.updateOne}/${facilityId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

const sendDeleteStorageFacilityRequest = (facilityId: UUID) =>
  sendRequest({
    path: `${API_URL.storageFacilities.deleteOne}/${facilityId}`,
    method: HttpMethod.DELETE,
  });

const sendGetManyStorageFacilitiesRequest = (
  body: IGetManyStorageFacilitiesParams
) =>
  sendRequest({
    path: API_URL.storageFacilities.getMany,
    method: HttpMethod.POST,
    data: body,
  });

export function* createStorageFacility(dto: Partial<IStorageFacility>) {
  try {
    const response: CreateStorageFacilityResponse = yield call(
      sendCreateStorageFacilityRequest,
      dto
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("CREATE STORAGE FACILITIES ERROR", err);
  }
}

export function* getStorageFacility(facilityId: UUID) {
  try {
    const response: GetStorageFacilityResponse = yield call(
      sendGetStorageFacilityRequest,
      facilityId
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("GET STORAGE FACILITY ERROR", err);
  }
}

export function* updateStorageFacility(
  facilityId: UUID,
  dto: Partial<IStorageFacility>
) {
  try {
    const response: UpdateStorageFacilityResponse = yield call(
      sendUpdateStorageFacilityRequest,
      facilityId,
      dto
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("UPDATE STORAGE FACILITY ERROR", err);
  }
}

export function* deleteStorageFacility(facilityId: UUID) {
  try {
    const response: DeleteStorageFacilityResponse = yield call(
      sendDeleteStorageFacilityRequest,
      facilityId
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.error("DELETE STORAGE FACILITY ERROR", err);
  }
}

export function* getManyStorageFacilities(
  body: IGetManyStorageFacilitiesParams
) {
  try {
    const response: GetManyStorageFacilitiesResponse = yield call(
      sendGetManyStorageFacilitiesRequest,
      body
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("RETRIEVE STORAGE FACILITIES GET MANY ERROR", error);
  }
}
