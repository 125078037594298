import { Route, Routes } from "react-router-dom";
import { ROUTER_PATHS } from "@/shared/config/router-paths";
import {
  CartPage,
  OrdersPage,
  ProfilePageCA as ProfilePage,
  ContactUsPageCA as ContactUsPage,
  SpecimenSearchPage,
} from "@/pages";

export function ClientAreaRouter() {
  const { cart, orders, profile, contactUs, specimenSearch } =
    ROUTER_PATHS.clientArea;

  return (
    <Routes>
      <Route path={cart.path} element={<CartPage />} />
      <Route path={orders.path} element={<OrdersPage />} />
      <Route path={profile.path} element={<ProfilePage />} />
      <Route path={contactUs.path} element={<ContactUsPage />} />
      <Route path={specimenSearch.path} element={<SpecimenSearchPage />} />
    </Routes>
  );
}
