import { BrowserRouter as AppBrowserRouter } from "react-router-dom";

import {
  AppStateProvider,
  AppThemeProvider,
  AppAlertsProvider,
  AppRouterProvider,
  // AppAdapterProvider,
  AppTranslateProvider,
} from "./providers";

export const App = () => {
  return (
    // <AppAdapterProvider>
    <AppTranslateProvider>
      <AppStateProvider>
        <AppThemeProvider>
          <AppAlertsProvider>
            <AppBrowserRouter>
              <AppRouterProvider />
            </AppBrowserRouter>
          </AppAlertsProvider>
        </AppThemeProvider>
      </AppStateProvider>
    </AppTranslateProvider>
    // </AppAdapterProvider>
  );
};
