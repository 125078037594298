import { UUID } from "crypto";
import { sendRequest } from "@/shared/api/requestSender";
import { API_URL } from "@/shared/api/apiUrl";
import { HttpMethod, IUser } from "@/shared/types";
import { IGetManyUsersParams } from "../model/types/interfaces";

export const sendCreateUserRequest = (body: Partial<IUser> | unknown) =>
  sendRequest({
    path: API_URL.users.createOne,
    method: HttpMethod.POST,
    data: body,
  });

export const sendUpdateUserAvatarRequest = (body: Partial<any> | unknown) =>
  sendRequest({
    path: API_URL.users.updateAvatar,
    method: HttpMethod.POST,
    data: body,
  });

export const sendDeleteUserAvatarRequest = () =>
  sendRequest({
    path: API_URL.users.deleteAvatar,
    method: HttpMethod.DELETE,
  });

export const sendGetUserRequest = (userId: UUID) =>
  sendRequest({
    path: `${API_URL.users.getOne}/${userId}`,
    method: HttpMethod.GET,
  });

export const sendUpdateUserRequest = (
  userId: UUID,
  body: Partial<IUser> | unknown
) =>
  sendRequest({
    path: `${API_URL.users.updateOne}/${userId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

export const sendDeleteUserRequest = (userId: UUID) =>
  sendRequest({
    path: `${API_URL.users.deleteOne}/${userId}`,
    method: HttpMethod.DELETE,
  });

export const sendGetManyUsersRequest = (body: IGetManyUsersParams) =>
  sendRequest({
    path: API_URL.users.getMany,
    method: HttpMethod.POST,
    data: body,
  });
