import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";

import { ActionType } from "./types/actionType";
import {
  GetVendorPayload,
  CreateVendorPayload,
  DeleteVendorPayload,
  UpdateVendorPayload,
  GetManyVendorsPayload,
} from "./types/types";

export const createVendor = createAction<CreateVendorPayload>(
  ActionType.CREATE_VENDOR
);

export const updateVendor = createAction<UpdateVendorPayload>(
  ActionType.UPDATE_VENDOR
);

export const deleteVendor = createAction<DeleteVendorPayload>(
  ActionType.DELETE_VENDOR
);

export const retrieveVendor = createAction<GetVendorPayload>(
  ActionType.RETRIEVE_VENDOR
);

export const retrieveManyVendors = createAction<GetManyVendorsPayload>(
  ActionType.RETRIEVE_MANY_VENDORS
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      createVendor,
      updateVendor,
      deleteVendor,
      retrieveVendor,
      retrieveManyVendors,
    },
    dispatch
  );
};

export const sagaActions = {
  createVendor,
  updateVendor,
  deleteVendor,
  retrieveVendor,
  retrieveManyVendors,
};
