import React from "react";
import { FooterWrapper } from "./styles";

export function SidebarFooter({
  children,
}: React.PropsWithChildren): JSX.Element {
  return <FooterWrapper>{children}</FooterWrapper>;
}

export default SidebarFooter;
