import { ICart, IClientCompany, IEntity, ITabItem, RootState } from '@/shared/types';

import { ICurrentUser, IUserConditions } from './types/interfaces';

export const selectActiveTab = (state: RootState): ITabItem | null =>
  state.common.activeTab;

export const selectCurrentUser = (state: RootState): ICurrentUser =>
  state.common.currentUser;

export const selectClientCompany = (state: RootState): IClientCompany | null =>
  state.common.userConditions.clientCompany;

export const selectActiveEntity = (state: RootState): IEntity | null =>
  state.common.activeEntity;

export const selectNavigationTabs = (state: RootState): Array<ITabItem> | [] =>
  state.common.navigationTabs;

export const selectUserCart = (state: RootState): ICart | null =>
  state.common.userConditions.cart;

export const selectIsLoading = (state: RootState): boolean =>
  state.common.isLoading;
