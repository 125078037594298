import { createSlice } from "@reduxjs/toolkit";
import { InitialStateType, SLICE_NAME, CaseReducers } from "./types/types";
import { GetManySamplesStatus } from '@/shared/types';

const INITIAL_STATE: InitialStateType = {
  data: { items: [], count: 0 },
  //TODO: temp solution
  params: { status: GetManySamplesStatus.AVAILABLE },
  availableSamples: [],
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSamples: (draft, { payload }) => {
      draft.data = payload.data;
    },
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
    setAvailableSamples: (draft, { payload }) => {
      draft.availableSamples = payload.data;
    },
  },
});

export const samplesSlice = slice.actions;
export const samplesReducer = slice.reducer;
