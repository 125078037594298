import { put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { alertsSlice } from "@/entities/alerts";

import { AlertPayload } from "./types/types";

export function* handleUpdateAlert(action: PayloadAction<AlertPayload>) {
  const { setAlert } = alertsSlice;
  const { status, message } = action.payload;

  yield put(setAlert({ status, message }));
}

export function* handleResetAlerts() {
  const { resetAlerts } = alertsSlice;

  yield put(resetAlerts());
}
