import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";

import { AlertPayload } from "./types/types";
import { ActionType } from "./types/actionType";

const updateAlert = createAction<AlertPayload>(ActionType.UPDATE_ALERT);

const resetAlerts = createAction(ActionType.RESET_ALERTS);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      updateAlert,
      resetAlerts,
    },
    dispatch
  );
};

export const sagaActions = {
  updateAlert,
  resetAlerts,
};
