import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarKey } from "notistack";
import { Inner, Wrapper, Message, IconClose, Container } from "../../styles";
import { IconWarning, IconContainerWarning } from "./styles";

interface WarningProps {
  id: SnackbarKey;
  message: string;
}

export const WarningAlert = forwardRef<HTMLDivElement, WarningProps>(
  (props, ref) => {
    const { id, message } = props;

    const { closeSnackbar } = useSnackbar();

    const onClose = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <Wrapper ref={ref}>
        <Container bgRgbColor="value_34" borderColor="value_36">
          <Inner>
            <IconContainerWarning>
              <IconWarning />
            </IconContainerWarning>
            <Message color="value_36">{message}</Message>
          </Inner>
          <IconClose onClick={onClose} backgroundColor="value_36" />
        </Container>
      </Wrapper>
    );
  }
);
