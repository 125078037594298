import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ICart, IEntity, ITabItem } from "@/shared/types";
import { ICurrentUser, IUserConditions } from "./interfaces";

export const SLICE_NAME = "common";

export type InitialStateType<E extends IEntity> = {
  activeTab: ITabItem | null;
  currentUser: ICurrentUser;
  activeEntity: E | null;
  navigationTabs: Array<ITabItem> | [];
  userConditions: IUserConditions;
  // Move to Table widget
  isLoading: boolean;
};

type ActiveTabPayload = {
  activeTab: ITabItem | null;
};

type CurrentUserPayload = {
  currentUser: ICurrentUser;
};

type ActiveEntityPayload<E extends IEntity> = {
  activeEntity: E | null;
};

type NavigationTabsPayload = {
  navigationTabs: Array<ITabItem> | [];
};

type IUserCartPayload = {
  cart: ICart | null;
};

type IUserConditionsPayload = {
  conditions: IUserConditions;
};

type LoadingPayload = {
  isLoading: boolean;
};

export type CaseReducers<State> = {
  setActiveTab: CaseReducer<State, PayloadAction<ActiveTabPayload>>;
  setCurrentUser: CaseReducer<State, PayloadAction<CurrentUserPayload>>;
  setActiveEntity: CaseReducer<
    State,
    PayloadAction<ActiveEntityPayload<IEntity>>
  >;
  setNavigationTabs: CaseReducer<State, PayloadAction<NavigationTabsPayload>>;
  setUserCart: CaseReducer<State, PayloadAction<IUserCartPayload>>;
  setUserConditions: CaseReducer<State, PayloadAction<IUserConditionsPayload>>;
  //ToDo move to Table widget
  setLoading: CaseReducer<State, PayloadAction<LoadingPayload>>;
};
