import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
// import { ILoincBiomarker } from "@/shared/types";
import { IBiomarkersData, IGetManyBiomarkersParams } from "./interfaces";

export const SLICE_NAME = "biomarkers";
//
export type InitialStateType = {
  data: IBiomarkersData;
  params: IGetManyBiomarkersParams;
  // activeBiomarker: ILoincBiomarker | null;
};

type BiomarkersDataPayload = {
  biomarkers: IBiomarkersData;
};

type GetManyBiomarkersParamsPayload = {
  params: IGetManyBiomarkersParams;
};

// type ActiveBiomarkerPayload = {
//   activeBiomarker: ILoincBiomarker | null;
// };

export type CaseReducers<State> = {
  setParams: CaseReducer<State, PayloadAction<GetManyBiomarkersParamsPayload>>;
  setBiomarkers: CaseReducer<State, PayloadAction<BiomarkersDataPayload>>;
  // setActiveBiomarker: CaseReducer<State, PayloadAction<ActiveBiomarkerPayload>>;
};
