import { createSlice } from "@reduxjs/toolkit";

import { InitialStateType, SLICE_NAME, CaseReducers } from "./types/types";

const INITIAL_STATE: InitialStateType = {
  data: { items: [], count: 0 },
  shippingLocations: { items: [], count: 0 },
  params: {},
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
    setClientCompanies: (draft, { payload }) => {
      draft.data = payload.clientCompanies;
    },
    setShippingLocations: (draft, { payload }) => {
      draft.shippingLocations = payload.shippingLocations;
    },
  },
});

export const clientCompaniesSlice = slice.actions;
export const clientCompaniesReducer = slice.reducer;
