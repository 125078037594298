import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME, InitialStateType, CaseReducers } from "./types/types";

export const INITIAL_STATE: InitialStateType = {
  data: {
    items: [],
    count: 0,
  },
  params: {},
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setChats: (draft, { payload }) => {
      draft.data = payload.data;
    },
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
  },
});

export const chatsSlice = slice.actions;
export const chatsReducer = slice.reducer;
