import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleClearAreaItems,
  handleAreaItemClicked,
  handleGetManyAreaItems,
  handleGetAllBodySystems,
  handleGetAllResearchAreas,
  handleRetrieveManyBiomarkers,
  handleRetrieveManyBiomarkersByBodySystem,
} from './workers';

export function* researchAreasWatcher() {
  yield takeLatest(ActionType.CLEAR_AREA_ITEMS, handleClearAreaItems);
  yield takeLatest(
    ActionType.GET_ALL_RESEARCH_AREAS,
    handleGetAllResearchAreas
  );
  yield takeLatest(
    ActionType.GET_ALL_BODY_SYSTEMS,
    handleGetAllBodySystems
  );
  yield takeLatest(ActionType.GET_MANY_AREA_ITEMS, handleGetManyAreaItems);
  yield takeLatest(ActionType.AREA_ITEM_CLICKED, handleAreaItemClicked);
  yield takeLatest(
    ActionType.RETRIEVE_MANY_BIOMARKERS,
    handleRetrieveManyBiomarkers
  );
  yield takeLatest(
    ActionType.RETRIEVE_MANY_BIOMARKERS_BY_BODY_SYSTEM,
    handleRetrieveManyBiomarkersByBodySystem
  );
}
