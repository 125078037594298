import { RootState } from "@/shared/types";
import { IGetManyClientCompaniesParams, IClientCompaniesData } from "./types/interfaces";

export const selectParams = (state: RootState): IGetManyClientCompaniesParams =>
  state.clientCompanies.params;

export const selectClientCompanies = (state: RootState): IClientCompaniesData =>
  state.clientCompanies.data;

export const selectShippingLocations = (state: RootState): IClientCompaniesData =>
  state.clientCompanies.shippingLocations;
