import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";

import { ActionType } from "./types/actionType";
import {
  GetChatPayload,
  DeleteChatPayload,
  UpdateChatPayload,
  GetManyChatsPayload,
  SendMessagePayload,
} from "./types/types";

export const updateChat = createAction<UpdateChatPayload>(
  ActionType.UPDATE_CHAT
);

export const deleteChat = createAction<DeleteChatPayload>(
  ActionType.DELETE_CHAT
);

export const retrieveChat = createAction<GetChatPayload>(
  ActionType.RETRIEVE_CHAT
);

export const retrieveManyChats = createAction<GetManyChatsPayload>(
  ActionType.RETRIEVE_MANY_CHATS
);

export const sendMessageAuthorized = createAction<SendMessagePayload>(
  ActionType.SEND_MESSAGE_AUTHORIZED
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      updateChat,
      deleteChat,
      retrieveChat,
      retrieveManyChats,
      sendMessageAuthorized,
    },
    dispatch
  );
};

export const sagaActions = {
  updateChat,
  deleteChat,
  retrieveChat,
  retrieveManyChats,
  sendMessageAuthorized,
};
