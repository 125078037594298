import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import { CardPayload, UserPayload, ActiveTabValuePayload } from "./types/types";

const userLoggedIn = createAction<UserPayload>(ActionType.USER_LOGGED_IN);

const userLoggedOut = createAction(ActionType.USER_LOGGED_OUT);

const navigationTabClicked = createAction<ActiveTabValuePayload>(
  ActionType.NAVIGATION_TAB_CLICKED
);

const removeCartProduct = createAction<CardPayload>(
  ActionType.REMOVE_PRODUCT_FROM_CART
);

const placeOrder = createAction<CardPayload>(
  ActionType.PLACE_ORDER
);

const addSampleToCartClicked = createAction<CardPayload>(
  ActionType.ADD_SAMPLE_TO_CART_CLICKED
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      userLoggedIn,
      userLoggedOut,
      navigationTabClicked,
      addSampleToCartClicked,
    },
    dispatch
  );
};

export const sagaActions = {
  placeOrder,
  userLoggedIn,
  userLoggedOut,
  removeCartProduct,
  navigationTabClicked,
  addSampleToCartClicked,
};
