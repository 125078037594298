import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import { ClickedAreaItemPayload, GetManyAreaItemsPayload } from "./types/types";
import { GetManyBiomarkersPayload } from '@/entities/biomarkers/api/types/types';

export const getAllResearchAreas = createAction(
  ActionType.GET_ALL_RESEARCH_AREAS
);

export const getAllBodySystems = createAction(
  ActionType.GET_ALL_BODY_SYSTEMS
);

export const getManyAreaItems = createAction<GetManyAreaItemsPayload>(
  ActionType.GET_MANY_AREA_ITEMS
);

export const clearAreaItems = createAction(ActionType.CLEAR_AREA_ITEMS);

export const areaItemClicked = createAction<ClickedAreaItemPayload>(
  ActionType.AREA_ITEM_CLICKED
);

const retrieveManyBiomarkers = createAction<
  GetManyBiomarkersPayload | undefined
>(ActionType.RETRIEVE_MANY_BIOMARKERS);

const retrieveManyBiomarkersByBodySystem = createAction<
  GetManyBiomarkersPayload | undefined
>(ActionType.RETRIEVE_MANY_BIOMARKERS_BY_BODY_SYSTEM);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      clearAreaItems,
      areaItemClicked,
      getManyAreaItems,
      getAllResearchAreas,
      getAllBodySystems,
      retrieveManyBiomarkers,
      retrieveManyBiomarkersByBodySystem,
    },
    dispatch
  );
};

export const sagaActions = {
  retrieveManyBiomarkers,
};
