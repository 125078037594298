import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleCreateSample,
  handleUpdateSample,
  handleDeleteSample,
  handleCustomRequest,
  handleRetrieveSample,
  handlePlaceCustomRequest,
  handleRetrieveManySamples,
} from "./workers";

export function* watcher() {
  yield takeLatest(ActionType.GET_SAMPLE, handleRetrieveSample);
  yield takeLatest(ActionType.CREATE_SAMPLE, handleCreateSample);
  yield takeLatest(ActionType.UPDATE_SAMPLE, handleUpdateSample);
  yield takeLatest(ActionType.DELETE_SAMPLE, handleDeleteSample);
  yield takeLatest(ActionType.GET_MANY_SAMPLES, handleRetrieveManySamples);
  yield takeLatest(ActionType.CUSTOM_REQUEST, handleCustomRequest);
  yield takeLatest(ActionType.PLACE_CUSTOM_REQUEST, handlePlaceCustomRequest);
}
