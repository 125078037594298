import React, { useMemo, useRef } from "react";
import { SnackbarProvider } from "notistack";
// import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";

import { selectAlerts, useAlertsActions } from "@/entities/alerts";

import { MAX_SNACK, AUTO_HIDE_DURATION, ANCHOR_ORIGIN } from "./lib/constants";
// import { IconCloseI } from "./ui/styles";
import { AlertsAdapter } from "./ui/AlertsAdapter";
import { InfoAlert } from "./ui/components/info/InfoAlert";
import { SuccessAlert } from "./ui/components/success/SuccessAlert";
import { ErrorAlert } from "./ui/components/error/ErrorAlert";
import { WarningAlert } from "./ui/components/warning/WarningAlert";

export function AppAlertsProvider({ children }: React.PropsWithChildren) {
  const messages = useSelector(selectAlerts);
  const { resetAlerts } = useAlertsActions();
  const notistackRef = useRef<any>(null);

  const onClearMessages = () => resetAlerts();

  const alertsOptions = useMemo(
    () => ({
      ref: notistackRef,
      maxSnack: MAX_SNACK,
      autoHideDuration: AUTO_HIDE_DURATION,
      anchorOrigin: ANCHOR_ORIGIN,
      // action: (key: any) => (
      //   <IconButton onClick={() => notistackRef.current.closeSnackbar(key)}>
      //     <IconCloseI />
      //   </IconButton>
      // ),
    }),
    []
  );

  const Components = {
    info: InfoAlert,
    error: ErrorAlert,
    warning: WarningAlert,
    success: SuccessAlert,
    default: SuccessAlert,
  };

  return (
    <SnackbarProvider Components={Components} {...alertsOptions}>
      <AlertsAdapter messages={messages} clearMessages={onClearMessages} />
      {children}
    </SnackbarProvider>
  );
}
