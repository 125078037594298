import { createSlice } from "@reduxjs/toolkit";

import { getItemFromLS } from "@/shared/utils/localStorageHelpers";
import { StorageKeys, IEntity, UserRole } from "@/shared/types";

import { SLICE_NAME, InitialStateType, CaseReducers } from "./types/types";

const ACTIVE_TAB = getItemFromLS(StorageKeys.ACTIVE_TAB);

const INITIAL_STATE: InitialStateType<IEntity> = {
  activeTab: ACTIVE_TAB ? JSON.parse(ACTIVE_TAB) : null, // move to Nav-Bar widget
  currentUser: { role: UserRole.UNAUTHORIZED },
  activeEntity: null,
  navigationTabs: [], // move to Nav-Bar widget
  userConditions: { cart: null, clientCompany: null },
  isLoading: false, // Move to Table widget
};

const slice = createSlice<
  InitialStateType<IEntity>,
  CaseReducers<InitialStateType<IEntity>>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setActiveTab: (draft, { payload }) => {
      draft.activeTab = payload.activeTab;
    },
    setCurrentUser: (draft, { payload }) => {
      draft.currentUser = payload.currentUser;
    },
    setActiveEntity: (draft, { payload }) => {
      draft.activeEntity = payload.activeEntity;
    },
    setNavigationTabs: (draft, { payload }) => {
      draft.navigationTabs = payload.navigationTabs;
    },
    setUserCart: (draft, { payload }) => {
      draft.userConditions = { ...draft.userConditions, cart: payload.cart };
    },
    setUserConditions: (draft, { payload }) => {
      draft.userConditions = payload.conditions;
    },
    setLoading: (draft, { payload }) => {
      draft.isLoading = payload.isLoading;
    },
  },
});

export const commonSlice = slice.actions;
export const commonReducer = slice.reducer;
