import { call, put, select, delay } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

import {
  ISample,
  IApiError,
  ToggleActionType,
  AlertStatusMessage, DialogMode,
} from '@/shared/types';

import { commonSlice } from "@/entities/common";
import { alertsSagaActions } from "@/entities/alerts";

import { dialogSagaActions, dialogSlice } from "@/widgets/dialog";
import { asideSagaActions, asideSlice } from "@/widgets/side-block";

import { selectParams } from "../model/selectors";
import { samplesSlice } from "../model/slice";
import { IGetManySamplesParams } from "../model/types/interfaces";
import {
  getCreateDtoByFormData,
  getUpdateDtoByFormData,
  parseDate,
} from "./helpers";
import {
  sendCustomRequest,
  sendGetSampleRequest,
  sendPlaceCustomRequest,
  sendCreateSampleRequest,
  sendUpdateSampleRequest,
  sendDeleteSampleRequest,
  sendGetManySamplesRequest,
} from './senders';
import {
  GetSamplePayload,
  GetSampleResponse,
  CreateSamplePayload,
  UpdateSamplePayload,
  DeleteSamplePayload,
  CustomRequestPayload,
  GetManySamplesPayload,
  GetManySamplesResponse,
  PlaceCustomRequestPayload,
} from './types/types';

export function* handleCreateSample(
  action: PayloadAction<CreateSamplePayload>
) {
  const { formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const createDto: Partial<ISample> = yield call(
    getCreateDtoByFormData,
    formData
  );

  try {
    yield call(sendCreateSampleRequest, createDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("sample_created_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManySamples);
  }
}

export function* handleUpdateSample(
  action: PayloadAction<UpdateSamplePayload>
) {
  const { sampleId, formData } = action.payload;

  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const updateDto: Partial<ISample> = yield call(
    getUpdateDtoByFormData,
    formData
  );

  try {
    yield call(sendUpdateSampleRequest, sampleId, updateDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("sample_updated_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManySamples);
  }
}

export function* handleRetrieveSample(action: PayloadAction<GetSamplePayload>) {
  const { sampleId } = action.payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;
  const { setLoadingDialog } = dialogSlice;

  try {
    const response: GetSampleResponse = yield call(
      sendGetSampleRequest,
      sampleId
    );
    const sample: ISample = response.data.data;

    const modifiedSample = {
      ...sample,
      donorDob: sample.donorDob,
      collectionDate: sample.collectionDate,
      expirationDate: sample.expirationDate,
    };

    yield put(setActiveEntity({ activeEntity: modifiedSample }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
    yield put(setLoadingDialog({ isLoading: false }));
  }
}

export function* handleCustomRequest(action: PayloadAction<CustomRequestPayload>) {
  const { setLoadingAside } = asideSlice;
  const { setAvailableSamples } = samplesSlice;

  try {
    yield put(setLoadingAside({ isLoading: true }));
    yield delay(1000);
    const response: GetSampleResponse = yield call(
      sendCustomRequest,
      action.payload
    );
    const samples: any = response.data.data;

    yield put(setAvailableSamples({ data: samples }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
  }
}

export function* handlePlaceCustomRequest(action: PayloadAction<PlaceCustomRequestPayload>) {
  const { setLoadingAside } = asideSlice;
  const { setAvailableSamples } = samplesSlice;
  const { toggleDialog } = dialogSagaActions;
  const { setLoadingDialog } = dialogSlice;

  try {
    yield put(setLoadingDialog({ isLoading: true }))
    // yield put(setLoadingAside({ isLoading: true }));
    yield delay(1500);
    const response: GetSampleResponse = yield call(
      sendPlaceCustomRequest,
      action.payload
    );

    const samples: any = response.data.data;
    yield put(setAvailableSamples({ data: samples }));
    yield put(setLoadingDialog({ isLoading: false }));
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield delay(1500);
    yield put(toggleDialog({ status: ToggleActionType.OPEN, mode: DialogMode.CONFIRM }));
  } catch (err) {
    yield put(setLoadingDialog({ isLoading: false }));
    yield call(handleError, err);
  }
}

export function* handleRetrieveManySamples(
  action?: PayloadAction<GetManySamplesPayload>
) {
  const { setSamples, setParams } = samplesSlice;
  const { setLoading } = commonSlice;

  if (action?.payload?.params) {
    yield put(setParams({ params: action.payload.params }));
  }

  try {
    yield put(setLoading({ isLoading: true }));
    const bodyParams: IGetManySamplesParams = yield select(selectParams);

    const response: GetManySamplesResponse = yield call(
      sendGetManySamplesRequest,
      bodyParams
    );
    const { items, count } = response.data.data;

    yield put(setSamples({ data: { items, count } }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

export function* handleDeleteSample(
  action: PayloadAction<DeleteSamplePayload>
) {
  const { sampleId } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  try {
    yield call(sendDeleteSampleRequest, sampleId as number);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("sample_deleted_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManySamples);
  }
}

function* handleError(err: any) {
  const { updateAlert } = alertsSagaActions;
  const error = err as IApiError;

  if (error?.response?.data?.error?.message?.length) {
    for (const text of error.response.data.error.message) {
      yield put(
        updateAlert({ status: AlertStatusMessage.ERROR, message: text })
      );
    }
  }
}
