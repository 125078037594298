import { ITabItem } from "@/shared/types";
import { ROUTER_PATHS } from "./router-paths";

// Back-Office (BO)
export const NAVIGATION_TABS_BO: Array<ITabItem> = [
  {
    id: 0,
    name: "Inventory",
    render: true,
    translateKey: "inventory",
    path: ROUTER_PATHS.backOffice.inventory.path,
  },
  {
    id: 1,
    name: "Users",
    render: true,
    translateKey: "users",
    path: ROUTER_PATHS.backOffice.users.path,
  },
  // {
  //   id: 2,
  //   name: "Research areas",
  //   render: true,
  //   translateKey: "research_areas",
  //   path: ROUTE_PATH.backOffice.researchAreas.path,
  // },
  // {
  //   id: 3,
  //   name: "Client orders",
  //   render: true,
  //   translateKey: "client_orders",
  //   path: ROUTE_PATH.backOffice.clientOrders.path,
  // },
  {
    id: 2,
    name: "Vendors",
    render: true,
    translateKey: "vendors",
    path: ROUTER_PATHS.backOffice.vendors.path,
  },
  {
    id: 3,
    name: "Manege contact us",
    render: true,
    translateKey: "contact_us",
    path: ROUTER_PATHS.backOffice.contactUs.path,
  },
  // {
  //   id: 4,
  //   name: "Vendor orders",
  //   render: true,
  //   translateKey: "vendor_orders",
  //   path: ROUTER_PATHS.backOffice.vendorOrders.path,
  // },
  {
    id: 4,
    name: "Client orders",
    render: true,
    translateKey: "client_orders",
    path: ROUTER_PATHS.backOffice.clientOrders.path,
  },
  {
    id: 5,
    name: "Client companies",
    render: true,
    translateKey: "client_companies",
    path: ROUTER_PATHS.backOffice.clientCompanies.path,
  },
  {
    id: 6,
    name: "Biomarkers",
    render: true,
    translateKey: "biomarkers",
    path: ROUTER_PATHS.backOffice.biomarkers.path,
  },
  {
    id: 7,
    name: "STUB",
    render: false,
    translateKey: "stub",
    path: "profile",
  },
];

// Client Area (CL)
export const NAVIGATION_TABS_CA: Array<ITabItem> = [
  {
    id: 0,
    name: "Specimen search",
    render: true,
    translateKey: "specimen_search",
    path: ROUTER_PATHS.clientArea.specimenSearch.path,
  },
  {
    id: 1,
    name: "Orders",
    render: true,
    translateKey: "orders",
    path: ROUTER_PATHS.clientArea.orders.path,
  },
  {
    id: 2,
    name: "Contact us",
    render: true,
    translateKey: "contact_us",
    path: ROUTER_PATHS.clientArea.contactUs.path,
  },
  {
    id: 3,
    name: "Cart",
    render: true,
    translateKey: "cart",
    path: ROUTER_PATHS.clientArea.cart.path,
  },
  {
    id: 4,
    name: "STUB",
    render: false,
    translateKey: "stub",
    path: "profile",
  },
];
