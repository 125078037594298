import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import { FormHelperText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import colorOpacity from '@/shared/utils/colorOpacity';

interface StyleProps {
  fontSize?: number;
  fontWeight?: number;
  height?: string | number;
  isPlaceholder?: boolean;
  pointerEvent?: boolean;
  fullWidth?: boolean;
  lineHeight?: number;
  color?: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    "& .MuiSelect-select": {
      fontSize: `1.6rem`,
      fontWeight: 500,
      color: theme.colors.value_18,
    },
    "&.Mui-readOnly": {
      pointerEvents: "none",
    },
    "&.Mui-readOnly .MuiSvgIcon-root": {
      display: "none",
    },
    "& .MuiFormLabel-root": {
      color: theme.colors.value_11,
      fontSize: "1.2rem",
      transform: `translate(14px, ${theme.coefficient * 16.5}px) scale(1)`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      color: theme.colors.value_11,
      transform: `translate(14px, ${theme.coefficient * -7}px) scale(0.75)`,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.colors.value_11,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 4,
      height: theme.coefficient * 56,
      width: "auto",
      fontSize: "1.4rem",
      color: theme.colors.value_18,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: `${(theme.coefficient)}px`,
      borderColor: theme.colors.value_31,
      transition: 'border-color .2s ease; background-color .2 ease',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.colors.value_20,
      borderWidth: `${(theme.coefficient)}px`,
    },
    '&:hover :not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.value_31,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.value_35,
      borderWidth: `${(theme.coefficient)}px`,
    },
    '& .MuiOutlinedInput-input::placeholder': {
      fontSize: '1.4rem',
      fontStyle: 'italic',
      color: theme.colors.value_32,
      opacity: 1,
    },
    "& .MuiSvgIcon-root": {
      color: theme.colors.value_18,
    },
    "& .MuiAutocomplete-clearIndicator": {
      color: theme.colors.value_18,
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: theme.colors.value_18,
    },
    "&.MuiPaper-root": {
      boxShadow: theme.boxShadows.value_02,
      borderRadius: 8,
    },
    "& .MuiList-root": {
      minWidth: theme.coefficient * 160,
      padding: theme.coefficient * 16,
    },
    "& .MuiMenuItem-root": {
      fontSize: "1.6rem",
      padding: theme.coefficient * 8,
      borderRadius: 2,
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: theme.colors.value_21,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    '& .MuiPaper-root ': {
      color: theme.colors.value_18,
      borderRadius: theme.coefficient * 8,
      boxShadow: `0px 32px 32px ${colorOpacity(theme.colors.value_18, 0.1)}`,
    },
    '& .MuiAutocomplete-listbox': {
      padding: theme.coefficient * 8,
      backgroundColor: theme.colors.value_01,
      '& li': {
        color: theme.colors.value_27,
      },
      '& li[aria-selected="true"], li.Mui-focused[aria-selected="true"]': {
        backgroundColor: theme.colors.value_18,
        color: theme.colors.value_01,
      },
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: theme.colors.value_18,
      color: theme.colors.value_01,
      opacity: 1,
    },
    '& .MuiAutocomplete-paper': {
      backgroundColor: theme.colors.value_01,
    },
    '& .Mui-expanded': {
      backgroundColor: theme.colors.value_01,
    },
    "& .MuiAutocomplete-loading": {
      backgroundColor: theme.colors.value_01,
      color: theme.colors.value_18,
      fontSize: '1.4rem',
    },
    "& .MuiButtonBase-root.MuiIconButton-root": {
      visibility: `visible`,
    },
    "& .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root": {
      width: theme.coefficient * 18,
      height: theme.coefficient * 18,
    },
    "& .MuiAutocomplete-endAdornment": {
      position: "absolute",
      right: `${theme.coefficient * 30}px !important`,
      display: "flex",
      alignItems: "center",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      paddingRight: `${theme.coefficient * 58}px !important`,
    },
    "& .MuiCircularProgress-root": {
      position: 'relative',
      right: `-${theme.coefficient * 27}px !important`,
    },
  },
  arrowIcon: {
    position: 'absolute',
    right: theme.coefficient * 7,
    color: "transparent",
    backgroundImage: `url(${theme.images.arrow_down_var_1})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "auto",
    height: theme.coefficient * 24,
    width: theme.coefficient * 24,
    transition: "transform 0.2s",
    zIndex: -1,
  },
  arrowIconOpen: {
    transform: "rotate(180deg)",
  },
}));

export const useCustomStyles = (theme: any) => ({
  maxHeight: "200px",
  overflow: "auto",
  backgroundColor: theme.colors.value_01,
  color: theme.colors.value_18,
  fontSize: '1.4rem',

  "& .MuiAutocomplete-loading": {
    backgroundColor: "#fff",
    color: "#000",
  },

  "& li": {
    height: theme.coefficient * 56,
  },
  "& .MuiAutocomplete-paper": {
    backgroundColor: `${theme.colors.value_01} !important`,
    color: theme.colors.value_18,
    borderRadius: theme.coefficient * 8,
    boxShadow: `0px 32px 32px ${colorOpacity(theme.colors.value_18, 0.1)}`,
  },
  "& .MuiAutocomplete-listbox": {
    padding: theme.coefficient * 8,
    backgroundColor: `${theme.colors.value_01} !important`,
    "& li": {
      color: theme.colors.value_27,
    },
    "& li[aria-selected='true'], li.Mui-focused[aria-selected='true']": {
      backgroundColor: theme.colors.value_18,
      color: theme.colors.value_01,
    },
  },
  "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
    backgroundColor: theme.colors.value_18,
    color: theme.colors.value_01,
    opacity: 1,
  },
  "& .MuiAutocomplete-clearIndicator": {
    color: `${theme.colors.value_18} !important`,
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: `${theme.colors.value_18} !important`,
  },
  "& .MuiSvgIcon-root": {
    color: `${theme.colors.value_18} !important`,
  },
});

export const Wrapper = styled.div<StyleProps>`
    pointer-events: ${({ pointerEvent }) => (pointerEvent ? "none" : "auto")};
    width: 100%;
`;

export const Label = styled.div`
    width: 100%;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.6rem;
    color: ${({ theme }) => theme.colors.value_31};
    margin: 0 0 ${({ theme }) => theme.coefficient * 4}px 0;
`;

export const Placeholder = styled.div<StyleProps>`
    font-size: ${({ fontSize }) => fontSize}rem;
    line-height: ${({ lineHeight }) => lineHeight}rem;
    color: ${({ theme }) => theme.colors.value_32};
    font-weight: ${({ fontWeight }) => fontWeight};
`;

export const ErrorContainer = styled(FormHelperText)`
    color: ${({ theme }) => theme.colors.value_35}!important;
`;
