import { call, put, select, all } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { selectParams } from "../model/selectors";
import { biomarkersSlice } from "../model/slice";
import { IGetManyBiomarkersParams } from "../model/types/interfaces";
import {
  GetManyBiomarkersPayload,
  GetManyBiomarkersResponse,
  GetBiomarkerPayload,
  CreateRangePayload,
  GetBiomarkerResponse,
} from "./types/types";
import { commonSlice } from "@/entities/common";
import {
  sendGetBiomarkerRequest,
  sendCreateLoincRangeRequest,
  sendDeleteLoincRangesRequest,
  sendGetManyBiomarkersRequest,
} from "./senders";
import { alertsSagaActions } from "@/entities/alerts";
import { AlertStatusMessage, IApiError, ILoincBiomarker, ToggleActionType } from '@/shared/types';
import { asideSagaActions, asideSlice } from '@/widgets/side-block';
import { dialogSlice } from "@/widgets/dialog";
import { modifyBiomarkerData } from '@/entities/biomarkers/api/helpers';

export function* handleRetrieveBiomarker(
  action: PayloadAction<GetBiomarkerPayload>
) {
  const { biomarkerId } = action.payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;
  const { setLoadingDialog } = dialogSlice;

  try {
    const response: GetBiomarkerResponse = yield call(
      sendGetBiomarkerRequest,
      biomarkerId
    );
    const biomarker: ILoincBiomarker = response.data.data;
    const modifiedBiomarker: ILoincBiomarker = yield call(modifyBiomarkerData, biomarker)

    yield put(setActiveEntity({ activeEntity: modifiedBiomarker }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
    yield put(setLoadingDialog({ isLoading: false }));
  }
}

export function* handleCreateLoincRange(
  action: PayloadAction<{ formData: CreateRangePayload[] }>
) {
  const { setLoadingAside } = asideSlice;
  const { toggleSideBlock } = asideSagaActions;

  try {
    yield all(
      action.payload?.formData.map((range: any) => {
        return call(sendCreateLoincRangeRequest, range);
      })
    );

  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
  }
}

export function* handleDeleteLoincRanges(
  action: PayloadAction<{ formData: CreateRangePayload[] }>
) {
  const { setLoadingAside } = asideSlice;
  const { toggleSideBlock } = asideSagaActions;

  try {
    yield all(
      action.payload?.formData.map((range: any) => {
        return call(sendDeleteLoincRangesRequest, range.id );
      })
    );

  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
  }
}

export function* handleRetrieveManyBiomarkers(
  action?: PayloadAction<GetManyBiomarkersPayload>
) {
  const { setBiomarkers, setParams } = biomarkersSlice;
  const { setLoading } = commonSlice;

  if (action?.payload?.params) {
    yield put(setParams({ params: action.payload.params }));
  }

  try {
    yield put(setLoading({ isLoading: true }));
    const bodyParams: IGetManyBiomarkersParams = yield select(selectParams);

    const response: GetManyBiomarkersResponse = yield call(
      sendGetManyBiomarkersRequest,
      bodyParams
    );
    const { items, count } = response.data.data;

    yield put(setBiomarkers({ biomarkers: { items, count } }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

// export function* handleViewRowButtonClicked(
//   action: PayloadAction<ClickedRowItemPayload>
// ) {
//   yield call(handleTableRowClicked, action);
// }

// export function* handleRequestRowButtonClicked(
//   action: PayloadAction<ClickedRowItemPayload>
// ) {
//   const { clickedRowItem } = action.payload;
//   console.log("clickedRowItem 4444", clickedRowItem);
//   // const activeSample: ISample | null = yield select(selectActiveSample);
//   const { setActiveBiomarker } = biomarkersActions;
//   yield put(setActiveBiomarker({ activeBiomarker: clickedRowItem }));
//   // const { setLoadingModal } = commonActions;
//   // yield put(setLoadingModal({ isLoadingModal: true }));
//   yield put(
//     toggleModalWindow({
//       status: ToggleActionType.OPEN,
//       type: ModalWindowType.ORDER_FORM,
//     })
//   );

// if (activeSample?.id === clickedRowItem.id) {
//   yield put(setLoadingModal({ isLoadingModal: false }));
//   return;
// }

// const retrievedSample: ISample | null = yield call(
//   getSample,
//   clickedRowItem.id
// );
//
// if (!retrievedSample) {
//   yield put(setActiveSample({ activeSample: null }));
//   yield put(setLoadingModal({ isLoadingModal: false }));
//   return;
// }
//
// const modifiedSample: ISample = yield call(modifySampleData, retrievedSample);
// yield put(setActiveSample({ activeSample: modifiedSample }));
// yield put(setLoadingModal({ isLoadingModal: false }));
// }

function* handleError(err: any) {
  const { updateAlert } = alertsSagaActions;
  const error = err as IApiError;

  if (error?.response?.data?.error?.message?.length) {
    for (const text of error.response.data.error.message) {
      yield put(
        updateAlert({ status: AlertStatusMessage.ERROR, message: text })
      );
    }
  }
}
