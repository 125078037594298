import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { UUID } from 'crypto';
import { asideSagaActions, asideSlice } from '@/widgets/side-block';
import { dialogSagaActions, dialogSlice } from '@/widgets/dialog';
import { usersSagaActions } from '@/entities/users';
import { clientCompaniesSagaActions } from '@/entities/client-companies';
import { selectChats } from '@/entities/chats/model/selectors';

import {
  DialogMode,
  EntityType,
  GetManySamplesStatus,
  GetManyUsersStatus,
  ICart,
  IChat,
  IEntity,
  ISample,
  SideBlockMode,
  TableActionType,
  ToggleActionType,
} from '@/shared/types';

import { selectCurrentEntity } from '..';
import { ControlButtonPayload, ControlTogglePayload, TableActionButtonPayload, TableRowPayload } from './types/types';
import { samplesSagaActions } from '@/entities/samples';
import { vendorsSagaActions } from '@/entities/vendors';
import { chatsSagaActions } from '@/entities/chats';
import { getManyInvoices } from '@/entities/invoices/api/senders';
import { getManyStorageFacilities } from '@/entities/storageFacilities/api/senders';
import { invoicesSlice } from '@/entities/invoices';
import { storageFacilitiesSlice } from '@/entities/storageFacilities';
import { commonSlice } from '@/entities/common';
import { biomarkersSagaActions } from '@/entities/biomarkers';
import { t } from 'i18next';
import { ordersSagaActions } from '@/entities/client-orders';

export function* handleControlCreateButtonClicked(
  action: PayloadAction<ControlButtonPayload>
) {
  const { toggleSideBlock } = asideSagaActions;
  yield put(
    toggleSideBlock({
      mode: SideBlockMode.CREATE,
      status: ToggleActionType.OPEN,
      t_title: action.payload.titleKey,
      t_submitBtn: "create",
    })
  );
}

export function* handleControlToggleSwitched(
  action: PayloadAction<ControlTogglePayload>
) {
  const { isToggle } = action.payload;
  const { retrieveManyUsers } = usersSagaActions;
  const { retrieveManySamples } = samplesSagaActions;

  const currentEntity: EntityType = yield select(selectCurrentEntity);

  let updatedParams: any = {};

  switch (currentEntity) {
    case EntityType.USER:
      updatedParams = {
        skip: 0,
        status: isToggle ? undefined : GetManyUsersStatus.ACTIVE,
      };
      yield put(retrieveManyUsers({ params: updatedParams }));
      break;
    case EntityType.SAMPLE:
      updatedParams = {
        skip: 0,
        status: isToggle ? undefined : GetManySamplesStatus.AVAILABLE,
      };
      yield put(retrieveManySamples({ params: updatedParams }));
      break;
  }
}

export function* handleTableRowClicked(
  action: PayloadAction<TableRowPayload<IEntity>>
) {
  const { rowItem } = action.payload;
  const { setLoadingAside } = asideSlice;
  const { toggleSideBlock } = asideSagaActions;

  const currentEntity: EntityType = yield select(selectCurrentEntity);

  yield put(setLoadingAside({ isLoading: true }));
  yield put(
    toggleSideBlock({
      mode: SideBlockMode.EDIT,
      status: ToggleActionType.OPEN,
      t_title: t(`aside_title_edit_${currentEntity}_mode`),
      t_submitBtn: `edit_${currentEntity}`,
    })
  );

  yield call(retrieveEntity, currentEntity, rowItem.id);
}

export function* handleTableActionButtonClicked(
  action: PayloadAction<TableActionButtonPayload<IEntity>>
) {
  const { rowItem, actionType } = action.payload;
  const { setActiveEntity } = commonSlice;

  switch (actionType) {
    case TableActionType.EDIT:
      // the same logic as in handleTableRowClicked so just call it
      yield call(handleTableRowClicked, action);
      break;
    case TableActionType.DELETE:
      yield call(processDeleteActionButton, rowItem);
      break;
    // case TableActionType.DUPLICATE:
    //   yield call(handleDuplicateRowButtonClicked, { payload: { rowItem } });
    //   break;
    case TableActionType.REQUEST_BIOMARKER:
      yield put(setActiveEntity({ activeEntity: rowItem as ISample }));
      yield call(processRequestBiomarkerButton, rowItem);
      break;
    case TableActionType.ADD_SHIPPING_LOCATION:
      yield put(setActiveEntity({ activeEntity: rowItem as ISample }));
      yield call(processAddNewLocationButton, rowItem);
      break;
    case TableActionType.DELETE_SHIPPING_LOCATION:
      yield put(setActiveEntity({ activeEntity: rowItem as ISample }));
      yield call(processDeleteLocationButton, rowItem);
      break;
    case TableActionType.CUSTOM_REQUEST:
      yield put(setActiveEntity({ activeEntity: rowItem as ISample }));
      yield call(processCustomRequestButton, rowItem);
      break;
    case TableActionType.PLACE_ORDER:
      yield put(setActiveEntity({ activeEntity: rowItem as ICart }));
      yield call(processSubmitActionButton);
      break;
    case TableActionType.SHOW_PRODUCT_INFO:
      yield put(setActiveEntity({ activeEntity: rowItem as ICart }));
      yield call(processSubmitProductInfo);
      break;
  }
}

function* processRequestBiomarkerButton(rowItem: IEntity) {
  const { toggleDialog } = dialogSagaActions;

  yield put(
    toggleDialog({
      mode: DialogMode.ORDER_FORM,
      status: ToggleActionType.OPEN,
    })
  );
}

function* processAddNewLocationButton(rowItem: IEntity) {
  const { toggleDialog } = dialogSagaActions;

  yield put(
    toggleDialog({
      mode: DialogMode.ADD_SHIPPING_LOCATION,
      status: ToggleActionType.OPEN,
    })
  );
}

function* processDeleteLocationButton(rowItem: IEntity) {
  const { toggleDialog } = dialogSagaActions;

  yield put(
    toggleDialog({
      mode: DialogMode.DELETE_SHIPPING_LOCATION,
      status: ToggleActionType.OPEN,
    })
  );
}

function* processCustomRequestButton(rowItem: IEntity) {
  const { toggleDialog } = dialogSagaActions;

  yield put(
    toggleDialog({
      mode: DialogMode.CUSTOM_REQUEST_FORM,
      status: ToggleActionType.OPEN,
    })
  );
}

function* processDeleteActionButton(rowItem: IEntity) {
  const { setLoadingDialog } = dialogSlice;
  const { toggleDialog } = dialogSagaActions;

  const currentEntity: EntityType = yield select(selectCurrentEntity);

  yield put(setLoadingDialog({ isLoading: true }));
  yield put(
    toggleDialog({
      mode: DialogMode.DELETE,
      status: ToggleActionType.OPEN,
    })
  );
  yield call(retrieveEntity, currentEntity, rowItem.id, rowItem);
}

function* processSubmitActionButton() {
  const { toggleDialog } = dialogSagaActions;

  yield put(
    toggleDialog({
      mode: DialogMode.PLACE_ORDER,
      status: ToggleActionType.OPEN,
    })
  );
}

function* processSubmitProductInfo() {
  const { toggleDialog } = dialogSagaActions;

  yield put(
    toggleDialog({
      mode: DialogMode.PRODUCT_INFO,
      status: ToggleActionType.OPEN,
    })
  );
}

function* retrieveEntity(
  entity: EntityType,
  id: UUID | string | number,
  rowItem?: IEntity
) {
  const { retrieveUser } = usersSagaActions;
  const { retrieveSample } = samplesSagaActions;
  const { retrieveVendor } = vendorsSagaActions;
  const { retrieveChat } = chatsSagaActions;
  const { retrieveBiomarker } = biomarkersSagaActions;
  const { retrieveOrder } = ordersSagaActions;
  const { retrieveCompany } = clientCompaniesSagaActions;

  // ToDo recheck logic
  const { setInvoices } = invoicesSlice;
  const { setStorageFacilities } = storageFacilitiesSlice;
  const { setActiveEntity } = commonSlice;
  const { setLoadingDialog } = dialogSlice;

  switch (entity) {
    case EntityType.USER:
      yield put(retrieveUser({ userId: id as UUID }));
      break;
    case EntityType.SAMPLE:
      yield put(retrieveSample({ sampleId: id as number }));
      // ToDo recheck logic
      const invoicesResponse: { items: any[]; count: number } = yield call(
        getManyInvoices,
        {}
      );
      const storageFacilitiesResponse: { items: any[]; count: number } =
        yield call(getManyStorageFacilities, {});
      yield put(
        setInvoices({
          invoices: {
            items: invoicesResponse.items,
            count: invoicesResponse.count,
          },
        })
      );
      yield put(
        setStorageFacilities({
          storageFacilities: {
            items: storageFacilitiesResponse.items,
            count: storageFacilitiesResponse.count,
          },
        })
      );
      break;
    case EntityType.VENDOR:
      yield put(retrieveVendor({ vendorId: id as number }));
      break;
    case EntityType.CHAT:
      const { items } = yield select(selectChats);
      const activeChat = items.find((option: IChat) => option.id === id);

      yield put(retrieveChat({ chatId: activeChat.id as number }));
      break;
    case EntityType.CART:
      yield put(setActiveEntity({ activeEntity: rowItem as ISample }));
      yield put(setLoadingDialog({ isLoading: false }));
      break;
    case EntityType.LOINC_BIOMARKER:
      yield put(retrieveBiomarker({ biomarkerId: id as number }));
      break;
    case EntityType.ORDER:
      yield put(retrieveOrder({ orderId: id as string }));
      break;
    case EntityType.COMPANY:
      yield put(retrieveCompany({ companyId: id as string }));
      yield put(setLoadingDialog({ isLoading: false }));
      break;
  }
}
