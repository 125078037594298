import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME, InitialStateType, CaseReducers } from "./types/types";
import { DialogMode } from "@/shared/types";

const INITIAL_STATE: InitialStateType = {
  settings: {
    mode: DialogMode.CLOSED,
    isOpen: false,
    t_title: "",
    t_submitBtn: "",
  },
  isLoading: false,
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDialogSettings: (draft, { payload }) => {
      draft.settings = payload.settings;
    },
    setLoadingDialog: (draft, { payload }) => {
      draft.isLoading = payload.isLoading;
    },
  },
});

export const dialogSlice = slice.actions;
export const dialogReducer = slice.reducer;
