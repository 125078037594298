import React, { ReactNode } from "react";
import { HeaderWrapper, CustomIconButton } from "./styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box } from "@mui/material";

type SidebarHeaderProps = {
  onClose: () => void;
  children?: ReactNode;
};

export function SidebarHeader({ onClose, children }: SidebarHeaderProps) {
  return (
    <HeaderWrapper>
      <Box display={"flex"} flex={1}>
        {children}
      </Box>
      <CustomIconButton onClick={onClose}>
        <CloseOutlinedIcon fontSize="large" />
      </CustomIconButton>
    </HeaderWrapper>
  );
}
