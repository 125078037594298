import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import { SubmitDialogPayload, ToggleDialogPayload } from "./types/types";
import { useDispatch } from "react-redux";

const toggleDialog = createAction<ToggleDialogPayload>(
  ActionType.TOGGLE_DIALOG
);

const dialogSubmitButtonClicked = createAction<SubmitDialogPayload>(
  ActionType.DIALOG_SUBMIT_BUTTON_CLICKED
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      toggleDialog,
      dialogSubmitButtonClicked,
    },
    dispatch
  );
};

export const sagaActions = {
  toggleDialog,
  dialogSubmitButtonClicked,
};
