import { createSlice } from "@reduxjs/toolkit";

import { OrderStatus } from "@/shared/types";

import { InitialStateType, SLICE_NAME, CaseReducers } from "./types/types";

const INITIAL_STATE: InitialStateType = {
  data: { items: [], count: 0 },
  params: {},
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
    setOrders: (draft, { payload }) => {
      draft.data = payload.orders;
    },
  },
});

export const ordersSlice = slice.actions;
export const ordersReducer = slice.reducer;
