import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";

import { getImages } from "../images/getImages";
import { IUniversalNumber, AppTheme } from "./types";
import { COLOR_MATRIX } from "./colorMatrix";

export const getMatrixByMode = (mode: PaletteMode): IUniversalNumber => ({
  ...(mode === "dark" ? COLOR_MATRIX.DARK : COLOR_MATRIX.LIGHT),
});

export const themeSettings = (mode: PaletteMode): AppTheme => {
  const matrix = getMatrixByMode(mode);

  return {
    rgb: matrix.rgb,
    colors: matrix.colors,
    images: getImages(mode),
    boxShadows: matrix.boxShadows,
    coefficient: 1,
    scaleFontSize: 1,
  };
};

export const ColorModeContext = createContext<{ toggleColorMode: () => void }>({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState<PaletteMode>("dark");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );
  const theme = useMemo(() => {
    const newTheme = createTheme(themeSettings(mode));
    return newTheme;
  }, [mode]);
  return { theme, colorMode };
};
