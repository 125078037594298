import styled from "@emotion/styled";
import colorOpacity from "@/shared/utils/colorOpacity";

export const FOOTER_HEIGHT = 80;

export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.coefficient * FOOTER_HEIGHT}px;
  background: ${({ theme }) => theme.colors.value_17};
  box-shadow: 0 4px 40px
    ${({ theme }) => colorOpacity(theme.colors.value_18, 0.15)};
  padding: 0 ${({ theme }) => theme.coefficient * 40}px 0
    ${({ theme }) => theme.coefficient * 40}px;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.coefficient * 24}px;
`;
