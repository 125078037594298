import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import {
  IAreaItemsData,
  IResearchAreasData,
  ILoincBiomarkersData, IGetManyBiomarkersParams,
} from './interfaces';
import { ILoincBiomarker } from '@/shared/types';

export const SLICE_NAME = "researchAreas";

export type InitialStateType = {
  data: IResearchAreasData;
  areaItems: IAreaItemsData | null;
  bodySystems: IResearchAreasData;
  params: IGetManyBiomarkersParams;
  loincBiomarkers: ILoincBiomarkersData;
  activeAreaName: string;
  activeLoincBiomarker: ILoincBiomarker | null;
};

type ResearchAreasDataPayload = {
  data: IResearchAreasData;
};

type ResearchAreasBodySystemsPayload = {
  bodySystems: IResearchAreasData;
};

type AreaItemsDataPayload = {
  areaItems: IAreaItemsData | null;
};

type LoincBiomarkersDataPayload = {
  loincBiomarkers: ILoincBiomarkersData;
};

type ActiveAreaNameDataPayload = {
  activeAreaName: string;
};

type GetManyBiomarkersParamsPayload = {
  params: IGetManyBiomarkersParams;
}

type ActiveBiomarkerPayload = {
  activeLoincBiomarker: ILoincBiomarker | null;
}

export type CaseReducers<State> = {
  setData: CaseReducer<State, PayloadAction<ResearchAreasDataPayload>>;
  setBodySystems: CaseReducer<State, PayloadAction<ResearchAreasBodySystemsPayload>>;
  setAreaItems: CaseReducer<State, PayloadAction<AreaItemsDataPayload>>;
  setParams: CaseReducer<State, PayloadAction<GetManyBiomarkersParamsPayload>>
  setLoincBiomarkers: CaseReducer<
    State,
    PayloadAction<LoincBiomarkersDataPayload>
  >;
  setActiveAreaName: CaseReducer<
    State,
    PayloadAction<ActiveAreaNameDataPayload>
  >;
  setActiveLoincBiomarker: CaseReducer<State, PayloadAction<ActiveBiomarkerPayload>>
};
