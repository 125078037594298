import React, { useCallback, useState } from 'react';
import { TextField, CircularProgress, Paper, Popper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Control, Controller } from "react-hook-form";
import { useStyles, Wrapper, Label, ErrorContainer, useCustomStyles } from './styles';
import { useTheme } from '@mui/styles';
import clsx from 'clsx';

type CustomAutocompleteProps = {
  name: string;
  label: string;
  placeholder: string;
  control: Control<any>;
  options?: any[];
  optionKey?: string;
  isLoading: boolean;
  onChangeCallback: (inputValue: string) => void;
  onScrollCallback: (event: any) => void;
  onCloseCallback?: () => void;
};

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
       name,
       label,
       control,
       options,
       optionKey = 'name',
       placeholder,
       isLoading,
       onChangeCallback,
       onScrollCallback,
       onCloseCallback,
     }) => {
  const theme = useTheme();
  const classes = useStyles();
  const customStyles = useCustomStyles(theme);
  const [isOpen, setIsOpen] = useState(false);

  const renderPopper = useCallback((inProps: any) => {
    const configPopper = {
      ...inProps,
      className: clsx(inProps.className, classes.root),
    };

    return <Popper {...configPopper} />;
  }, [classes.root]);

  const renderPaper = useCallback((inProps: any) => {
    const configPaper = {
      ...inProps,
      className: clsx(inProps.className, classes.root),
    };

    return <Paper {...configPaper} />;
  }, [classes.root]);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...field } }) => {
          return (
          <Autocomplete
            {...field}
            freeSolo
            className={classes.root}
            options={options ? options : []}
            loading={isLoading}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option[optionKey]
            }
            onInputChange={(_, newInputValue) => {
              if (newInputValue === "") {
                onChange(null);
              } else {
                onChangeCallback(newInputValue);
                const option = options?.find((elem) => {
                  return elem.name === newInputValue;
                });

                onChange(option);
              }
            }}
            onOpen={() => {
              setIsOpen(true);
              onChangeCallback("");
            }}
            onClose={() => {
              setIsOpen(false);
              onCloseCallback && onCloseCallback();
            }}
            ListboxProps={{
              style: customStyles,
              onScroll: onScrollCallback,
            }}
            PaperComponent={renderPaper}
            PopperComponent={renderPopper}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                placeholder={placeholder}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                      <span className={clsx(classes.arrowIcon, { [classes.arrowIconOpen]: isOpen })} />
                    </>
                  ),
                }}
              />
            )}
          />
        )}}
      />
      <ErrorContainer>
        {/* Error */}
      </ErrorContainer>
    </Wrapper>
  );
};

export default CustomAutocomplete;
