import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";

import { ActionType } from "./types/actionType";
import {
  GetUserPayload,
  CreateUserPayload,
  UpdateUserPayload,
  DeleteUserPayload,
  GetManyUsersPayload,
} from "./types/types";

const createUser = createAction<CreateUserPayload>(ActionType.CREATE_USER);

const updateUser = createAction<UpdateUserPayload>(ActionType.UPDATE_USER);

const deleteUser = createAction<DeleteUserPayload>(ActionType.DELETE_USER);

const retrieveUser = createAction<GetUserPayload>(ActionType.GET_USER);

const updateUserAvatar = createAction<GetUserPayload>(ActionType.UPDATE_USER_AVATAR);

const deleteUserAvatar = createAction(ActionType.DELETE_USER_AVATAR);

const retrieveManyUsers = createAction<GetManyUsersPayload | undefined>(
  ActionType.GET_MANY_USERS
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      createUser,
      updateUser,
      deleteUser,
      retrieveUser,
      updateUserAvatar,
      deleteUserAvatar,
      retrieveManyUsers,
    },
    dispatch
  );
};

export const sagaActions = {
  createUser,
  updateUser,
  deleteUser,
  retrieveUser,
  updateUserAvatar,
  deleteUserAvatar,
  retrieveManyUsers,
};
