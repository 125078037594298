import { ReactElement } from "react";
import { Dialog } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import CustomLoading from "@/shared/components/customLoading/CustomLoading";

import {
  Wrapper,
  FooterContainer,
  HeaderContainer,
  ContentContainer,
  CustomIconButton,
} from "./styles";

type DialogAdapterProps = {
  title?: React.ReactNode;
  isOpen: boolean;
  footer?: React.ReactNode;
  content?: React.ReactNode;
  isLoading: boolean;
  onCloseCallback: any;
};

export function DialogAdapter({
  isOpen,
  title,
  footer,
  content,
  isLoading,
  onCloseCallback,
}: DialogAdapterProps): ReactElement {
  return (
    <>
      <Dialog open={isOpen} onClose={onCloseCallback} sx={{ '& .MuiDialog-paper': { width: 'auto', maxWidth: 'none' } }}>
        {isOpen && (
          <Wrapper>
            <HeaderContainer>
              {title}
              <CustomIconButton onClick={onCloseCallback}>
                <CloseOutlinedIcon fontSize="large" />
              </CustomIconButton>
            </HeaderContainer>

            <ContentContainer>{content}</ContentContainer>

            <FooterContainer>{footer}</FooterContainer>
          </Wrapper>
        )}
      </Dialog>
      <CustomLoading isOpen={isLoading} zIndex="snackbar" />
    </>
  );
}
