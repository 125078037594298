import {
  commonImages,
  darkThemeImages,
  lightThemeImages,
} from "./imageProvider";

export function getImages(theme: any) {
  return {
    ...commonImages,
    ...(theme === "dark" ? darkThemeImages : lightThemeImages),
  };
}
