import { IInvoice } from "@/shared/types";

export const modifyInvoicesData = (items: Array<IInvoice>): Array<IInvoice> => {
  return items.map((item: IInvoice) => {
    return { ...item };
  });
};

export const modifyInvoiceData = (item: IInvoice): IInvoice => {
  return { ...item };
};

export const getCreateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
  };
  return a;
};

export const getUpdateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
  };
  return a;
};
