import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const NAVBAR_HEIGHT = 72;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiTabs-root": {
      minHeight: "auto",
      width: "100%",
    },
  },
  navBarRegular: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "&.MuiTabs-root": {
      minHeight: "auto",
      overflowX: "auto",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
    },
    "& .MuiButtonBase-root": {
      position: "relative",
      color: "#CDD4E2",
      flexDirection: "row",
      textTransform: "none",
      minHeight: `${32}px`,
      height: `${NAVBAR_HEIGHT}px`,
      fontSize: "1.8rem",
      fontWeight: 400,
      padding: `0 ${15}px`,
      minWidth: 0,
      whiteSpace: "nowrap",
      width: "fit-content",
      overflowX: "hidden",
    },
    "& .MuiButtonBase-root .MuiTouchRipple-root": {
      overflowX: "hidden",
    },
    "& .MuiButtonBase-root.Mui-selected::after": {
      content: '""',
      position: "absolute",
      bottom: "0px",
      left: 16,
      right: 13,
      height: 4,
      backgroundColor: "#F0C350",
      borderRadius: "4px 4px 0 0",
    },
    "& .MuiButtonBase-root:hover": {
      color: "#34BAE4",
    },
    "& .MuiButtonBase-root>svg": {
      margin: `0 ${7}px 0 0`,
    },
    "& .MuiButtonBase-root.Mui-selected": {
      color: "#F0C350",
      fontWeight: 600,
    },
  },
  navBar: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "&.MuiTabs-root": {
      minHeight: "auto",
      overflowX: "auto",
      alignItems: "center",
      height: "100%",
      width: "100%"
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      alignItems: "flex-start",
    },
    "& .MuiButtonBase-root": {
      position: "relative",
      color: "#ACB6CB",
      flexDirection: "row",
      textTransform: "none",
      minHeight: `${32}px`,
      height: `${NAVBAR_HEIGHT}px`,
      fontSize: "1.8rem",
      fontWeight: 400,
      padding: `0 ${15}px`,
      minWidth: 0,
      whiteSpace: "nowrap",
      width: "fit-content",
      overflowX: "hidden",
    },
    "& .MuiButtonBase-root .MuiTouchRipple-root": {
      overflowX: "hidden",
    },
    "& .MuiButtonBase-root:hover": {
      color: "#34BAE4",
    },
    "& .MuiButtonBase-root>svg": {
      margin: `0 ${7}px 0 0`,
    },
    "& .MuiButtonBase-root.Mui-selected": {
      color: "#F0C350",
      fontWeight: 600,
    },
  },
  tab: {
    "&.MuiTab-root": {
      padding: `0 ${theme.coefficient * 25}px !important`,
    },
    "&.Mui-selected": {
      "& .MuiBadge-badge": {
        color: theme.colors.value_02,
        backgroundColor: theme.colors.value_46,
      },
    },
    "& svg": { width: "26px", height: "26px" },
    "& .MuiBadge-badge": {
      top: "-8px",
      right: "-12px",
      width: "22px",
      height: "22px",
      fontSize: "12px",
      fontWeight: "bold",
      color: theme.colors.value_02,
      backgroundColor: theme.colors.value_32,
    },
  },
  cartTab: {
    right: 40,
  },
  hidden: {
    pointerEvents: "none",
    visibility: "hidden",
  },
}));

export default useStyles;
