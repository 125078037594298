import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarKey } from "notistack";
import { Inner, Wrapper, Message, IconClose, Container } from "../../styles";
import { IconInfo, IconContainerInfo } from "./styles";

interface InfoProps {
  id: SnackbarKey;
  message: string;
}

export const InfoAlert = forwardRef<HTMLDivElement, InfoProps>((props, ref) => {
  const { id, message } = props;
  const { closeSnackbar } = useSnackbar();

  const onClose = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <Wrapper ref={ref}>
      <Container bgRgbColor="value_19" borderColor="value_20">
        <Inner>
          <IconContainerInfo>
            <IconInfo />
          </IconContainerInfo>
          <Message color="value_20">{message}</Message>
        </Inner>
        <IconClose onClick={onClose} backgroundColor="value_20" />
      </Container>
    </Wrapper>
  );
});
