export const RE_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const RE_PASSWORD = /^.{8,50}$/;
export const RE_REPLACE_NUMBER = /[s-]|[ s]|[()]|[+]/gm;
export const RE_IMAGE_FORMAT = /\.(jpg|jpeg|png)$/i;
export const RE_NUMBER_REQUIRED = /(?=.*?[0-9])/;
export const RE_PASSWORD_LENGTH = /.{8,}/;
export const RE_UPPERCASE_REQUIRED = /(?=.*?[A-Z])/;
export const RE_LOWERCASE_REQUIRED = /(?=.*?[a-z])/;
export const RE_LATIN_LETTERS = /^[a-zA-Z]+$/;
