import { IOrder } from "@/shared/types";

export const modifyOrdersData = (items: Array<IOrder>): Array<IOrder> => {
  return items.map((item: IOrder) => {
    return { ...item };
  });
};

export const modifyOrderData = (item: IOrder): IOrder => {
  return { ...item };
};

export const getCreateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
  };
  return a;
};

export const getUpdateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
  };
  return a;
};
