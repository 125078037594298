import { ILoincBiomarker, RootState } from "@/shared/types";
import { IBiomarkersData, IGetManyBiomarkersParams } from "./types/interfaces";

export const selectParams = (state: RootState): IGetManyBiomarkersParams =>
  state.biomarkers.params;

export const selectBiomarkers = (state: RootState): IBiomarkersData =>
  state.biomarkers.data;

// export const selectActiveBiomarker = (state: RootState): ILoincBiomarker | null =>
//   state.biomarkers.activeBiomarker;
