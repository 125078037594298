import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

import { useAuthActions } from "@/entities/auth";
import { useCommonActions } from "@/entities/common";
import { ITabItem } from "@/shared/types";
import { ICurrentUser } from "@/entities/common/model/types/interfaces";

import {
  Wrapper,
  CustomMenu,
  IconLogout,
  IconProfile,
  CustomButton,
  CustomMenuItem,
} from "./styles";

type MenuProps = {
  tabs: Array<ITabItem>;
  currentUser?: ICurrentUser | null;
};

export function Menu({ tabs, currentUser }: MenuProps): JSX.Element {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const { logOutButtonClicked } = useAuthActions();
  const { navigationTabClicked } = useCommonActions();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((anchorEl) => (anchorEl = event.currentTarget));
  };

  const onCloseMenu = () => {
    setAnchorEl((anchorEl) => (anchorEl = null));
  };

  const onClickProfileItem = () => {
    setAnchorEl((anchorEl) => (anchorEl = null));
    const value = tabs[tabs.length - 1].id;
    navigationTabClicked({ value });
  };

  const onClickLogOutItem = () => {
    setAnchorEl((anchorEl) => (anchorEl = null));
    logOutButtonClicked();
  };

  const isActive = location.pathname === "/profile";

  return (
    <Wrapper>
      <CustomButton onClick={onOpenMenu}>
        <IconProfile active={isActive} />
        <Typography
          variant="h6"
          color={isActive ? theme.colors.value_59 : theme.colors.value_01}
        >
          {`
          ${currentUser ? currentUser.firstName : ""} 
          ${currentUser ? currentUser.lastName : ""}
          `}
        </Typography>
      </CustomButton>
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
      >
        <CustomMenuItem onClick={onClickProfileItem}>
          <IconProfile active={isActive} />
          <Typography
            variant="h5"
            color={isActive ? theme.colors.value_59 : theme.colors.value_01}
          >
            {t("profile")}
          </Typography>
        </CustomMenuItem>
        <CustomMenuItem onClick={onClickLogOutItem}>
          <IconLogout />
          <Typography variant="h5" color={theme.colors.value_01}>
            {t("log_out")}
          </Typography>
        </CustomMenuItem>
      </CustomMenu>
    </Wrapper>
  );
}
