import { SnackbarOrigin } from "notistack";

const MAX_SNACK = 10;
const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};
const AUTO_HIDE_DURATION = 5000;

export { MAX_SNACK, ANCHOR_ORIGIN, AUTO_HIDE_DURATION };
