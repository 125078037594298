// Helper function to log errors
function logError(error: any) {
  console.error("LocalStorage Error:", error);
}

// Function to set an item in localStorage
export function setItemToLS(key: string, item: any): boolean {
  try {
    const value = typeof item === "string" ? item : JSON.stringify(item);
    localStorage.setItem(key, value);
    return true;
  } catch (error) {
    logError(error);
    return false;
  }
}

// Function to remove an item from localStorage
export function removeItemFromLS(key: string): boolean {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    logError(error);
    return false;
  }
}

// Function to get an item from localStorage
export function getItemFromLS<T = string>(
  key: string,
  parseJson = false
): T | null {
  try {
    const item = localStorage.getItem(key);
    if (item) {
      return parseJson ? JSON.parse(item) : (item as unknown as T);
    }
    return null;
  } catch (error) {
    logError(error);
    return null;
  }
}
