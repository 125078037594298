import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  PLACE_ORDER: `${SLICE_NAME}/place_order`,
  USER_LOGGED_IN: `${SLICE_NAME}/user_logged_in`,
  USER_LOGGED_OUT: `${SLICE_NAME}/user_logged_out`,
  NAVIGATION_TAB_CLICKED: `${SLICE_NAME}/navigation_tab_clicked`,
  REMOVE_PRODUCT_FROM_CART: `${SLICE_NAME}/remove_product_from_cart`,
  ADD_SAMPLE_TO_CART_CLICKED: `${SLICE_NAME}/add_sample_to_cart_clicked`,
} as const;
