import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import {
  InfinitySelectOpenPayload,
  InfinitySelectLoadMorePayload,
} from "@/features/infinity-select/api/types/types";

const infinitySelectOpen = createAction<InfinitySelectOpenPayload>(
  ActionType.INFINITY_SELECT_OPEN
);

const infinitySelectLoadMore = createAction<InfinitySelectLoadMorePayload>(
  ActionType.INFINITY_SELECT_LOAD_MORE
);

const infinitySelectClose = createAction(ActionType.INFINITY_SELECT_CLOSE);

export const useSelectActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      infinitySelectOpen,
      infinitySelectClose,
      infinitySelectLoadMore,
    },
    dispatch
  );
};
