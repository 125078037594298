import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import { handleToggleDialog, handleSubmitButtonClicked } from "./workers";

export function* watcher() {
  yield takeLatest(
    ActionType.DIALOG_SUBMIT_BUTTON_CLICKED,
    handleSubmitButtonClicked
  );
  yield takeLatest(ActionType.TOGGLE_DIALOG, handleToggleDialog);
}
