import { useDispatch } from "react-redux";
import { createAction, bindActionCreators } from "@reduxjs/toolkit";
import { AuthActionType } from "./types/actionType";
import { LoginPayload, UpdateProfilePayload } from "./types/types";

export const loginButtonClicked = createAction<LoginPayload>(
  AuthActionType.LOGIN_BUTTON_CLICKED
);

export const logOutButtonClicked = createAction(
  AuthActionType.LOG_OUT_BUTTON_CLICKED
);

export const checkUserByToken = createAction(
  AuthActionType.CHECK_USER_BY_TOKEN
);

const updateProfileButtonClicked = createAction<UpdateProfilePayload>(
  AuthActionType.UPDATE_PROFILE_BUTTON_CLICKED
);

export const useAuthActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      checkUserByToken,
      loginButtonClicked,
      logOutButtonClicked,
      updateProfileButtonClicked,
    },
    dispatch
  );
};
