import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";

const retrieveManyInvoices = createAction(ActionType.RETRIEVE_MANY_INVOICES);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      retrieveManyInvoices,
    },
    dispatch
  );
};

export const sagaActions = {
  retrieveManyInvoices,
};
