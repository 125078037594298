import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IInvoice } from "@/shared/types";
import { IGetManyInvoicesParams, IInvoicesData } from "./interfaces";

export const SLICE_NAME = "invoices";

export type InitialStateType = {
  data: IInvoicesData;
  params: IGetManyInvoicesParams;
};

type InvoicesDataPayload = {
  invoices: IInvoicesData;
};

type GetManyInvoicesParamsPayload = {
  params: IGetManyInvoicesParams;
};

export type CaseReducers<State> = {
  setParams: CaseReducer<State, PayloadAction<GetManyInvoicesParamsPayload>>;
  setInvoices: CaseReducer<State, PayloadAction<InvoicesDataPayload>>;
};
