import { Helmet } from "@/widgets/helmet/Helmet";
import { UserRole } from "@/shared/types/enums/UserRole.enum";
// import { useResponsive } from "../providers/adapter/AppAdapterProvider";
import { BackOfficeLayout } from "./back-office/BackOfficeLayout";
import { ClientAreaLayout } from "./client-area/ClientAreaLayout";
import { AuthLayout } from "./auth/AuthLayout";
import { GlobalStyles } from "../providers/theme/lib/globalStyle";

type BaseLayoutProps = {
  role?: UserRole;
  children: React.ReactNode;
};

export function BaseLayout({ role, children }: BaseLayoutProps): JSX.Element {
  // const { isMobile } = useResponsive();

  const getLayoutByUserRole = (role?: UserRole): JSX.Element => {
    switch (role) {
      case UserRole.SUPER_ADMIN:
      case UserRole.ADMIN:
        return <BackOfficeLayout isMobile={false} children={children} />;
      case UserRole.CLIENT_MANAGER:
      case UserRole.CLIENT_USER:
        return <ClientAreaLayout isMobile={false} children={children} />;
      default:
        return <AuthLayout isMobile={false} children={children} />;
    }
  };

  return (
    <>
      <Helmet title={"MRN"} />
      {getLayoutByUserRole(role)}
      <GlobalStyles />
    </>
  );
}
