import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleTableRowClicked,
  handleTableActionButtonClicked,
  handleControlToggleSwitched,
  handleControlCreateButtonClicked,
} from "./workers";

export function* watcher() {
  yield takeLatest(ActionType.TABLE_ROW_CLICKED, handleTableRowClicked);
  yield takeLatest(
    ActionType.TABLE_ACTION_BUTTON_CLICKED,
    handleTableActionButtonClicked
  );
  yield takeLatest(
    ActionType.CONTROL_TOGGLE_SWITCHED,
    handleControlToggleSwitched
  );
  yield takeLatest(
    ActionType.CONTROL_CREATE_BUTTON_CLICKED,
    handleControlCreateButtonClicked
  );
}
