import { UUID } from "crypto";

import { sendRequest } from "@/shared/api/requestSender";
import { API_URL } from "@/shared/api/apiUrl";
import { HttpMethod, IVendor } from "@/shared/types";

import { IGetManyVendorsParams } from "../model/types/interfaces";

export const sendCreateVendorRequest = (body: Partial<IVendor> | unknown) =>
  sendRequest({
    path: API_URL.vendors.createOne,
    method: HttpMethod.POST,
    data: body,
  });

export const sendGetVendorRequest = (vendorId: number) =>
  sendRequest({
    path: `${API_URL.vendors.getOne}/${vendorId}`,
    method: HttpMethod.GET,
  });

export const sendUpdateVendorRequest = (
  vendorId: number,
  body: Partial<IVendor> | unknown
) =>
  sendRequest({
    path: `${API_URL.vendors.updateOne}/${vendorId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

export const sendDeleteVendorRequest = (vendorId: number) =>
  sendRequest({
    path: `${API_URL.vendors.deleteOne}/${vendorId}`,
    method: HttpMethod.DELETE,
  });

export const sendGetManyVendorsRequest = (body: IGetManyVendorsParams) =>
  sendRequest({
    path: API_URL.vendors.getMany,
    method: HttpMethod.POST,
    data: body,
  });
