import { IClientCompany } from "@/shared/types";
import { RE_REPLACE_NUMBER } from '@/shared/utils/regExp';

export const getFullAddress = (
  address1?: string,
  address2?: string,
  state?: string,
  city?: string,
  zip?: string
) => {
  const parts = [address1, address2, city, state, zip]?.filter(Boolean);
  return parts.length > 0 ? parts.join(', ') : '';
};

export const modifyClientCompaniesData = (items: Array<IClientCompany>): Array<IClientCompany> => {
  return items.map((item: IClientCompany) => {
    const fullAddress = getFullAddress(item.address1, item.address2, item.state, item.city, item.zip);
    return { ...item, fullAddress };
  });
};

export const modifyOrderData = (item: IClientCompany): IClientCompany => {
  return { ...item };
};

export const getCreateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
  };
  return a;
};

export const getCreateDtoByShippingLocationFormData = (formData: any) => {
  const a = {
    name: formData.name,
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    state: formData.state,
    zip: formData.zip,
    phoneNumber: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || "",
    fax: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || "",
    email: formData.email,
    clientCompanyId: formData.clientCompanyId,
  };
  return a;
};

export const getUpdateDtoByShippingLocationFormData = (formData: any) => {
  const a = {
    name: formData.name,
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    state: formData.state,
    zip: formData.zip,
    phoneNumber: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || null,
    fax: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || null,
    email: formData.email,
  };
  return a;
};
