import { Wrapper } from "./styles";

type AuthLayoutProps = {
  isMobile: boolean;
  children: React.ReactNode;
};

export function AuthLayout({
  isMobile,
  children,
}: AuthLayoutProps): JSX.Element {
  return isMobile ? <div>THIS IS MOBILE</div> : <Wrapper>{children}</Wrapper>;
}
