import { ColorMatrix } from "./types";

export const COLOR_MATRIX: ColorMatrix = {
  LIGHT: {
    colors: {
      value_01: "#000000",
      value_02: "#ffffff",
      value_03: "#fff",
      value_04: "#cfcfcf",
      value_05: "#4a7b76",
      value_06: "#b1d7d3",
      value_07: "#8d8d8d",
      value_08: "#1f1f1f",
      value_09: "#030030",
      value_10: "#2f2f2f",
      value_11: "#585454",
      value_12: "#4d4d4d",
      value_13: "#00ffff",
      value_14: "#0f0100",
      value_15: "#a9a9a9",
      value_16: "#535353",
      value_17: "#fe0cc2",
      value_18: "#d7d5d5",
      value_19: "#1a1a1a",
      value_20: "#f0c9db",
      value_21: "#060606",
      value_22: "#b0b001",
      value_23: "#8b8b8b",
      value_24: "#060605",
      value_25: "#2d2d2d",
      value_26: "#4d4d4d",
      value_27: "#efc5c0",
      value_29: "#9e8d91",
      value_30: "#d5c5e1",
      value_31: "#6b593b",
      value_32: "#8b8686",
      value_33: "#e2e2e2",
      value_34: "#a34532",
      value_35: "#10668a",
      value_36: "#283023",
      value_37: "#3c3e54",
      value_38: "#fef8ef",
      value_39: "#0b9090",
      value_40: "#9fc0bc",
      value_41: "#140500",
      value_42: "#cdb0fe",
      value_43: "#ff697a",
      value_44: "#93df9f",
      value_45: "#0a0509",
      value_46: "#d42fa1",
      value_47: "#96ae74",
      value_48: "#0d0e19",
      value_51: "#b0b0b0",
      value_52: "#3c53ab",
      value_53: "#324e04",
      value_54: "#cabda6",
      value_55: "#cdebf0",
      value_56: "#5598b2",
      value_58: "#f0e0cd",
      value_59: "#cabda6",
      value_60: "#006b60",
      value_61: "#051019",
      value_62: "#5e1256",
      value_63: "#5f1f00",
      value_64: "#b4a99f",
    },
    rgb: {
      value_01: "#000000",
      value_02: "rgba(52, 186, 228, 0.75)",
      value_03: "rgba(215, 198, 148, 0.25)",
      value_04: "rgba(255, 45, 129, 0.55)",
      value_05: "rgba(0, 0, 0, 0.55)",
      value_06: "rgba(40, 57, 96, 0.9)",
      value_07: "rgba(0, 0, 0, 0.5)",
      value_08: "rgba(inherit, inherit, inherit, 1)",
      value_09: "rgba(215, 198, 148, 0.2)",
      value_10: "#324e04",
      value_11: "#d7d7d7",
      value_12: "rgba(inherit, inherit, inherit, 1)",
      value_13: "rgba(106, 123, 160, 0.1)",
      value_14: "rgba(0, 0, 0, 0.8)",
      value_15: "rgba(215, 198, 148, 0.75)",
      value_16: "rgba(245, 239, 197, 0.9)",
      value_17: "rgba(16, 96, 68, 0.9)",
      value_18: "#4a7b76",
      value_19: "rgba(215, 198, 148, 0.9)",
      value_20: "rgba(16, 96, 68, 0.9)",
      value_21: "rgba(0, 0, 0, 0.1)",
      value_22: "rgba(16, 96, 68, 0.75)",
      value_23: "rgba(165, 222, 100, 0.5)",
      value_24: "rgba(165, 222, 100, 0.85)",
      value_25: "rgba(16, 96, 68, 0.85)",
      value_26: "rgba(215, 198, 148, 0.9)",
      value_27: "rgba(16, 96, 68, 0.9)",
      value_28: "rgba(165, 222, 100, 0.8)",
      value_29: "rgba(245, 239, 197, 0.88)",
      value_30: "rgba(165, 222, 100, 0.9)",
      value_31: "#96ae74",
      value_32: "#fe0cc2",
      value_33: "rgba(29, 29, 14, 0.5)",
      value_34: "rgba(215, 198, 148, 0.9)",
      value_35: "rgba(25, 25, 25, 0.5)",
      value_36: "rgba(50, 57, 96, 0.8)",
      value_37: "rgba(215, 198, 148, 0.9)",
      value_38: "#0d0e19",
      value_39: "rgba(215, 198, 148, 0.5)",
    },
    boxShadows: {
      value_01: "0px 4px 30px rgba(215, 198, 148, 0.85)",
      value_02: "0px 15px 60px rgba(215, 198, 148, 0.85)",
      value_03: "0px 15px 20px rgba(255, 255, 255, 0.9)",
      value_04: "0px 15px 20px rgba(215, 198, 148, 0.9)",
      value_05: "0 4px 30px rgba(215, 198, 148, 0.85)",
      value_06: "0 -14px 14px rgba(215, 198, 148, 0.95)",
      value_07: "0px 10px 60px rgba(215, 198, 148, 0.9)",
      value_08: "0px 32px 32px rgba(255, 255, 255, 0.9)",
      value_09: "0px 15px 20px rgba(255, 255, 255, 0.9)",
      value_10: "0px 18px 32px rgba(215, 198, 148, 0.95)",
      value_11: "0px 10px 60px rgba(245, 239, 197, 0.82)",
    },
  },
  DARK: {
    colors: {
      value_01: "#ffffff",
      value_02: "#000000",
      value_03: "#1976d2",
      value_04: "#3f51b5",
      value_05: "#79D3CD",
      value_06: "#4ec7bd",
      value_07: "#727272",
      value_08: "#EFEFEF",
      value_09: "#FCAE9D",
      value_10: "#767676",
      value_11: "#A7A4A4",
      value_12: "#B2B2B2",
      value_13: "#FF0000",
      value_14: "#F0FEFF",
      value_15: "#565656",
      value_16: "#ACACAC",
      value_17: "#01133d",
      value_18: "#283960",
      value_19: "#E5E5E5",
      value_20: "#34BAE4",
      value_21: "#F9F9F9",
      value_22: "#4FC8EE",
      value_23: "#606B84",
      value_24: "#F9F9FB",
      value_25: "#d3d3d3",
      value_26: "#B6BAC2",
      value_27: "#4E5C7C",
      value_29: "#6A7BA0",
      value_30: "#352479",
      value_31: "#ACB6CB",
      value_32: "#CDD4E2",
      value_33: "#ECECF1",
      value_34: "#5CBA7C",
      value_35: "#EF5DA8",
      value_36: "#DBCA30",
      value_37: "#CCD2E3",
      value_38: "#289d60",
      value_39: "#E43434",
      value_40: "#E45E34",
      value_41: "#B6E434",
      value_42: "#3446E4",
      value_43: "#34E446",
      value_44: "#EFA35D",
      value_45: "#f9f9fd",
      value_46: "#F0C350",
      value_47: "#314267",
      value_48: "#F4F5F8",
      value_51: "#4F4F4F",
      value_52: "#9DD1FF",
      value_53: "#BAD2FC",
      value_54: "#3347F2",
      value_55: "#3B2716",
      value_56: "#A9E44D",
      value_58: "#04091E",
      value_59: "#F0C350",
      value_60: "#FF8B92",
      value_61: "#FAEE86",
      value_62: "#A1EDA9",
      value_63: "#A0C0FF",
      value_64: "#4B5D7D",
    },
    rgb: {
      value_01: "#ffffff",
      value_02: "rgba(78, 92, 124, 0.25)",
      value_03: "rgba(40, 57, 96, 0.15)",
      value_04: "rgba(252, 210, 129, 0.45)",
      value_05: "rgba(255, 255, 255, 0.45)",
      value_06: "rgba(52, 186, 228, 0.1)",
      value_07: "rgba(255, 255, 255, 0.5)",
      value_08: "rgba(inherit, inherit, inherit, 0)",
      value_09: "rgba(40, 57, 96, 0.8)",
      value_10: "#CDD4E2",
      value_11: "#283960",
      value_12: "rgba(inherit, inherit, inherit, 0)",
      value_13: "rgba(106,123,160,0.8)",
      value_14: "rgba(52, 186, 228, 0.2)",
      value_15: "rgba(40, 57, 96, 0.25)",
      value_16: "rgba(1, 19, 61, 0.1)",
      value_17: "rgba(239, 93, 168, 0.1)",
      value_18: "#ACB6CB",
      value_19: "rgba(52, 186, 228, 0.1)",
      value_20: "rgba(219,202,48,0.1)",
      value_21: "rgba(255,255,255,0.9)",
      value_22: "rgba(239, 93, 168, 0.15)",
      value_23: "rgba(92,186,124, 0.5)",
      value_24: "rgba(92,186,124, 0.15)",
      value_25: "rgba(239,93,168,0.15)",
      value_26: "rgba(52,186,228,0.1)",
      value_27: "rgba(239,93,168,0.1)",
      value_28: "rgba(92, 186, 124, 0.2)",
      value_29: "rgba(1, 19, 61, 0.12)",
      value_30: "rgba(92, 186, 124, 0.1)",
      value_31: "#314267",
      value_32: "#01133D",
      value_33: "rgba(236, 236, 241, 0.5)",
      value_34: "rgba(219, 202, 48, 0.1)",
      value_35: "rgba(229, 229, 229, 0.5)",
      value_36: "rgba(205, 212, 226, 0.2)",
      value_37: "rgba(52, 186, 228, 0.1)",
      value_38: "#F4F5F8",
      value_39: "rgba(40, 57, 96, 0.5)",
    },
    boxShadows: {
      value_01: "0px 4px 30px rgba(40, 57, 96, 0.15)",
      value_02: "0px 15px 60px rgba(40, 57, 96, 0.15)",
      value_03: "0px 15px 20px rgba(0, 0, 0, 0.1)",
      value_04: "0px 15px 20px rgba(40, 57, 96, 0.1)",
      value_05: "0 4px 30px rgba(40, 57, 96, 0.15)",
      value_06: "0 -14px 14px rgba(40, 57, 96, 0.05)",
      value_07: "0px 10px 60px rgba(40, 57, 96, 0.1)",
      value_08: "0px 32px 32px rgba(0, 0, 0, 0.1)",
      value_09: "0px 15px 20px rgba(0, 0, 0, 0.1)",
      value_10: "0px 18px 32px rgba(40, 57, 96, 0.05)",
      value_11: "0px 10px 60px rgba(1, 19, 61, 0.18)",
    },
  },
};
