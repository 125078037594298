import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { IInvoice, ToggleActionType } from "@/shared/types";

import { IGetManyInvoicesParams } from "../model/types/interfaces";
import { selectParams } from "../model/selectors";
import {
  modifyInvoicesData,
  modifyInvoiceData,
  getCreateDtoByFormData,
  getUpdateDtoByFormData,
} from "./helpers";
import {
  getInvoice,
  updateInvoice,
  createInvoice,
  deleteInvoice,
  getManyInvoices,
} from "./senders";
import {
  ClickedRowItemPayload,
  CreateInvoicePayload,
  DeleteInvoicePayload,
  UpdateInvoicePayload,
} from "./types/types";
import { commonSlice } from "@/entities/common";
import { invoicesSlice } from "../model/slice";

export function* handleRetrieveManyInvoices() {
  const { setInvoices } = invoicesSlice;
  const { setLoading } = commonSlice;
  const body: IGetManyInvoicesParams = yield select(selectParams);

  yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getManyInvoices, body);
  const modifiedInvoices: Array<IInvoice> = yield call(
    modifyInvoicesData,
    items
  );

  yield put(setInvoices({ invoices: { items: modifiedInvoices, count } }));
  yield put(setLoading({ isLoading: false }));
}
