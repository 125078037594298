import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { API_URL } from "./apiUrl";
import { emitter } from "../utils/eventEmitter";
import { StorageKeys } from "../types/enums/StorageKeys.enum";
import { IRequestParams } from "./types";
import { getItemFromLS, setItemToLS } from '../utils/localStorageHelpers';
import { commonSagaActions } from "@/entities/common";

let isRefreshing = false;
let failedQueue: {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
  config: AxiosRequestConfig;
}[] = [];

const processQueue = (error: any) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(axios(prom.config));
    }
  });

  failedQueue = [];
};

const refreshToken = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_URL.domain}${API_URL.auth.refreshSession}`,
      method: "GET",
    })
      .then((response) => {
        if (!response) {
          reject("empty_response");
          return;
        }
        if (response.status >= 200 && response.status < 300) {
          if (response.headers.authorization) {
            const token = JSON.stringify(response.headers.authorization);
            setItemToLS(StorageKeys.AUTH_TOKEN, token);
            resolve(true);
          } else {
            reject("authorization_header_missing");
          }
        } else {
          reject("invalid_response_status");
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const config = { ...request, withCredentials: true };
  const token = getItemFromLS(StorageKeys.AUTH_TOKEN);
  try {
    config.headers = config.headers || {};
    config.headers.Authorization = JSON.parse(token as string);
    return config;
  } catch (error) {
    return config;
  }
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;
    const originalRequest = config;
    const { userLoggedOut } = commonSagaActions;

    if (
      response?.config.url.includes("refresh_session") &&
      response.status === 401 &&
      response?.data?.error?.code === 1002
    ) {
      // refresh token expired
      isRefreshing = false;
      failedQueue = [];
      emitter.emit("UNAUTHORIZED");
      return Promise.reject(error);
    }

    if (
      response?.status === 401 &&
      response?.data?.error?.code === 1002 &&
      !originalRequest._retry
    ) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          await refreshToken();
          isRefreshing = false;
          processQueue(null);
        } catch (err) {
          isRefreshing = false;
          failedQueue = [];

          try {
            const { rootStore } = await import('@/app/store');
            rootStore?.dispatch(userLoggedOut());
          } catch (dispatchError) {
            console.error('Failed to dispatch userLoggedOut:', dispatchError);
          }

          emitter.emit("UNAUTHORIZED");
        }
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject, config: originalRequest });
      });
    }

    return Promise.reject(error);
  }
);

// const request = (
//   url: string,
//   method: string,
//   data?: any,
//   params?: any,
//   headers?: any
// ) =>
//   axios({ url, method, data, params, headers })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       throw error;
//     });

// const downloadRequest = ({
//   url,
//   filename,
//   data,
//   extension,
//   options,
// }: {
//   url: string;
//   filename: string;
//   data?: any;
//   extension: string;
//   options?: AxiosRequestConfig;
// }) =>
//   axios({
//     url,
//     method: "GET",
//     data,
//     responseType: "blob",
//     ...options,
//   }).then((response) => {
//     const href = URL.createObjectURL(response.data);

//     const link = document.createElement("a");
//     link.href = href;
//     link.setAttribute("download", `${filename}.${extension}`);
//     document.body.appendChild(link);
//     link.click();

//     document.body.removeChild(link);
//     URL.revokeObjectURL(href);
//   });

// export const apiClientInterface = () => ({
//   get: ({
//     url,
//     data,
//     params,
//     subPath = "",
//   }: {
//     url: string;
//     data?: any;
//     params?: any;
//     subPath?: string;
//   }) => request(`${URL_PREFIX}${url}${subPath}`, "GET", data, params),
//   post: ({
//     url,
//     data,
//     params,
//     subPath = "",
//     headers,
//   }: {
//     url: string;
//     data?: any;
//     params?: any;
//     subPath?: string;
//     headers?: any;
//   }) => request(`${URL_PREFIX}${url}${subPath}`, "POST", data, params, headers),
//   put: ({ url, data, params }: { url: string; data?: any; params?: any }) =>
//     request(`${URL_PREFIX}${url}`, "PUT", data, params),
//   patch: ({
//     url,
//     data,
//     params,
//     subPath = "",
//   }: {
//     url: string;
//     data?: any;
//     params?: any;
//     subPath?: string;
//   }) => request(`${URL_PREFIX}${url}${subPath}`, "PATCH", data, params),
//   delete: ({
//     url,
//     data,
//     params,
//     subPath = "",
//   }: {
//     url: string;
//     data?: any;
//     params?: any;
//     subPath?: string;
//   }) => request(`${URL_PREFIX}${url}${subPath}`, "DELETE", data, params),
//   download: ({
//     url,
//     subPath = "",
//     data,
//     filename,
//     extension,
//     options = {},
//   }: {
//     url: string;
//     subPath?: string;
//     data?: any;
//     filename: string;
//     extension: string;
//     options?: AxiosRequestConfig;
//   }) =>
//     downloadRequest({
//       url: `${URL_PREFIX}${url}${subPath}`,
//       data,
//       filename,
//       extension,
//       options,
//     }),
// });

export const sendRequest = async ({
  path,
  method,
  data,
  params,
  headers,
}: IRequestParams) => {
  try {
    return await axios({
      url: `${API_URL.domain}${path}`,
      method,
      data,
      params,
      headers,
    });
  } catch (error) {
    throw error;
  }
};
