import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleRetrieveManyShippingLocations,
  handleRetrieveManyCompanies,
  handleRetrieveCompany,
  handleUpdateCompany,
  handleCreateCompany,
  handleDeleteCompany,
  handleUpdateShippingLocation,
  handleCreateShippingLocation,
  handleDeleteShippingLocation,
} from './workers';

export function* watcher() {
  yield takeLatest(
    ActionType.RETRIEVE_MANY_SHIPPING_LOCATIONS,
    handleRetrieveManyShippingLocations
  );
  yield takeLatest(
    ActionType.RETRIEVE_MANY_COMPANIES,
    handleRetrieveManyCompanies
  );
  yield takeLatest(
    ActionType.RETRIEVE_COMPANY,
    handleRetrieveCompany
  );
  yield takeLatest(
    ActionType.UPDATE_COMPANY,
    handleUpdateCompany
  );
  yield takeLatest(
    ActionType.CREATE_COMPANY,
    handleCreateCompany
  );
  yield takeLatest(
    ActionType.CREATE_SHIPPING_LOCATION,
    handleCreateShippingLocation
  );
  yield takeLatest(
    ActionType.DELETE_COMPANY,
    handleDeleteCompany
  );
  yield takeLatest(
    ActionType.DELETE_SHIPPING_LOCATION,
    handleDeleteShippingLocation
  );
  yield takeLatest(
    ActionType.UPDATE_SHIPPING_LOCATION,
    handleUpdateShippingLocation
  );
}
