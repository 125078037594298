import { createSlice } from "@reduxjs/toolkit";

import { InitialStateType, SLICE_NAME, CaseReducers } from "./types";

const INITIAL_STATE: InitialStateType<any> = {
  data: { items: [], count: 0 },
  params: { skip: 0, limit: 10 },
  isLoading: false,
};

const selectSlice = createSlice<
  InitialStateType<any>,
  CaseReducers<InitialStateType<any>, any>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setData: (draft, { payload }) => {
      draft.data = payload.data;
    },
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
    setIsLoading: (draft, { payload }) => {
      draft.isLoading = payload.isLoading;
    },
  },
});

export const selectActions = selectSlice.actions;
export const selectReducer = selectSlice.reducer;
