import { call } from "redux-saga/effects";
import { UUID } from "crypto";

import { API_URL } from "@/shared/api/apiUrl";
import { sendRequest } from "@/shared/api/requestSender";
import { HttpMethod, IOrder } from "@/shared/types";

import { IGetManyOrdersParams } from "../model/types/interfaces";
import {
  CreateOrderResponse,
  DeleteOrderResponse,
  UpdateOrderResponse,
  GetManyOrdersResponse,
} from "./types/types";

const sendCreateOrderRequest = (body: Partial<IOrder> | unknown) =>
  sendRequest({
    path: API_URL.clientOrders.createCart,
    method: HttpMethod.POST,
    data: body,
  });

export const sendGetOrderRequest = (orderId: UUID) =>
  sendRequest({
    path: `${API_URL.clientOrders.getOne}/${orderId}`,
    method: HttpMethod.GET,
  });

export const sendAssignOrderRequest = (orderId: UUID) =>
  sendRequest({
    path: `${API_URL.clientOrders.assignOrder.start}${orderId}${API_URL.clientOrders.assignOrder.end}`,
    method: HttpMethod.POST,
  });

const sendUpdateOrderRequest = (
  orderId: UUID,
  body: Partial<IOrder> | unknown
) =>
  sendRequest({
    path: `${API_URL.inventory.invoices.updateOne}/${orderId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

export const sendManageOrderRequest = (
  orderId: UUID,
  body: Partial<IOrder> | unknown
) =>
  sendRequest({
    path: `${API_URL.clientOrders.manageOrder.start}${orderId}${API_URL.clientOrders.manageOrder.end}`,
    method: HttpMethod.PATCH,
    data: body,
  });

const sendDeleteOrderRequest = (orderId: UUID) =>
  sendRequest({
    path: `${API_URL.inventory.invoices.deleteOne}/${orderId}`,
    method: HttpMethod.DELETE,
  });

const sendGetManyOrdersRequest = (body: IGetManyOrdersParams) =>
  sendRequest({
    path: API_URL.clientOrders.getMany,
    method: HttpMethod.POST,
    data: body,
  });

export function* getManyOrders(body: IGetManyOrdersParams) {
  try {
    const response: GetManyOrdersResponse = yield call(
      sendGetManyOrdersRequest,
      body
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("RETRIEVE ORDERS GET MANY ERROR", error);
  }
}
