const basePath = "static/assets/common/";

export const commonImages = {
  appLogo: `${basePath}MRN_app_logo.png`,
  loginPage: `${basePath}MRN_login_page.png`,
  common_example_logo: `${basePath}accessibility_icon.svg`,
  common_example_logo2: `${basePath}biobank_logo.png`,
  common_example_logo3: `${basePath}icon_default_laboratory.png`,
  auth_image_1: `${basePath}auth/auth_image_1.png`,
  auth_image_2: `${basePath}auth/auth_image_2.png`,
  auth_image_3: `${basePath}auth/auth_image_3.png`,
  auth_image_4: `${basePath}auth/auth_image_4.png`,
  change_password: `${basePath}change_password.svg`,
  check_var_4: `${basePath}check_var_4.svg`,
  dot: `${basePath}dot.svg`,
  view_var_4: `${basePath}view_var_4.svg`,
  view_var_5: `${basePath}view_var_5.svg`,
  arrow_top_var_3: `${basePath}arrow_top_var_3.svg`,
  arrow_top_var_4: `${basePath}arrow_top_var_4.svg`,
  pencil_var_3: `${basePath}pencil_var_3.svg`,
  delete_var_4: `${basePath}delete_var_4.svg`,
  close_var_1: `${basePath}close_var_1.svg`,
  close_var_2: `${basePath}close_var_2.svg`,
  copy_var2: `${basePath}copy_var2.svg`,
  arrow_down_var_1: `${basePath}arrow_down_var_1.svg`,
  calendar_var_2: `${basePath}calendar_var_2.svg`,
  warning: `${basePath}warning.svg`,
  warning_alert: `${basePath}warning_alert.svg`,
  view_var_1: `${basePath}view_var_1.svg`,
  view_var_3: `${basePath}view_var_3.svg`,
  icon_default_user: `${basePath}icon_default_user.png`,
  show_type: `${basePath}show_type.svg`,
  info_var_2: `${basePath}info_var_2.svg`,
  info_var_3: `${basePath}info_var_3.svg`,
  check_var_7: `${basePath}check_var_7.svg`,
  check_var_1: `${basePath}check_var_1.svg`,
  add_lab_logo: `${basePath}add_lab_logo.svg`,
  camera: `${basePath}camera.svg`,
  icon_default_user_var2: `${basePath}icon_default_user_var2.png`,
  profile: `${basePath}profile.svg`,
  profile_var_3: `${basePath}profile_var_3.svg`,
  profile_active: `${basePath}profile_active.svg`,
  profile_icon: `${basePath}profile.svg`,
  logout_icon: `${basePath}logout_icon_var_2.svg`,
  rotate: `${basePath}rotate.svg`,
  search_light: `${basePath}search_light.svg`,
  arrow_right_var_1: `${basePath}arrow_right_var_1.svg`,
  cart_icon: `${basePath}cart_icon.svg`,
  no_data_var_2: `${basePath}no_data_var_2.svg`,
  headphones: `${basePath}headphones.svg`,
  profile_var_9: `${basePath}profile_var_9.svg`,
  info: `${basePath}info.svg`,
  star: `${basePath}star.svg`,
  sample: `${basePath}samples.svg`,
  folder: `${basePath}folder.svg`,
};

const darkPath = "static/assets/dark/";

export const darkThemeImages = {
  theme_example_logo: `${darkPath}add_round_sidebar.svg`,
};

const lightPath = "static/assets/light/";

export const lightThemeImages = {
  theme_example_logo: `${lightPath}add_round_sidebar_plus.svg`,
};
