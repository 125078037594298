import { call } from "redux-saga/effects";
import { UUID } from "crypto";

import { API_URL } from "@/shared/api/apiUrl";
import { sendRequest } from "@/shared/api/requestSender";
import { HttpMethod, IClientCompany } from "@/shared/types";

import { IGetManyClientCompaniesParams } from "../model/types/interfaces";
import {
  GetClientCompanyResponse,
  CreateClientCompanyResponse,
  DeleteClientCompanyResponse,
  UpdateClientCompanyResponse,
  GetManyClientCompaniesResponse,
} from "./types/types";

const sendCreateClientCompanyRequest = (body: Partial<IClientCompany> | unknown) =>
  sendRequest({
    path: API_URL.clientCompanies.createOne,
    method: HttpMethod.POST,
    data: body,
  });

const sendCreateShippingLocationRequest = (body: Partial<IClientCompany> | unknown) =>
  sendRequest({
    path: API_URL.clientCompanies.shippingLocations.createOne,
    method: HttpMethod.POST,
    data: body,
  });

const sendGetClientCompanyRequest = (clientCompanyId: number) =>
  sendRequest({
    path: `${API_URL.clientCompanies.getOne}/${clientCompanyId}`,
    method: HttpMethod.GET,
  });

const sendUpdateClientCompanyRequest = (
  clientCompanyId: number,
  body: Partial<IClientCompany> | unknown
) =>
  sendRequest({
    path: `${API_URL.clientCompanies.updateOne}/${clientCompanyId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

const sendUpdateShippingLocationRequest = (
  clientCompanyId: number,
  body: Partial<IClientCompany> | unknown
) =>
  sendRequest({
    path: `${API_URL.clientCompanies.shippingLocations.updateOne}/${clientCompanyId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

const sendDeleteClientCompanyRequest = (clientCompanyId: number) =>
  sendRequest({
    path: `${API_URL.clientCompanies.deleteOne}/${clientCompanyId}`,
    method: HttpMethod.DELETE,
  });

const sendDeleteShippingLocationRequest = (shippingLocationId: number) =>
  sendRequest({
    path: `${API_URL.clientCompanies.shippingLocations.deleteOne}/${shippingLocationId}`,
    method: HttpMethod.DELETE,
  });

const sendGetManyClientCompaniesRequest = (body: IGetManyClientCompaniesParams) =>
  sendRequest({
    path: API_URL.clientCompanies.getMany,
    method: HttpMethod.POST,
    data: body,
  });

const sendGetManyShippingLocationsRequest = (body: IGetManyClientCompaniesParams) =>
  sendRequest({
    path: API_URL.clientCompanies.shippingLocations.getMany,
    method: HttpMethod.POST,
    data: body,
  });

export function* createClientCompany(dto: Partial<IClientCompany>) {
  try {
    const response: CreateClientCompanyResponse = yield call(
      sendCreateClientCompanyRequest,
      dto
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("CREATE COMPANY ERROR", err);
  }
}

export function* createShippingLocation(dto: Partial<IClientCompany>) {
  try {
    const response: CreateClientCompanyResponse = yield call(
      sendCreateShippingLocationRequest,
      dto
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("CREATE COMPANY ERROR", err);
  }
}

export function* getClientCompany(clientCompanyId: number) {
  try {
    const response: GetClientCompanyResponse = yield call(
      sendGetClientCompanyRequest,
      clientCompanyId
    );
    return response.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("GET CLIENT COMPANY ERROR", err);
  }
}

export function* updateClientCompany(clientCompanyId: number, dto: Partial<IClientCompany>) {
  try {
    const response: UpdateClientCompanyResponse = yield call(
      sendUpdateClientCompanyRequest,
      clientCompanyId,
      dto
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("UPDATE CLIENT COMPANY ERROR", err);
    throw err;
  }
}

export function* updateShippingLocation(shippingLocationId: number, dto: Partial<IClientCompany>) {
  try {
    const response: UpdateClientCompanyResponse = yield call(
      sendUpdateShippingLocationRequest,
      shippingLocationId,
      dto
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("UPDATE CLIENT COMPANY ERROR", err);
    throw err;
  }
}

export function* deleteClientCompany(clientCompanyId: number) {
  try {
    const response: DeleteClientCompanyResponse = yield call(
      sendDeleteClientCompanyRequest,
      clientCompanyId
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.error("DELETE CLIENT COMPANY ERROR", err);
    throw err;
  }
}

export function* deleteShippingLocation(shippingLocationId: number) {
  try {
    const response: DeleteClientCompanyResponse = yield call(
      sendDeleteShippingLocationRequest,
      shippingLocationId
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.error("DELETE SHIPPING LOCATION ERROR", err);
    throw err;
  }
}

export function* getManyShippingLocations(body: IGetManyClientCompaniesParams) {
  try {
    const response: GetManyClientCompaniesResponse = yield call(
      sendGetManyShippingLocationsRequest,
      body
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("RETRIEVE SHIPPING LOCATIONS GET MANY ERROR", error);
  }
}


export function* getManyClientCompanies(body: IGetManyClientCompaniesParams) {
  try {
    const response: GetManyClientCompaniesResponse = yield call(
      sendGetManyClientCompaniesRequest,
      body
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("RETRIEVE CLIENT COMPANY GET MANY ERROR", error);
  }
}
