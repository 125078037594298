import { takeLatest } from "redux-saga/effects";

import { ActionType } from "./types/actionType";
import {
  handleCreateVendor,
  handleUpdateVendor,
  handleDeleteVendor,
  handleRetrieveVendor,
  handleRetrieveManyVendors,
} from "./workers";

export function* watcher() {
  yield takeLatest(ActionType.CREATE_VENDOR, handleCreateVendor);
  yield takeLatest(ActionType.UPDATE_VENDOR, handleUpdateVendor);
  yield takeLatest(ActionType.DELETE_VENDOR, handleDeleteVendor);
  yield takeLatest(ActionType.RETRIEVE_VENDOR, handleRetrieveVendor);
  yield takeLatest(ActionType.RETRIEVE_MANY_VENDORS, handleRetrieveManyVendors);
}
