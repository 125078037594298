import { DateTime } from "luxon";

import { GetManyUsersStatus, IUser } from "@/shared/types";
import { RE_REPLACE_NUMBER } from "@/shared/utils/regExp";

import { IUserModified } from "../model/types/interfaces";

export const modifyUsersData = (items: Array<IUser>): Array<IUserModified> => {
  return items.map((item: IUser) => {
    return { ...item, fullName: `${item.firstName} ${item.lastName}` };
  });
};

export const modifyUserData = (user: IUser): IUserModified => {
  return { ...user, fullName: `${user.firstName} ${user.lastName}` };
};

export const getCreateDtoByFormData = (formData: any) => ({
  role: formData.role,
  email: formData.email,
  gender: formData.gender,
  status: formData.status ? formData.status : GetManyUsersStatus.ACTIVE,
  lastName: formData.lastName,
  firstName: formData.firstName,
  phoneNumber: formData.phoneNumber
    ? formData.phoneNumber.replace(RE_REPLACE_NUMBER, "")
    : undefined,
  dateOfBirth: formData.dateOfBirth
    ? formatDate(formData.dateOfBirth)
    : undefined,
  clientCompanyId: formData.clientCompanyId, // "clientCompanyId": 123, for UserRole.CLIENT or UserRole.CLIENT_ADMIN
});

export const getUpdateDtoByFormData = (formData: any) => ({
  role: formData.role,
  email: formData.email,
  gender: formData.gender,
  status: formData.status,
  lastName: formData.lastName,
  firstName: formData.firstName,
  phoneNumber: formData.phoneNumber
    ? formData.phoneNumber.replace(RE_REPLACE_NUMBER, "")
    : undefined,
  dateOfBirth: formData.dateOfBirth
    ? formatDate(formData.dateOfBirth)
    : undefined,
  clientCompanyId: formData.clientCompanyId, // "clientCompanyId": 123, for UserRole.CLIENT or UserRole.CLIENT_ADMIN
});

const formatDate = (timestamp: number): string | null => {
  const localDateTime = DateTime.fromMillis(timestamp, { zone: "UTC+2" });
  const utcDateTime = localDateTime.setZone("utc");

  return utcDateTime.toISO({ includeOffset: false }); // e.g., 2024-07-16T10:36:13.385Z
};
