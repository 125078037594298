import { createSlice } from "@reduxjs/toolkit";
import { InitialStateType, SLICE_NAME, CaseReducers } from "./types/types";

const INITIAL_STATE: InitialStateType = {
  data: { items: [], count: 0 },
  params: {},
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
    setStorageFacilities: (draft, { payload }) => {
      draft.data = payload.storageFacilities;
    },
  },
});

export const storageFacilitiesSlice = slice.actions;
export const storageFacilitiesReducer = slice.reducer;
