import React from "react";
import { Provider, ReactReduxContext } from "react-redux";
import { rootStore } from "@/app/store";

export function AppStateProvider({
  children,
}: React.PropsWithChildren): JSX.Element {
  return (
    <Provider store={rootStore} context={ReactReduxContext}>
      {children}
    </Provider>
  );
}
