import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IAuthParams } from "./interfaces";

export const SLICE_NAME = "auth";

export type InitialStateType = {
  params: IAuthParams;
};

type AuthParamsPayload = {
  params: IAuthParams;
};

export type CaseReducers<State> = {
  setAuthParams: CaseReducer<State, PayloadAction<AuthParamsPayload>>;
};
