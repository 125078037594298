import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const Wrapper = styled.div`
  width: ${({ theme }) => theme.coefficient * 190}px;
`;

export const IconLogout = styled.div`
  width: ${({ theme }) => theme.coefficient * 24}px;
  height: ${({ theme }) => theme.coefficient * 24}px;
  background-image: ${({ theme }) => `url(${theme.images.logout_icon})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

interface IconProfileProps {
  active: boolean;
}

export const IconProfile = styled.div<IconProfileProps>`
  width: ${({ theme }) => theme.coefficient * 24}px;
  height: ${({ theme }) => theme.coefficient * 24}px;
  background-image: ${({ theme, active }) =>
    `url(
      ${active ? theme.images.profile_active : theme.images.profile_icon}
    )`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

// export const IconAvatar = styled.img`
//   position: relative;
//   width: ${({ theme }) => theme.coefficient * 24}px;
//   height: ${({ theme }) => theme.coefficient * 24}px;
//   border-radius: 50%;
//   padding: ${({ theme }) => theme.coefficient * 1}px;
//   background-color: ${({ theme }) => theme.colors.color_46};
// `;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: ${({ theme }) => theme.coefficient * 120}px;
`;

export const Items = styled.div`
  color: ${({ theme }) => theme.colors.color_215};
  margin: 0 ${({ theme }) => theme.coefficient * 24}px 0
    ${({ theme }) => theme.coefficient * 11}px;
  max-width: ${({ theme }) => theme.coefficient * 180}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CustomButton = styled(Button)(({ theme }) => ({
  height: `${theme.coefficient * 42}px`,
  minWidth: `${theme.coefficient * 190}px !important`,
  backgroundColor: `${theme.colors.value_18} !important`,
  color: `${theme.colors.value_01} !important`,
  margin: `0`,
  fontSize: "1.2rem !important",
  gridGap: `0 ${theme.coefficient * 10}px`,
  textWrap: "nowrap",
}));

export const CustomMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: `${theme.colors.value_18}`,
    minWidth: `${theme.coefficient * 190}px !important`,
    marginTop: `${theme.coefficient * 4}px !important`,
  },
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "1.4rem !important",
  gridGap: "0 12px",

  "&:hover": {
    backgroundColor: `${theme.rgb.value_06}`,
  },
}));
