import { createSlice } from "@reduxjs/toolkit";
import { GetManyUsersStatus } from "@/shared/types";
import { SLICE_NAME, InitialStateType, CaseReducers } from "./types/types";

const INITIAL_STATE: InitialStateType = {
  data: { items: [], count: 0 },
  params: {
    status: GetManyUsersStatus.ACTIVE,
  },
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setUsers: (draft, { payload }) => {
      draft.data = payload.data;
    },
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
  },
});

export const usersSlice = slice.actions;
export const usersReducer = slice.reducer;
