import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/ActionType";
import {
  handleToggleSideBlock,
  handleSaveButtonClicked,
  handleCreateButtonClicked,
} from "./workers";

export function* watcher() {
  yield takeLatest(ActionType.TOGGLE_SIDE_BLOCK, handleToggleSideBlock);
  yield takeLatest(ActionType.SAVE_BUTTON_CLICKED, handleSaveButtonClicked);
  yield takeLatest(ActionType.CREATE_BUTTON_CLICKED, handleCreateButtonClicked);
}
