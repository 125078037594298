import React from "react";
import clsx from "clsx";
import { Badge, Tab, Tabs, TabsProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import { ICart, ITabItem } from "@/shared/types";
import { useCommonActions } from "@/entities/common";
import useStyles from "./styles";

interface CustomTabsMUIProps extends TabsProps {
  cart: ICart | null;
  tabs: Array<ITabItem>;
  activeTab: ITabItem | null;
  cssVariant: "navBarRegular" | "navBar";
}

const NavTabs: React.FC<CustomTabsMUIProps> = ({
       cart,
       tabs,
       activeTab,
       cssVariant = "navBarRegular",
     }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigationTabClicked } = useCommonActions();

  const onChangeCallback = (_: React.SyntheticEvent, newValue: number) => {
    navigationTabClicked({ value: newValue });
  };

  const className = clsx(classes.root, cssVariant && classes[cssVariant]);
  const getIconTabByName = (name: string): JSX.Element | undefined => {
    switch (name) {
      case "Cart":
        return <ShoppingCartOutlinedIcon />;
      case "Specimen search":
        return <TravelExploreOutlinedIcon />;
      case "Orders":
        return <ListAltOutlinedIcon />;
      case "Contact us":
        return <ContactMailOutlinedIcon />;
      default:
        return undefined;
    }
  };

  const badgeCount = cart?.samples.length || 0;

  return (
    <Tabs
      value={activeTab?.id}
      onChange={onChangeCallback}
      className={className}
      role="navigation"
    >
      {tabs.map((tab: ITabItem) => (
        <Tab
          icon={getIconTabByName(tab.name)}
          key={tab.id}
          label={
            tab.name === "Cart" ? (
              <Badge badgeContent={badgeCount}>{t(tab.translateKey)}</Badge>
            ) : (
              t(tab.translateKey)
            )
          }
          className={clsx(classes.tab, {
            [classes.cartTab]: tab.name === 'Cart',
            [classes.hidden]: !tab.render,
          })}
          sx={tab.name === 'Cart' ? { position: 'absolute !important' } : {}}
        />
      ))}
    </Tabs>
  );
};

export default NavTabs;
