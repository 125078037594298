import { takeLatest } from "redux-saga/effects";

import { ActionType } from "./types/actionType";
import {
  handleUpdateChat,
  handleDeleteChat,
  handleRetrieveChat,
  handleRetrieveManyChats,
  handleSendMessageAuthorized,
} from "./workers";

export function* watcher() {
  yield takeLatest(ActionType.UPDATE_CHAT, handleUpdateChat);
  yield takeLatest(ActionType.DELETE_CHAT, handleDeleteChat);
  yield takeLatest(ActionType.RETRIEVE_CHAT, handleRetrieveChat);
  yield takeLatest(ActionType.RETRIEVE_MANY_CHATS, handleRetrieveManyChats);
  yield takeLatest(ActionType.SEND_MESSAGE_AUTHORIZED, handleSendMessageAuthorized);
}
