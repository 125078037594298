import { RootState } from "@/shared/types";
import { ISamplesData, IGetManySamplesParams } from "./types/interfaces";

export const selectSamples = (state: RootState): ISamplesData =>
  state.samples.data;

export const selectAvailableSamples = (state: RootState): ISamplesData =>
  state.samples.availableSamples;

export const selectParams = (state: RootState): IGetManySamplesParams =>
  state.samples.params;
