import { createSlice } from "@reduxjs/toolkit";
import { StorageKeys } from "@/shared/types/enums/StorageKeys.enum";
import { getItemFromLS } from "@/shared/utils/localStorageHelpers";
import { SLICE_NAME, InitialStateType, CaseReducers } from "./types/types";

const AUTH_TOKEN_FROM_LS = getItemFromLS(StorageKeys.AUTH_TOKEN);

const INITIAL_STATE: InitialStateType = {
  params: {
    isAuth: AUTH_TOKEN_FROM_LS ? true : false,
    authToken: AUTH_TOKEN_FROM_LS ? AUTH_TOKEN_FROM_LS : "",
  },
};

const authSlice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setAuthParams: (draft, { payload }) => {
      draft.params = payload.params;
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
