import { createSlice } from "@reduxjs/toolkit";
import { InitialStateType, CaseReducers, SLICE_NAME } from "./types/types";

const INITIAL_STATE: InitialStateType = {
  data: {
    items: [],
    count: 0,
  },
  bodySystems: {
    items: [],
    count: 0,
  },
  areaItems: {
    items: [],
    count: 0,
  },
  activeAreaName: '',
  params: {},
  loincBiomarkers: {
    items: [],
    count: 0,
  },
  activeLoincBiomarker: null,
};

const researchAreasSlice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setData: (draft, { payload }) => {
      draft.data = payload.data;
    },
    setBodySystems: (draft, { payload }) => {
      draft.bodySystems = payload.bodySystems;
    },
    setAreaItems: (draft, { payload }) => {
      draft.areaItems = payload.areaItems;
    },
    setActiveAreaName: (draft, { payload }) => {
      draft.activeAreaName = payload.activeAreaName;
    },
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
    setLoincBiomarkers: (draft, { payload }) => {
      draft.loincBiomarkers = payload.loincBiomarkers;
    },
    setActiveLoincBiomarker: (draft, { payload }) => {
      draft.activeLoincBiomarker = payload.activeLoincBiomarker;
    },
  },
});

export const researchAreasActions = researchAreasSlice.actions;
export const researchAreasReducer = researchAreasSlice.reducer;
