import { IStorageFacility } from "@/shared/types";

export const modifyStorageFacilitiesData = (
  items: Array<IStorageFacility>
): Array<IStorageFacility> => {
  return items.map((item: IStorageFacility) => {
    return { ...item };
  });
};

export const modifyStorageFacilityData = (
  item: IStorageFacility
): IStorageFacility => {
  return { ...item };
};

export const getCreateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
  };
  return a;
};

export const getUpdateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
  };
  return a;
};
