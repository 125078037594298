import { API_URL } from "@/shared/api/apiUrl";
import { HttpMethod } from "@/shared/types";
import { sendRequest } from "@/shared/api/requestSender";

// Temporary here
export const sendUserConditions = () =>
  sendRequest({
    path: API_URL.auth.conditions,
    method: HttpMethod.GET,
  });

export const sendLoginRequest = (body: { email: string; password: string }) =>
  sendRequest({
    path: API_URL.auth.login,
    method: HttpMethod.POST,
    data: body,
  });

export const sendLogOutRequest = () =>
  sendRequest({
    path: API_URL.auth.logout,
    method: HttpMethod.GET,
  });

export const sendCheckMeRequest = () =>
  sendRequest({
    path: API_URL.auth.me,
    method: HttpMethod.GET,
  });

export const sendUpdateMeRequest = (body: any) =>
  sendRequest({
    path: API_URL.auth.me,
    method: HttpMethod.PATCH,
    data: body,
  });

// export const signUp = (data: any) => userApi.post({ url: apiUrl.registration.index, data });
// export const invitedSignUp = (data: any, query: { lab_id: any; patient_lab_id: any; email: any; }) => userApi.post({ url: `${apiUrl.registration.index}?lab_id=${query.lab_id}&patient_lab_id=${query.patient_lab_id}&email=${query.email}`, data });
// export const invitedSignIn = (data: any, query: { lab_id: any; patient_lab_id: any; email: any; }) => userApi.post({ url: `${apiUrl.auth.index}?lab_id=${query.lab_id}&patient_lab_id=${query.patient_lab_id}&email=${query.email}`, data });
// export const recoverPassword = (data: any) => userApi.put({ url: apiUrl.recoverPassword.index, data });
// export const resetPassword = (data: any) => userApi.put({ url: apiUrl.resetPassword.index, data });
