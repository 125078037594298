import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IGetManyOrdersParams, IOrdersData } from "./interfaces";

export const SLICE_NAME = "orders";

export type InitialStateType = {
  data: IOrdersData;
  params: IGetManyOrdersParams;
};

type OrdersDataPayload = {
  orders: IOrdersData;
};

type GetManyOrdersParamsPayload = {
  params: IGetManyOrdersParams;
};

export type CaseReducers<State> = {
  setParams: CaseReducer<State, PayloadAction<GetManyOrdersParamsPayload>>;
  setOrders: CaseReducer<State, PayloadAction<OrdersDataPayload>>;
};
