import { Route, Routes } from "react-router-dom";
import { ROUTER_PATHS } from "@/shared/config/router-paths";
import { LoginPage } from "@/pages";

export function AuthRouter() {
  const { signIn } = ROUTER_PATHS.auth;

  return (
    <Routes>
      <Route path={signIn.path} element={<LoginPage />} />
    </Routes>
  );
}
