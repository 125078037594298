import { RE_REPLACE_NUMBER } from "@/shared/utils/regExp";

interface Message {
  updatedAt: string;
  [key: string]: any;
}

export function sortMessagesByDate(messages: Message[]): Message[] {
  return messages.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
}

export const getCreateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
    type: formData.type,
    lrIntegrated: formData.lrIntegrated,
    active: formData.active,
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    state: formData.state,
    zip: formData.zip,
    phoneNumber: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || null,
    fax: formData.fax?.replace(RE_REPLACE_NUMBER, "") || null,
    logoUrl: "https://vendor-name.com/logo.png",
    contactPerson: {
      firstName: formData.contactPerson.firstName,
      lastName: formData.contactPerson.lastName,
      position: formData.contactPerson.position,
      phoneNumber: formData.contactPerson.phoneNumber,
      email: formData.contactPerson.email,
    },
    metadata: {
      website: formData.website,
      cliaLicense: formData.cliaLicense,
      npi: formData.npi,
    },
  };
  return a;
};

export const getUpdateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
    type: formData.type,
    lrIntegrated: formData.lrIntegrated,
    active: formData.active,
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    state: formData.state,
    zip: formData.zip,
    phoneNumber: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || null,
    fax: formData.fax?.replace(RE_REPLACE_NUMBER, "") || null,
    logoUrl: "https://vendor-name.com/logo.png",
    contactPerson: {
      firstName: formData.contactPerson.firstName,
      lastName: formData.contactPerson.lastName,
      position: formData.contactPerson.position,
      phoneNumber: formData.contactPerson.phoneNumber,
      email: formData.contactPerson.email,
    },
    metadata: {
      website: formData.website,
      cliaLicense: formData.cliaLicense,
      npi: formData.npi,
    },
  };
  return a;
};
