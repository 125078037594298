import styled from "@emotion/styled";
import { IconContainer } from "../../styles";

export const IconSuccess = styled.div`
  top: ${({ theme }) => theme.coefficient * 24}px;
  right: ${({ theme }) => theme.coefficient * 40}px;
  height: ${({ theme }) => theme.coefficient * 24}px;
  width: ${({ theme }) => theme.coefficient * 24}px;
  background-image: ${({ theme }) => `url(${theme.images.check_var_7})`};
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
`;

export const IconContainerSuccess = styled(IconContainer)`
  & circle {
    fill: ${({ theme }) => theme.colors.value_34};
  }
  & path {
    stroke: ${({ theme }) => theme.colors.value_01};
  }
`;
