import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ITableSettings } from "./interfaces";

export const SLICE_NAME = "table";

export type InitialStateType = {
  settings: ITableSettings;
};

type SettingsPayload = {
  settings: ITableSettings;
};

export type CaseReducers<State> = {
  setSettings: CaseReducer<State, PayloadAction<SettingsPayload>>;
};
