import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import { handleRetrieveManyInvoices } from "./workers";

export function* watcher() {
  yield takeLatest(
    ActionType.RETRIEVE_MANY_INVOICES,
    handleRetrieveManyInvoices
  );
}
