import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { AlertStatusMessage } from "@/shared/types";

type AlertsAdapterProps = {
  messages: {
    [key in AlertStatusMessage]?: string[];
  };
  clearMessages: () => void;
};

export function AlertsAdapter({ messages, clearMessages }: AlertsAdapterProps) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    Object.keys(messages).forEach((key) => {
      switch (key) {
        case AlertStatusMessage.SUCCESS:
        case AlertStatusMessage.MESSAGE: {
          messages[key]?.forEach((success) => {
            enqueueSnackbar(success, {
              variant: "success",
            });
          });
          break;
        }
        case AlertStatusMessage.ERROR: {
          messages[key]?.forEach((error) => {
            if (!error) return;
            enqueueSnackbar(error, {
              variant: "error",
            });
          });
          break;
        }
        case AlertStatusMessage.WARNING: {
          messages[key]?.forEach((warning) => {
            enqueueSnackbar(warning, {
              variant: "warning",
            });
          });
          break;
        }
        case AlertStatusMessage.INFO: {
          messages[key]?.forEach((info) => {
            enqueueSnackbar(info, {
              variant: "info",
            });
          });
          break;
        }
        default: {
          break;
        }
      }
    });

    Object.values(messages).forEach((message) => {
      if (message?.length) {
        clearMessages();
      }
    });
  }, [messages, clearMessages, enqueueSnackbar]);

  return null;
}
