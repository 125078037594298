import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import { AssignOrderPayload, GetOrderPayload } from '@/entities/client-orders/api/types/types';

const manageOrder = createAction<any>(ActionType.MANAGE_ORDER);
const assingOrder = createAction<AssignOrderPayload>(ActionType.ASSIGN_ORDER);
const retrieveOrder = createAction<GetOrderPayload>(ActionType.RETRIEVE_ORDER);
const retrieveManyOrders = createAction(ActionType.RETRIEVE_MANY_ORDERS);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      assingOrder,
      manageOrder,
      retrieveOrder,
      retrieveManyOrders,
    },
    dispatch
  );
};

export const sagaActions = {
  assingOrder,
  manageOrder,
  retrieveOrder,
  retrieveManyOrders,
};
