import { CssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ColorModeContext, useMode } from "./lib/logic";

export function AppThemeProvider({
  children,
}: React.PropsWithChildren): JSX.Element {
  const { theme, colorMode } = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </EmotionThemeProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  );
}
