import { IRouterPaths } from "../types/interfaces/IRouterPaths.interface";

export const ROUTER_PATHS: IRouterPaths = {
  // DEFAULT PATH IS ONLY LOGIN PAGE
  defaultPath: "/sign-in",

  // AUTH PAGES
  auth: {
    defaultPath: "/sign-in",
    signIn: {
      path: "/sign-in",
    },
    signUp: {
      path: "/sign-up",
    },
    forgotPassword: {
      path: "/forgot-password",
    },
  },

  // BACK OFFICE PAGES
  backOffice: {
    defaultPath: "/inventory",
    users: {
      path: "/users",
    },
    inventory: {
      path: "/inventory",
    },
    researchAreas: {
      path: "/research-areas",
    },
    clientOrders: {
      path: "/client-orders",
    },
    clientCompanies: {
      path: "/client-companies",
    },
    vendors: {
      path: "/vendors",
    },
    vendorOrders: {
      path: "/vendor-orders",
    },
    contactUs: {
      path: "/contact-us",
    },
    profile: {
      path: "/profile",
    },
    biomarkers: {
      path: "/biomarkers",
    },
  },

  // CLIENT AREA PAGES
  clientArea: {
    defaultPath: "/specimen-search",
    specimenSearch: {
      path: "/specimen-search",
      // todo
      loincList: {
        path: "/specimen-search/loinc-list",
      },
    },
    orders: {
      path: "/orders",
    },
    cart: {
      path: "/cart",
    },
    contactUs: {
      path: "/contact-us",
    },
    profile: {
      path: "/profile",
    },
  },
};
