import { IGetManyParams, RootState } from "@/shared/types";
// import { ISelectData } from "./types";

export const selectData = (state: RootState): any => state.select.data;

export const selectParams = (state: RootState): IGetManyParams =>
  state.select.params;

export const selectIsLoading = (state: RootState): boolean =>
  state.select.isLoading;
