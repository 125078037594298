import { IVendor } from "@/shared/types";
import { RE_REPLACE_NUMBER } from "@/shared/utils/regExp";

import { IVendorModified } from "../model/types/interfaces";

export const modifyVendorsData = (
  items: Array<IVendor>
): Array<IVendorModified> => {
  return items.map((element) => {
    const fullAddress = [
      element.address1,
      element.address2,
      element.city,
      element.state,
      element.zip,
    ]
      .filter(Boolean)
      .join(", ");

    return { ...element, fullAddress };
  });
};

export const modifyVendorData = (vendor: IVendor): IVendorModified => {
  const fullAddress = [
    vendor.address1,
    vendor.address2,
    vendor.city,
    vendor.state,
    vendor.zip,
  ]
    .filter(Boolean)
    .join(", ");

  return { ...vendor, fullAddress };
};

export const getCreateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    state: formData.state,
    zip: formData.zip,
    phoneNumber: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || null,
    website: formData.website,
    // logoUrl: "https://vendor-name.com/logo.png",
  };
  return a;
};

export const getUpdateDtoByFormData = (formData: any) => {
  const a = {
    name: formData.name,
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    state: formData.state,
    zip: formData.zip,
    phoneNumber: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || null,
    website: formData.website,
    // logoUrl: "https://vendor-name.com/logo.png",
  };
  return a;
};
