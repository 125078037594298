import { call } from "redux-saga/effects";
import { API_URL } from "@/shared/api/apiUrl";
import { HttpMethod } from "@/shared/types";
import { sendRequest } from "@/shared/api/requestSender";
import {
  IGetManyAreaItemsParams,
  IGetManyLoincBiomarkersParams,
} from "./types/interfaces";
import {
  GetManyAreaItemsResponse,
  GetAllResearchAreasResponse,
  GetManyLoincBiomarkersResponse,
} from "./types/types";
import { IGetManyBiomarkersParams } from '@/entities/biomarkers/model/types/interfaces';

const sendGetAllResearchAreasRequest = () =>
  sendRequest({
    path: API_URL.researchAreas.getAll,
    method: HttpMethod.GET,
  });

const sendGetAllBodySystemsRequest = () =>
  sendRequest({
    path: API_URL.panels.getAll,
    method: HttpMethod.GET,
  });

const sendGetManyAreaItemsRequest = (body: IGetManyAreaItemsParams) =>
  sendRequest({
    path: API_URL.researchAreas.items.getMany,
    method: HttpMethod.POST,
    data: body,
  });

const sendGetManyLoincBiomarkersRequest = (
  areaItemId: number,
  body: IGetManyLoincBiomarkersParams
) =>
  sendRequest({
    path: `${API_URL.researchAreas.biomarkers.getMany.start}${areaItemId}${API_URL.researchAreas.biomarkers.getMany.end}`,
    method: HttpMethod.POST,
    data: body,
  });

export const sendGetManyLoincBiomarkersByAreaRequest = (body: IGetManyBiomarkersParams) => {
  return sendRequest({
    path: `${API_URL.researchAreas.biomarkers.getMany.start}${body.areaItemId}/get_loinc_biomarkers`,
    method: HttpMethod.POST,
    data: body,
  });
};

export const sendGetManyLoincBiomarkersByBodySystem = (body: IGetManyBiomarkersParams) => {
  return sendRequest({
    path: `${API_URL.panels.getAll}/${body.panelId}/get_loinc_biomarkers`,
    method: HttpMethod.POST,
    data: body,
  });
};

export function* getAllResearchAreas() {
  try {
    const response: GetAllResearchAreasResponse = yield call(
      sendGetAllResearchAreasRequest
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("GetAllResearchAreas ERROR", error);
  }
}

export function* getAllBodySystems() {
  try {
    const response: GetAllResearchAreasResponse = yield call(
      sendGetAllBodySystemsRequest
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("GetAllResearchAreas ERROR", error);
  }
}

export function* getManyAreaItems(body: IGetManyAreaItemsParams) {
  try {
    const response: GetManyAreaItemsResponse = yield call(
      sendGetManyAreaItemsRequest,
      body
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("GetManyAreaItems ERROR", error);
  }
}

export function* getManyLoincBiomarkers(
  areaItemId: number,
  body: IGetManyLoincBiomarkersParams
) {
  try {
    const response: GetManyLoincBiomarkersResponse = yield call(
      sendGetManyLoincBiomarkersRequest,
      areaItemId,
      body
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("getManyLoincBiomarkers ERROR", error);
  }
}
