import React, { useEffect } from "react";
import { Backdrop } from "@mui/material";

import CustomLoading from "@/shared/components/customLoading/CustomLoading";

import { SidebarHeader } from "./components/sidebar-header/SidebarHeader";
import { SidebarContent } from "./components/sidebar-content/SidebarContent";
import { SidebarFooter } from "./components/sidebar-footer/SidebarFooter";
import { SidebarFooterChat } from "./components/sidebar-footer-chat/SidebarFooterChat";
import { styles, Wrapper } from "./styles";

type AsideAdapterProps = {
  type?: string
  isOpen: boolean;
  header: React.ReactNode;
  footer: React.ReactNode;
  content: React.ReactNode;
  isLoading: boolean;
  onCloseCallback: any;
};

export function SideBlockAdapter({
  type,
  isOpen,
  header,
  footer,
  content,
  isLoading,
  onCloseCallback,
}: AsideAdapterProps) {
  useEffect(() => {
    const onESC = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onCloseCallback();
      }
    };

    window.addEventListener("keydown", onESC);

    return () => {
      window.removeEventListener("keydown", onESC);
    };
  }, [onCloseCallback]);

  return (
    <>
      <Wrapper $isOpen={isOpen}>
        {isOpen && (
          <>
            <SidebarHeader onClose={onCloseCallback}>{header}</SidebarHeader>
            <SidebarContent>{content}</SidebarContent>
            {type === 'chat' ? (
              <SidebarFooterChat>{footer}</SidebarFooterChat>
            ) : (
              <SidebarFooter>{footer}</SidebarFooter>
            )}
            <CustomLoading
              isOpen={isLoading}
              zIndex={"snackbar"}
              backdropFilter={"blur(20px)"}
            />
          </>
        )}
      </Wrapper>
      <Backdrop open={isOpen} sx={styles.backdrop} onClick={onCloseCallback} />
    </>
  );
}
