import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { AlertStatusMessage } from "@/shared/types";

export const SLICE_NAME = "alerts";

export interface InitialStateType {
  info: string[];
  error: string[];
  success: string[];
  warning: string[];
  message: string[];
}

export type AlertPayload = {
  status: AlertStatusMessage;
  message: string;
};

export type CaseReducers<State> = {
  setAlert: CaseReducer<State, PayloadAction<AlertPayload>>;
  resetAlerts: CaseReducer<State>;
};
