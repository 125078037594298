import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import {
  IStorageFacilitiesData,
  IGetManyStorageFacilitiesParams,
} from "./interfaces";

export const SLICE_NAME = "storageFacilities";

export type InitialStateType = {
  data: IStorageFacilitiesData;
  params: IGetManyStorageFacilitiesParams;
};

type StorageFacilitiesDataPayload = {
  storageFacilities: IStorageFacilitiesData;
};

type GetManyStorageFacilitiesParamsPayload = {
  params: IGetManyStorageFacilitiesParams;
};

export type CaseReducers<State> = {
  setParams: CaseReducer<
    State,
    PayloadAction<GetManyStorageFacilitiesParamsPayload>
  >;
  setStorageFacilities: CaseReducer<
    State,
    PayloadAction<StorageFacilitiesDataPayload>
  >;
};
