import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

import { commonSlice } from "@/entities/common";
import { alertsSagaActions } from "@/entities/alerts";

import { asideSagaActions, asideSlice } from "@/widgets/side-block";
import { dialogSagaActions, dialogSlice } from "@/widgets/dialog";

import {
  IUser,
  IApiError,
  ToggleActionType,
  AlertStatusMessage,
} from "@/shared/types";

import {
  GetUserPayload,
  GetUserResponse,
  CreateUserPayload,
  DeleteUserPayload,
  UpdateUserPayload,
  GetManyUsersPayload,
  GetManyUsersResponse,
  UpdateUserAvatarPayload,
} from "./types/types";
import {
  sendGetUserRequest,
  sendCreateUserRequest,
  sendUpdateUserRequest,
  sendDeleteUserRequest,
  sendGetManyUsersRequest,
  sendDeleteUserAvatarRequest,
  sendUpdateUserAvatarRequest,
} from "./senders";
import {
  modifyUserData,
  modifyUsersData,
  getCreateDtoByFormData,
  getUpdateDtoByFormData,
} from "./helpers";
import { usersSlice } from "../model/slice";
import { selectParams } from "../model/selectors";
import { IUserModified, IGetManyUsersParams } from "../model/types/interfaces";
import { checkUserByToken } from '@/entities/auth';

export function* handleCreateUser(action: PayloadAction<CreateUserPayload>) {
  const { formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const createDto: Partial<IUser> = yield call(
    getCreateDtoByFormData,
    formData
  );

  try {
    yield call(sendCreateUserRequest, createDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("user_created_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyUsers);
  }
}

export function* handleUpdateUser(action: PayloadAction<UpdateUserPayload>) {
  const { userId, formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const updateDto: Partial<IUser> = yield call(
    getUpdateDtoByFormData,
    formData
  );

  try {
    yield call(sendUpdateUserRequest, userId, updateDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("user_updated_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyUsers);
  }
}

export function* handleUpdateUserAvatar(action: PayloadAction<UpdateUserAvatarPayload>) {
  const { contentType, size, originalName, blob } = action.payload;
  const { updateAlert } = alertsSagaActions;

  try {

    const response: { data: { data: string } } = yield call(sendUpdateUserAvatarRequest, { contentType, size, originalName });

    yield call(fetch, response.data.data, {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
        'Content-Length': size.toString(),
        'Content-Disposition': `inline; filename="${originalName}"`,
      },
      body: blob,
    });

    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("avatar_updated_successfully")}`,
      })
    );

    yield put(checkUserByToken());
  } catch (err) {
    yield call(handleError, err);
  }
}

export function* handleDeleteUserAvatar() {
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  try {
    yield call(sendDeleteUserAvatarRequest);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("avatar_deleted_successfully")}`,
      })
    );
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield put(checkUserByToken());
  } catch (err) {
    yield call(handleError, err);
  }
}

export function* handleDeleteUser(action: PayloadAction<DeleteUserPayload>) {
  const { userId } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  try {
    yield call(sendDeleteUserRequest, userId);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("user_deleted_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyUsers);
  }
}

export function* handleRetrieveUser(action: PayloadAction<GetUserPayload>) {
  const { userId } = action.payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;
  const { setLoadingDialog } = dialogSlice;

  try {
    const response: GetUserResponse = yield call(sendGetUserRequest, userId);
    const user: IUser = response.data.data;
    const modifiedUser: IUserModified = yield call(modifyUserData, user);
    yield put(setActiveEntity({ activeEntity: modifiedUser }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
    yield put(setLoadingDialog({ isLoading: false }));
  }
}

export function* handleRetrieveManyUsers(
  action?: PayloadAction<GetManyUsersPayload>
) {
  const { setUsers, setParams } = usersSlice;
  const { setLoading } = commonSlice;

  if (action?.payload?.params) {
    yield put(setParams({ params: action.payload.params }));
  }

  try {
    yield put(setLoading({ isLoading: true }));
    const bodyParams: IGetManyUsersParams = yield select(selectParams);
    const response: GetManyUsersResponse = yield call(
      sendGetManyUsersRequest,
      bodyParams
    );
    const { items, count } = response.data.data;
    const modifiedUsers: Array<IUserModified> = yield call(
      modifyUsersData,
      items
    );
    yield put(setUsers({ data: { items: modifiedUsers, count } }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

function* handleError(err: any) {
  const { updateAlert } = alertsSagaActions;
  const error = err as IApiError;

  if (error?.response?.data?.error?.message?.length) {
    for (const text of error.response.data.error.message) {
      yield put(
        updateAlert({ status: AlertStatusMessage.ERROR, message: text })
      );
    }
  }
}
