import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { IChatsData, IGetManyChatsParams } from "./interfaces";

export const SLICE_NAME = "chats";

export type InitialStateType = {
  data: IChatsData;
  params: IGetManyChatsParams;
};

type ChatsDataPayload = {
  data: IChatsData;
};

type GetManyChatsParamsPayload = {
  params: IGetManyChatsParams;
};

export type CaseReducers<State> = {
  setParams: CaseReducer<State, PayloadAction<GetManyChatsParamsPayload>>;
  setChats: CaseReducer<State, PayloadAction<ChatsDataPayload>>;
};
