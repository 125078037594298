import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

import {
  IChat,
  IApiError,
  ToggleActionType,
  AlertStatusMessage,
} from "@/shared/types";

import { commonSlice } from "@/entities/common";
import { alertsSagaActions } from "@/entities/alerts";

import { asideSagaActions, asideSlice } from "@/widgets/side-block";
import { dialogSagaActions, dialogSlice } from "@/widgets/dialog";

import { selectParams } from "../model/selectors";
import { chatsSlice } from "../model/slice";
import {
  IGetManyChatsParams,
} from "../model/types/interfaces";
import {
  sendUpdateChatRequest,
  sendDeleteChatRequest,
  sendGetChatRequest,
  sendChatMessageAuthorizedRequest,
  sendGetManyChatsRequest,
} from "./senders";
import {
  getUpdateDtoByFormData, sortMessagesByDate,
} from './helpers';
import {
  GetChatPayload,
  GetManyChatsResponse,
  UpdateChatPayload,
  DeleteChatPayload,
  GetManyChatsPayload, SendMessagePayload,
} from './types/types';
import { retrieveChat } from '@/entities/chats/api/actions';

export function* handleUpdateChat(
  action: PayloadAction<UpdateChatPayload>
) {
  const { chatId, formData } = action.payload;

  const { setLoadingAside } = asideSlice;

  try {
    yield call(sendUpdateChatRequest, { ...formData, chatId });
    yield put(setLoadingAside({ isLoading: false }));
    yield put(retrieveChat({ chatId: chatId }));
  } catch (err) {
    yield call(handleError, err);
  }
}

export function* handleDeleteChat(
  action: PayloadAction<DeleteChatPayload>
) {
  const { chatId } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  try {
    yield call(sendDeleteChatRequest, chatId);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("chat_deleted_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyChats);
  }
}

export function* handleRetrieveChat(action: PayloadAction<GetChatPayload>) {
  const { chatId } = action.payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;
  const { setLoadingDialog } = dialogSlice;

  try {
    const response: GetManyChatsResponse = yield call(
      sendGetChatRequest,
      { chatId: chatId.toString() }
    );

    const chat: any = response.data.data;
    const sortedChatMessages: any = sortMessagesByDate(chat.items);

    yield put(setActiveEntity({ activeEntity: {id: '', createdAt: '', updatedAt: '', messages: sortedChatMessages }  }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
    yield put(setLoadingDialog({ isLoading: false }));
  }
}

export function* handleSendMessageAuthorized(action: PayloadAction<SendMessagePayload>) {
  const { comment } = action.payload;
  const { setLoading } = commonSlice;
  const { updateAlert } = alertsSagaActions;

  try {
    yield put(setLoading({ isLoading: true }));
    yield call(sendChatMessageAuthorizedRequest, { comment, sourceType: 'general' });
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("message_has_been_sent_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

export function* handleRetrieveManyChats(
  action?: PayloadAction<GetManyChatsPayload>
) {
  const { setChats, setParams } = chatsSlice;
  const { setLoading } = commonSlice;

  if (action?.payload?.params) {
    yield put(setParams({ params: action.payload.params }));
  }

  try {
    yield put(setLoading({ isLoading: true }));
    const bodyParams: IGetManyChatsParams = yield select(selectParams);

    const response: GetManyChatsResponse = yield call(
      sendGetManyChatsRequest,
      bodyParams
    );
    const { items, count } = response.data.data;

    yield put(setChats({ data: { items, count } }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

function* handleError(err: any) {
  const { updateAlert } = alertsSagaActions;
  const error = err as IApiError;

  if (error?.response?.data?.error?.message?.length) {
    for (const text of error.response.data.error.message) {
      yield put(
        updateAlert({ status: AlertStatusMessage.ERROR, message: text })
      );
    }
  }
}
