import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IAsideSettings } from "./interfaces";

export const SLICE_NAME = "aside";

export type InitialStateType = {
  settings: IAsideSettings;
  isLoading: boolean;
};

type SettingsPayload = {
  settings: IAsideSettings;
};

type LoadingPayload = {
  isLoading: boolean;
};

export type CaseReducers<State> = {
  setAsideSettings: CaseReducer<State, PayloadAction<SettingsPayload>>;
  setLoadingAside: CaseReducer<State, PayloadAction<LoadingPayload>>;
};
