import { call, put, select } from "redux-saga/effects";
import { AlertStatusMessage, IApiError, IClientCompany, ToggleActionType } from '@/shared/types';
import { IGetManyClientCompaniesParams } from "../model/types/interfaces";
import { selectParams } from "../model/selectors";
import {
  getCreateDtoByShippingLocationFormData,
  getUpdateDtoByShippingLocationFormData,
  modifyClientCompaniesData,
} from './helpers';
import {
  getClientCompany,
  updateClientCompany,
  deleteClientCompany,
  createClientCompany,
  getManyClientCompanies,
  getManyShippingLocations,
  updateShippingLocation,
  createShippingLocation,
  deleteShippingLocation,
} from './senders';
import { commonSlice } from "@/entities/common";
import { clientCompaniesSlice } from "../model/slice";
import { PayloadAction } from '@reduxjs/toolkit';
import { asideSagaActions, asideSlice } from '@/widgets/side-block';
import {
  CreateClientCompanyPayload,
  DeleteClientCompanyPayload,
  GetClientCompanyResponse,
  GetCompanyPayload,
  UpdateClientCompanyPayload,
  UpdateShippingLocationPayload,
  CreateShippingLocationPayload, DeleteShippingLocationPayload,
} from '@/entities/client-companies/api/types/types';
import { alertsSagaActions } from '@/entities/alerts';
import { getUpdateDtoByFormData } from '@/entities/vendors/api/helpers';
import { t } from 'i18next';
import { dialogSagaActions } from '@/widgets/dialog';

export function* handleRetrieveManyShippingLocations() {
  const { setShippingLocations } = clientCompaniesSlice;
  const { setLoading } = commonSlice;
  const body: IGetManyClientCompaniesParams = yield select(selectParams);

  yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getManyShippingLocations, body);
  const modifiedClientCompanies: Array<IClientCompany> = yield call(
    modifyClientCompaniesData,
    items
  );

  yield put(setShippingLocations({ shippingLocations: { items: modifiedClientCompanies, count } }));
  yield put(setLoading({ isLoading: false }));
}

export function* handleRetrieveManyCompanies() {
  const { setClientCompanies } = clientCompaniesSlice;
  const { setLoading } = commonSlice;
  const body: IGetManyClientCompaniesParams = yield select(selectParams);

  yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getManyClientCompanies, body);
  const modifiedClientCompanies: Array<IClientCompany> = yield call(
    modifyClientCompaniesData,
    items
  );


  yield put(setClientCompanies({ clientCompanies: { items: modifiedClientCompanies, count } }));
  yield put(setLoading({ isLoading: false }));
}

export function* handleRetrieveCompany({ payload }: PayloadAction<GetCompanyPayload>) {
  const { companyId } = payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;

  const response: GetClientCompanyResponse = yield call(getClientCompany, companyId);

  yield put(setActiveEntity({ activeEntity: response.data }));
  yield put(setLoadingAside({ isLoading: false }));
}

export function* handleUpdateCompany(
  action: PayloadAction<UpdateClientCompanyPayload>
) {
  const { clientCompanyId, formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const updateDto: Partial<IClientCompany> = yield call(
    getUpdateDtoByFormData,
    formData
  );

  try {
    yield call(updateClientCompany, clientCompanyId, updateDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("company_updated_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyCompanies);
  }
}

export function* handleUpdateShippingLocation(
  action: PayloadAction<UpdateShippingLocationPayload>
) {
  const { shippingLocationId, formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const updateDto: Partial<IClientCompany> = yield call(
    getUpdateDtoByShippingLocationFormData,
    formData
  );

  try {
    yield call(updateShippingLocation, shippingLocationId, updateDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("shipping_location_updated_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyShippingLocations);
  }
}

export function* handleCreateCompany(
  action: PayloadAction<CreateClientCompanyPayload>
) {
  const { formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const updateDto: Partial<IClientCompany> = yield call(
    getUpdateDtoByFormData,
    formData
  );

  try {
    yield call(createClientCompany, updateDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("company_created_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyCompanies);
  }
}

export function* handleCreateShippingLocation(
  action: PayloadAction<CreateShippingLocationPayload>
) {
  const { formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  const updateDto: Partial<IClientCompany> = yield call(
    getCreateDtoByShippingLocationFormData,
    formData
  );

  try {
    yield call(createShippingLocation, updateDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("shipping_location_created_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyShippingLocations);
  }
}

export function* handleDeleteCompany(
  action: PayloadAction<DeleteClientCompanyPayload>
) {
  const { clientCompanyId } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  try {
    yield call(deleteClientCompany, clientCompanyId);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("company_deleted_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyCompanies);
  }
}

export function* handleDeleteShippingLocation(
  action: PayloadAction<DeleteShippingLocationPayload>
) {
  const { shippingLocationId } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  try {
    yield call(deleteShippingLocation, shippingLocationId);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("location_deleted_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyCompanies);
  }
}

function* handleError(err: any) {
  const { updateAlert } = alertsSagaActions;
  const error = err as IApiError;

  if (error?.response?.data?.error?.message?.length) {
    for (const text of error.response.data.error.message) {
      yield put(
        updateAlert({ status: AlertStatusMessage.ERROR, message: text })
      );
    }
  }
}
