import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarKey } from "notistack";
import { Inner, Wrapper, Message, IconClose, Container } from "../../styles";
import { IconContainerError } from "./styles";

interface ErrorProps {
  id: SnackbarKey;
  message: string;
}

export const ErrorAlert = forwardRef<HTMLDivElement, ErrorProps>(
  ({ id, message }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const onClose = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <Wrapper ref={ref}>
        <Container bgRgbColor="value_17" borderColor="value_35">
          <Inner>
            <IconContainerError backgroundColor="value_35"></IconContainerError>
            <Message color="value_35">{message}</Message>
          </Inner>
          <IconClose onClick={onClose} backgroundColor="value_35" />
        </Container>
      </Wrapper>
    );
  }
);
