import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleInfinityLoadMore,
  handleInfinitySelectOpen,
  handleInfinitySelectClose,
} from "./workers";

export function* selectWatcher() {
  yield takeLatest(ActionType.INFINITY_SELECT_OPEN, handleInfinitySelectOpen);
  yield takeLatest(
    ActionType.INFINITY_SELECT_LOAD_MORE,
    handleInfinityLoadMore
  );
  yield takeLatest(ActionType.INFINITY_SELECT_CLOSE, handleInfinitySelectClose);
}
