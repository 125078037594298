import { t } from "i18next";
import { call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { getCookie } from "@/shared/utils/cookie";
import {
  IUser,
  IApiError,
  StorageKeys,
  AlertStatusMessage,
  ICart,
  IClientCompany,
} from "@/shared/types";
import {
  setItemToLS,
  getItemFromLS,
  removeItemFromLS,
} from "@/shared/utils/localStorageHelpers";

import { asideSlice } from "@/widgets/side-block";

import { getCurrentUser } from "@/entities/common/api/helpers";
import { UpdateUserResponse } from "@/entities/users/api/types/types";
import { authActions, checkUserByToken } from "@/entities/auth";
import { alertsSagaActions } from "@/entities/alerts";
import { commonSlice, commonSagaActions } from "@/entities/common";

import {
  LoginPayload,
  LoginResponse,
  UpdateProfilePayload,
} from "./types/types";
import {
  sendLoginRequest,
  sendLogOutRequest,
  sendCheckMeRequest,
  sendUpdateMeRequest,
  sendUserConditions,
} from "./senders";
import { getUpdateDtoByFormData } from "./helpers";

export function* handleLogin(action: PayloadAction<LoginPayload>) {
  const { email, password } = action.payload;
  const { setAuthParams } = authActions;
  const { setLoading } = commonSlice;
  const { updateAlert } = alertsSagaActions;
  const { userLoggedIn } = commonSagaActions;

  try {
    yield put(setLoading({ isLoading: true }));

    const response: LoginResponse = yield call(sendLoginRequest, {
      email,
      password,
    });

    const { authorization } = response.headers;

    if (!authorization) {
      // ToDo handle case properly
      return;
    }

    yield call(
      setItemToLS,
      StorageKeys.AUTH_TOKEN,
      JSON.stringify(authorization)
    );

    yield call(conditions);

    const params = { isAuth: true, authToken: authorization };

    yield put(setAuthParams({ params }));
    yield put(userLoggedIn({ user: response.data.data }));
  } catch (err: any) {
    //TODO: handle error properly
    console.error("LOGIN ERROR", err);
    yield put(setLoading({ isLoading: false }));
    yield put(
      updateAlert({
        status: AlertStatusMessage.ERROR,
        message: err.response.data.error.message[0],
      })
    );
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

export function* handleLogOut() {
  const { setAuthParams } = authActions;
  const { userLoggedOut } = commonSagaActions;

  try {
    yield call(sendLogOutRequest);
  } catch (err) {
    //TODO: handle error properly
    console.error("LOGOUT ERROR", err);
  }

  yield call(removeItemFromLS, StorageKeys.AUTH_TOKEN);
  yield put(setAuthParams({ params: { isAuth: false, authToken: "" } }));
  yield put(userLoggedOut());
}

export function* handleCheckUser() {
  const { setAuthParams } = authActions;
  const { setLoading } = commonSlice;
  const { userLoggedIn } = commonSagaActions;

  //TODO: check logic
  const authHeaderCookie = getCookie("auth_header");
  if (authHeaderCookie) {
    yield call(setItemToLS, StorageKeys.AUTH_TOKEN, authHeaderCookie);
  }

  const authToken: string = yield call(getItemFromLS, StorageKeys.AUTH_TOKEN);

  if (!authToken && !authHeaderCookie) {
    return;
  }

  try {
    yield put(setLoading({ isLoading: true }));

    const response: LoginResponse = yield call(sendCheckMeRequest);
    const user: IUser = response.data.data;

    if (!user) {
      throw new Error("User not found");
    }

    yield put(
      setAuthParams({
        params: {
          isAuth: true,
          authToken: authToken,
        },
      })
    );
    yield call(conditions);
    yield put(userLoggedIn({ user: response.data.data }));
    yield put(setLoading({ isLoading: false }));
  } catch (err) {
    //TODO: handle error properly
    console.error("LOGIN ERROR", err);
    yield put(setLoading({ isLoading: false }));
  }
}

export function* initializeApp() {
  try {
    yield put(checkUserByToken());
  } catch (err) {
    //TODO: handle error properly
    console.error("INITIALIZE APP ERROR", err);
  }
}

export function* handleUpdateProfileButtonClicked(
  action: PayloadAction<UpdateProfilePayload>
) {
  const { formData } = action.payload;

  const { setCurrentUser } = commonSlice;
  const { setLoadingAside } = asideSlice;
  const { updateAlert } = alertsSagaActions;

  const updateDto: Partial<IUser> = yield call(
    getUpdateDtoByFormData,
    formData
  );

  yield put(setLoadingAside({ isLoading: true }));

  let updatedUser: IUser;
  try {
    const response: UpdateUserResponse = yield call(
      sendUpdateMeRequest,
      updateDto
    );
    updatedUser = response.data.data;
  } catch (err) {
    // ToDo handle error properly
    const error = err as IApiError;

    if (error?.response?.data?.error?.message?.length) {
      for (const text of error.response.data.error.message) {
        yield put(
          updateAlert({ status: AlertStatusMessage.ERROR, message: text })
        );
      }
    }
    yield put(setLoadingAside({ isLoading: false }));
    return;
  }

  const currentUser = getCurrentUser(updatedUser);

  yield put(setCurrentUser({ currentUser }));
  yield put(
    updateAlert({
      status: AlertStatusMessage.SUCCESS,
      message: t("changes_have_been_saved_successfully"),
    })
  );
  yield put(setLoadingAside({ isLoading: false }));
}

export type UserConditionsResponse = {
  data: {
    data: {
      cart: ICart;
      clientCompany: IClientCompany;
    };
  };
};

function* conditions() {
  const { setUserConditions } = commonSlice;
  const response: UserConditionsResponse = yield call(sendUserConditions);
  yield put(setUserConditions({ conditions: response.data.data }));
}
