import { API_URL } from "@/shared/api/apiUrl";
import { sendRequest } from "@/shared/api/requestSender";
import { HttpMethod } from "@/shared/types";

// Temporary here
export const sendCreateCartRequest = () =>
  sendRequest({
    path: API_URL.clientOrders.createCart,
    method: HttpMethod.POST,
  });

// Temporary here
export const sendAddItemToCartRequest = (
  orderId: string,
  body: {
    sampleId: number;
    quantity?: number;
  }
) =>
  sendRequest({
    path: `${API_URL.clientOrders.addItemToCart.start}${orderId}${API_URL.clientOrders.addItemToCart.end}`,
    method: HttpMethod.POST,
    data: body,
  });

// Temporary here
export const sendRemoveItemFromCartRequest = (
  orderId: string,
  body: { sampleId: number; quantity?: number }
) =>
  sendRequest({
    path: `${API_URL.clientOrders.removeItemFromCart.start}${orderId}${API_URL.clientOrders.removeItemFromCart.end}`,
    method: HttpMethod.POST,
    data: body,
  });

// Temporary here
export const sendPlaceOrderRequest = (
  orderId: string,
  body: { sampleId: number; quantity?: number }
) =>
  sendRequest({
    path: `${API_URL.clientOrders.placeOrder.start}${orderId}${API_URL.clientOrders.placeOrder.end}`,
    method: HttpMethod.POST,
    data: body,
  });
