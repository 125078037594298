import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import colorOpacity from "../../utils/colorOpacity";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${({ theme, $z_index }) =>
    typeof $z_index === "string" ? theme.zIndex[$z_index] : $z_index};
  backdrop-filter: ${({ $backdrop_filter }) => $backdrop_filter || "3px"};
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor] || "none"};
`;

Wrapper.Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default makeStyles((theme) => ({
  root: {
    color: theme.colors.value_01,
  },
  backdrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: colorOpacity(theme.colors.value_11, 0.5),
    color: theme.colors.value_04,
    zIndex: theme.zIndex.drawer,
  },
}));
