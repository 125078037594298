import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";

import { ActionType } from "./types/actionType";
import {
  GetSamplePayload,
  CreateSamplePayload,
  DeleteSamplePayload,
  UpdateSamplePayload,
  GetManySamplesPayload,
  CustomRequestPayload,
  PlaceCustomRequestPayload,
} from './types/types';

const createSample = createAction<CreateSamplePayload>(
  ActionType.CREATE_SAMPLE
);

const updateSample = createAction<UpdateSamplePayload>(
  ActionType.UPDATE_SAMPLE
);

const deleteSample = createAction<DeleteSamplePayload>(
  ActionType.DELETE_SAMPLE
);

const retrieveSample = createAction<GetSamplePayload>(ActionType.GET_SAMPLE);

const cusomtRequest = createAction<CustomRequestPayload>(ActionType.CUSTOM_REQUEST);

const placeCustomRequest = createAction<PlaceCustomRequestPayload>(ActionType.PLACE_CUSTOM_REQUEST);

const retrieveManySamples = createAction<GetManySamplesPayload | undefined>(
  ActionType.GET_MANY_SAMPLES
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      createSample,
      updateSample,
      deleteSample,
      cusomtRequest,
      retrieveSample,
      placeCustomRequest,
      retrieveManySamples,
    },
    dispatch
  );
};

export const sagaActions = {
  createSample,
  updateSample,
  deleteSample,
  cusomtRequest,
  retrieveSample,
  placeCustomRequest,
  retrieveManySamples,
};
