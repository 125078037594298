import styled from "@emotion/styled";
import { IconContainer } from "../../styles";

interface IconProps {
  backgroundColor?: string;
}

// export const IconError = styled.div<IconProps>`
//   background-image: ${({ theme }) => `url(${theme.images.warning})`};
//   background-size: auto;
//   background-repeat: no-repeat;
//   background-position: center;
// `;

export const IconContainerError = styled(IconContainer)<IconProps>`
  top: ${({ theme }) => theme.coefficient * 24}px;
  right: ${({ theme }) => theme.coefficient * 40}px;
  height: ${({ theme }) => theme.coefficient * 24}px;
  width: ${({ theme }) => theme.coefficient * 24}px;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor || ""]};
  background-image: ${({ theme }) => `url(${theme.images.warning_alert})`};
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25%;
`;

/* & svg path:nth-child(1) {
    fill: ${({ theme }) => theme.colors.value_35};
    stroke: ${({ theme }) => theme.colors.value_35};
  }
  & svg path:nth-child(2),
  & svg path:nth-child(3) {
    stroke: ${({ theme }) => theme.colors.value_01};
  } */
