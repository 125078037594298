import { put, call, select } from 'redux-saga/effects';
import { PayloadAction } from "@reduxjs/toolkit";

import { AlertStatusMessage, DialogMode, IApiError, IResearchArea, ToggleActionType } from '@/shared/types';

import { ClickedAreaItemPayload, GetManyAreaItemsPayload } from "./types/types";
import { researchAreasActions } from "../model/slice";
import {
  getManyAreaItems,
  getAllBodySystems,
  getAllResearchAreas,
  getManyLoincBiomarkers,
  sendGetManyLoincBiomarkersByAreaRequest, sendGetManyLoincBiomarkersByBodySystem,
} from './senders';
import { commonSagaActions, commonSlice } from "@/entities/common";
import { dialogSagaActions, dialogSlice } from "@/widgets/dialog";
import { GetManyBiomarkersPayload, GetManyBiomarkersResponse } from '@/entities/biomarkers/api/types/types';
import { selectParams } from '@/entities/researchAreas';
import { IGetManyBiomarkersParams } from '@/entities/biomarkers/model/types/interfaces';
import { alertsSagaActions } from '@/entities/alerts';
import { modifyBodySystemsData } from '@/entities/researchAreas/api/helpers';

export function* handleGetAllResearchAreas() {
  const { setData } = researchAreasActions;
  const { setLoading } = commonSlice;

  yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getAllResearchAreas);

  yield put(setData({ data: { items, count } }));
  yield put(setLoading({ isLoading: false }));
}

export function* handleGetAllBodySystems() {
  const { setBodySystems } = researchAreasActions;
  const { setLoading } = commonSlice;

  yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getAllBodySystems);
  const modifiedItems: Array<IResearchArea> = yield call(modifyBodySystemsData, items);

  yield put(setBodySystems({ bodySystems: { items: modifiedItems, count } }));
  yield put(setLoading({ isLoading: false }));
}

export function* handleGetManyAreaItems(
  action: PayloadAction<GetManyAreaItemsPayload>
) {
  const { bodyParams, activeAreaName } = action.payload;
  const { setAreaItems, setActiveAreaName } = researchAreasActions;
  // const { setLoading } = commonActions;
  // yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getManyAreaItems, bodyParams);

  yield put(setAreaItems({ areaItems: { items, count } }));
  yield put(setActiveAreaName({ activeAreaName }));
  // yield put(setLoading({ isLoading: false }));
}

export function* handleClearAreaItems() {
  const { setAreaItems } = researchAreasActions;
  yield put(setAreaItems({ areaItems: { items: [], count: 0 } }));
}

export function* handleAreaItemClicked(
  action: PayloadAction<ClickedAreaItemPayload>
) {
  const { areaItem } = action.payload;
  const { setLoadingDialog } = dialogSlice;
  const { setLoincBiomarkers } = researchAreasActions;
  const { toggleDialog } = dialogSagaActions;

  yield put(setLoadingDialog({ isLoading: true }));
  yield put(
    toggleDialog({
      status: ToggleActionType.OPEN,
      mode: DialogMode.INFO,
    })
  );

  const bodyParams = {
    skip: 0,
    limit: 100,
    sortBy: {},
    search: {},
    excludeIds: [],
  };

  const { items, count } = yield call(
    getManyLoincBiomarkers,
    areaItem.id,
    bodyParams
  );

  yield put(setLoincBiomarkers({ loincBiomarkers: { items, count } }));
  yield put(setLoadingDialog({ isLoading: false }));
}

export function* handleRetrieveManyBiomarkers(
  action?: PayloadAction<GetManyBiomarkersPayload>
) {
  const { setLoincBiomarkers, setParams } = researchAreasActions;
  const { setLoading } = commonSlice;

  if (action?.payload?.params) {
    yield put(setParams({ params: action.payload.params }));
  }

  try {
    yield put(setLoading({ isLoading: true }));
    const bodyParams: IGetManyBiomarkersParams = yield select(selectParams);

    const response: GetManyBiomarkersResponse = yield call(
      sendGetManyLoincBiomarkersByAreaRequest,
      bodyParams
    );
    const { items, count } = response.data.data;

    yield put(setLoincBiomarkers({ loincBiomarkers: { items, count } }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

export function* handleRetrieveManyBiomarkersByBodySystem(
  action?: PayloadAction<GetManyBiomarkersPayload>
) {
  const { setLoincBiomarkers, setParams } = researchAreasActions;
  const { setLoading } = commonSlice;

  if (action?.payload?.params) {
    yield put(setParams({ params: action.payload.params }));
  }

  try {
    yield put(setLoading({ isLoading: true }));
    const bodyParams: IGetManyBiomarkersParams = yield select(selectParams);

    const response: GetManyBiomarkersResponse = yield call(
      sendGetManyLoincBiomarkersByBodySystem,
      bodyParams
    );
    const { items, count } = response.data.data;

    yield put(setLoincBiomarkers({ loincBiomarkers: { items, count } }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

function* handleError(err: any) {
  const { updateAlert } = alertsSagaActions;
  const error = err as IApiError;

  if (error?.response?.data?.error?.message?.length) {
    for (const text of error.response.data.error.message) {
      yield put(
        updateAlert({ status: AlertStatusMessage.ERROR, message: text })
      );
    }
  }
}
