import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handlePlaceOrder,
  handleUserLoggedIn,
  handleUserLoggedOut,
  handleUpdateActiveTab,
  handleAddSampleToCart,
  handleRemoveProductFromCart,
} from "./workers";

export function* watcher() {
  yield takeLatest(ActionType.USER_LOGGED_IN, handleUserLoggedIn);
  yield takeLatest(ActionType.USER_LOGGED_OUT, handleUserLoggedOut);
  yield takeLatest(ActionType.NAVIGATION_TAB_CLICKED, handleUpdateActiveTab);
  yield takeLatest(
    ActionType.ADD_SAMPLE_TO_CART_CLICKED,
    handleAddSampleToCart
  );
  yield takeLatest(
    ActionType.REMOVE_PRODUCT_FROM_CART,
    handleRemoveProductFromCart
  );
  yield takeLatest(
    ActionType.PLACE_ORDER,
    handlePlaceOrder,
  );
}
