import styled from "styled-components";

const HEIGHT = 72;
const PADDING = 40;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const UpContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.coefficient * HEIGHT}px;
  padding: ${({ theme }) =>
    `0 ${theme.coefficient * PADDING}px 0 ${theme.coefficient * PADDING}px`};
  background-color: ${({ theme }) => theme.colors.value_17};
`;

export const Content = styled.div`
  display: flex;
  height: calc(100% - ${HEIGHT}px);
  padding: ${({ theme }) =>
    `0 ${theme.coefficient * PADDING}px ${theme.coefficient * PADDING}px ${
      theme.coefficient * PADDING
    }px`};
  background-color: ${({ theme }) => theme.colors.value_01};
`;
