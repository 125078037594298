export enum EntityType {
  CART = "cart",
  USER = "user",
  CHAT = "chat",
  ORDER = "order",
  SAMPLE = "sample",
  VENDOR = "vendor",
  INVOICE = "invoice",
  RESEARCH_AREA = "research_area",
  LOINC_BIOMARKER = "loinc_biomarker",
  STORAGE_FACILITY = "storage_facility",
  COMPANY = "company",
}
