import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IDialogSettings } from "./interfaces";

export const SLICE_NAME = "dialog";

export type InitialStateType = {
  settings: IDialogSettings;
  isLoading: boolean;
};

type SettingsPayload = {
  settings: IDialogSettings;
};

type LoadingPayload = {
  isLoading: boolean;
};

export type CaseReducers<State> = {
  setDialogSettings: CaseReducer<State, PayloadAction<SettingsPayload>>;
  setLoadingDialog: CaseReducer<State, PayloadAction<LoadingPayload>>;
};
