import { RootState } from "@/shared/types";
import {
  IStorageFacilitiesData,
  IGetManyStorageFacilitiesParams,
} from "./types/interfaces";

export const selectStorageFacilities = (
  state: RootState
): IStorageFacilitiesData => state.storageFacilities.data;

export const selectParams = (
  state: RootState
): IGetManyStorageFacilitiesParams => state.storageFacilities.params;
