// import { useContext } from "react";
import { useSelector } from "react-redux";
import { Menu } from "./components/menu/Menu";
// import { IconButton, useTheme } from "@mui/material";
// import { useTheme } from "@mui/material";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

import {
  selectActiveTab,
  selectCurrentUser,
  selectNavigationTabs,
  selectUserCart, useCommonActions,
} from '@/entities/common';

import NavTabs from "./components/tabs/NavTabs";
import {
  Logo,
  Wrapper,
  TabsContainer,
  LogoContainer,
  ProfileContainer,
} from "./styles";

// import {
// getMatrixByMode,
// ColorModeContext,
// } from "@/app/providers/theme/lib/logic";

export function NavBar() {
  // const theme = useTheme();
  // const colors = getMatrixByMode(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);
  const { navigationTabClicked } = useCommonActions();

  const redirectToDefaultPage = () => {
    navigationTabClicked({ value: 0 });
  }

  const { cart, tabs, activeTab, currentUser } = {
    cart: useSelector(selectUserCart),
    tabs: useSelector(selectNavigationTabs),
    activeTab: useSelector(selectActiveTab),
    currentUser: useSelector(selectCurrentUser),
  };

  return (
    <Wrapper>
      <LogoContainer onClick={redirectToDefaultPage}>
        <Logo />
      </LogoContainer>
      {/* <IconButton onClick={colorMode.toggleColorMode}>
        {theme.palette.mode === "dark" ? (
          <DarkModeOutlinedIcon />
        ) : (
          <LightModeOutlinedIcon />
        )}
      </IconButton> */}
      <TabsContainer>
        <NavTabs
          cart={cart}
          tabs={tabs}
          activeTab={activeTab}
          cssVariant={"navBarRegular"}
        />
      </TabsContainer>
      <ProfileContainer>
        <Menu tabs={tabs} currentUser={currentUser} />
      </ProfileContainer>
    </Wrapper>
  );
}
