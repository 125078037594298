import { createSlice } from "@reduxjs/toolkit";
import { CaseReducers, InitialStateType, SLICE_NAME } from "./types/types";
import { SideBlockMode } from "@/shared/types";

const INITIAL_STATE: InitialStateType = {
  settings: {
    mode: SideBlockMode.CLOSED,
    isOpen: false,
    t_title: "",
    t_submitBtn: "",
  },
  isLoading: false,
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setAsideSettings: (draft, { payload }) => {
      draft.settings = payload.settings;
    },
    setLoadingAside: (draft, { payload }) => {
      draft.isLoading = payload.isLoading;
    },
  },
});

export const asideSlice = slice.actions;
export const asideReducer = slice.reducer;
