import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME, CaseReducers, InitialStateType } from "./types/types";
import { AlertStatusMessage } from "@/shared/types";

const INITIAL_STATE: InitialStateType = {
  info: [],
  error: [],
  success: [],
  warning: [],
  message: [],
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setAlert: (draft, { payload }) => {
      switch (payload.status) {
        case AlertStatusMessage.INFO:
          draft.info.push(payload.message);
          break;
        case AlertStatusMessage.ERROR:
          draft.error.push(payload.message);
          break;
        case AlertStatusMessage.SUCCESS:
          draft.success.push(payload.message);
          break;
        case AlertStatusMessage.WARNING:
          draft.warning.push(payload.message);
          break;
        case AlertStatusMessage.MESSAGE:
          draft.message.push(payload.message);
          break;
      }
    },
    resetAlerts: (draft) => {
      draft.info = [];
      draft.error = [];
      draft.success = [];
      draft.warning = [];
      draft.message = [];
    },
  },
});

export const alertsSlice = slice.actions;
export const alertsReducer = slice.reducer;
