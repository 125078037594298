import React from "react";
import { FooterWrapper, BlockFooter } from "./styles";

export function SidebarFooterChat({
  children,
}: React.PropsWithChildren): JSX.Element {
  return (
    <FooterWrapper>
      <BlockFooter>{children}</BlockFooter>
    </FooterWrapper>);
}

export default SidebarFooterChat;
