import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { IStorageFacility, ToggleActionType } from "@/shared/types";

import { IGetManyStorageFacilitiesParams } from "../model/types/interfaces";
import { selectParams } from "../model/selectors";
import {
  modifyStorageFacilitiesData,
  modifyStorageFacilityData,
  getCreateDtoByFormData,
  getUpdateDtoByFormData,
} from "./helpers";
import {
  ClickedRowItemPayload,
  CreateStorageFacilityPayload,
  DeleteStorageFacilityPayload,
  UpdateStorageFacilityPayload,
} from "./types/types";
import {
  getStorageFacility,
  updateStorageFacility,
  createStorageFacility,
  deleteStorageFacility,
  getManyStorageFacilities,
} from "./senders";
import { commonSlice } from "@/entities/common";
import { storageFacilitiesSlice } from "../model/slice";

export function* handleRetrieveManyStorageFacilities() {
  const { setStorageFacilities } = storageFacilitiesSlice;
  const { setLoading } = commonSlice;
  const body: IGetManyStorageFacilitiesParams = yield select(selectParams);

  yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getManyStorageFacilities, body);
  const modifiedStorageFacilities: Array<IStorageFacility> = yield call(
    modifyStorageFacilitiesData,
    items
  );

  yield put(
    setStorageFacilities({
      storageFacilities: { items: modifiedStorageFacilities, count },
    })
  );
  yield put(setLoading({ isLoading: false }));
}

// export function* handleCreateHeaderButtonClicked() {
//   const { setActiveStorageFacility } = storageFacilitiesActions;

//   yield put(setActiveStorageFacility({ activeStorageFacility: null }));
//   yield put(
//     toggleSideBlock({
//       status: ToggleActionType.OPEN,
//       type: SideBlockType.CREATE,
//     })
//   );
// }

// export function* handleTableRowClicked(
//   action: PayloadAction<ClickedRowItemPayload>
// ) {
//   const { clickedRowItem } = action.payload;
//   const activeStorageFacility: IStorageFacility | null = yield select(
//     selectActiveStorageFacility
//   );
//   const { setActiveStorageFacility } = storageFacilitiesActions;
//   const { setLoadingSide } = commonActions;
//   yield put(setLoadingSide({ isLoadingSide: true }));
//   yield put(
//     toggleSideBlock({
//       status: ToggleActionType.OPEN,
//       type: SideBlockType.EDIT,
//     })
//   );

//   if (activeStorageFacility?.id === clickedRowItem.id) {
//     yield put(setLoadingSide({ isLoadingSide: false }));
//     return;
//   }

//   const retrievedInvoice: IStorageFacility | null = yield call(
//     getStorageFacility,
//     clickedRowItem.id
//   );

//   if (!retrievedInvoice) {
//     yield put(setActiveStorageFacility({ activeStorageFacility: null }));
//     yield put(setLoadingSide({ isLoadingSide: false }));
//     return;
//   }

//   const modifiedStorageFacility: IStorageFacility = yield call(
//     modifyStorageFacilityData,
//     retrievedInvoice
//   );
//   yield put(
//     setActiveStorageFacility({ activeStorageFacility: modifiedStorageFacility })
//   );
//   yield put(setLoadingSide({ isLoadingSide: false }));
// }

// export function* handleEditRowButtonClicked(
//   action: PayloadAction<ClickedRowItemPayload>
// ) {
//   yield call(handleTableRowClicked, action);
// }

// export function* handleDeleteRowButtonClicked(
//   action: PayloadAction<ClickedRowItemPayload>
// ) {
//   const { clickedRowItem } = action.payload;
//   const activeStorageFacility: IStorageFacility | null = yield select(
//     selectActiveStorageFacility
//   );
//   const { setActiveStorageFacility } = storageFacilitiesActions;
//   const { setLoadingModal } = commonActions;
//   yield put(setLoadingModal({ isLoadingModal: true }));
//   yield put(
//     toggleModalWindow({
//       status: ToggleActionType.OPEN,
//       type: ModalWindowType.DELETE,
//     })
//   );

//   if (activeStorageFacility?.id === clickedRowItem.id) {
//     yield put(setLoadingModal({ isLoadingModal: false }));
//     return;
//   }

//   const retrievedStorageFacility: IStorageFacility | null = yield call(
//     getStorageFacility,
//     clickedRowItem.id
//   );

//   if (!retrievedStorageFacility) {
//     yield put(setActiveStorageFacility({ activeStorageFacility: null }));
//     yield put(setLoadingModal({ isLoadingModal: false }));
//     return;
//   }

//   const modifiedStorageFacility: IStorageFacility = yield call(
//     modifyStorageFacilityData,
//     retrievedStorageFacility
//   );
//   yield put(
//     setActiveStorageFacility({ activeStorageFacility: modifiedStorageFacility })
//   );
//   yield put(setLoadingModal({ isLoadingModal: false }));
// }

// export function* handleDeleteModalButtonClicked(
//   action: PayloadAction<DeleteStorageFacilityPayload>
// ) {
//   const { invoiceId } = action.payload;
//   yield call(deleteStorageFacility, invoiceId);
//   yield call(handleRetrieveManyStorageFacilities);
//   yield put(toggleModalWindow({ status: ToggleActionType.CLOSE }));
// }

// export function* handleCreateSideBlockButtonClicked(
//   action: PayloadAction<CreateStorageFacilityPayload>
// ) {
//   const { formData } = action.payload;

//   const { setLoadingSide } = commonActions;
//   yield put(setLoadingSide({ isLoadingSide: true }));
//   const createDto: Partial<IStorageFacility> = yield call(
//     getCreateDtoByFormData,
//     formData
//   );
//   yield call(createStorageFacility, createDto);
//   yield call(handleRetrieveManyStorageFacilities);
//   yield put(setLoadingSide({ isLoadingSide: false }));
//   yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
// }

// export function* handleUpdateSideBlockButtonClicked(
//   action: PayloadAction<UpdateStorageFacilityPayload>
// ) {
//   const { invoiceId, formData } = action.payload;
//   const { setLoadingSide } = commonActions;
//   const updateDto: Partial<IStorageFacility> = yield call(
//     getUpdateDtoByFormData,
//     formData
//   );
//   yield put(setLoadingSide({ isLoadingSide: true }));
//   yield call(updateStorageFacility, invoiceId, updateDto);
//   yield call(handleRetrieveManyStorageFacilities);
//   yield put(setLoadingSide({ isLoadingSide: false }));
//   yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
// }
