import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IUsersData, IGetManyUsersParams } from "./interfaces";

export const SLICE_NAME = "users";

export type InitialStateType = {
  data: IUsersData;
  params: IGetManyUsersParams;
};

type UsersDataPayload = {
  data: IUsersData;
};

type GetManyUsersParamsPayload = {
  params: IGetManyUsersParams;
};

export type CaseReducers<State> = {
  setUsers: CaseReducer<State, PayloadAction<UsersDataPayload>>;
  setParams: CaseReducer<State, PayloadAction<GetManyUsersParamsPayload>>;
};
