import { Route, Routes } from "react-router-dom";
import { ROUTER_PATHS } from "@/shared/config/router-paths";
import {
  UsersPage,
  VendorsPage,
  ProfilePageBO as ProfilePage,
  ContactUsPageBO as ContactUsPage,
  InventoryPage,
  BiomarkersPage,
  ClientOrdersPage,
  VendorOrdersPage,
  ResearchAreasPage,
  ClientCompaniesPage,
} from "@/pages";

export function BackOfficeRouter() {
  const {
    users,
    vendors,
    profile,
    contactUs,
    inventory,
    biomarkers,
    clientOrders,
    vendorOrders,
    researchAreas,
    clientCompanies,
  } = ROUTER_PATHS.backOffice;

  return (
    <Routes>
      <Route path={users.path} element={<UsersPage />} />
      <Route path={vendors.path} element={<VendorsPage />} />
      <Route path={profile.path} element={<ProfilePage />} />
      <Route path={contactUs.path} element={<ContactUsPage />} />
      <Route path={inventory.path} element={<InventoryPage />} />
      <Route path={clientOrders.path} element={<ClientOrdersPage />} />
      <Route path={vendorOrders.path} element={<VendorOrdersPage />} />
      <Route path={researchAreas.path} element={<ResearchAreasPage />} />
      <Route path={clientCompanies.path} element={<ClientCompaniesPage />} />
      <Route path={biomarkers.path} element={<BiomarkersPage />} />
    </Routes>
  );
}
