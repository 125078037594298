import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import { handleRetrieveManyOrders, handleRetrieveOrder, handleManageOrder, handleAssignOrder } from "./workers";

export function* watcher() {
  yield takeLatest(
    ActionType.RETRIEVE_MANY_ORDERS,
    handleRetrieveManyOrders
  );
  yield takeLatest(
    ActionType.RETRIEVE_ORDER,
    handleRetrieveOrder
  );
  yield takeLatest(
    ActionType.MANAGE_ORDER,
    handleManageOrder
  );
  yield takeLatest(
    ActionType.ASSIGN_ORDER,
    handleAssignOrder
  );
}
