import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import { handleRetrieveManyStorageFacilities } from "./workers";

export function* watcher() {
  yield takeLatest(
    ActionType.RETRIEVE_MANY_STORAGE_FACILITIES,
    handleRetrieveManyStorageFacilities
  );
  // yield takeLatest(
  //   StorageFacilitiesActionType.TABLE_ROW_CLICKED,
  //   handleTableRowClicked
  // );
  // yield takeLatest(
  //   StorageFacilitiesActionType.EDIT_ROW_BUTTON_CLICKED,
  //   handleEditRowButtonClicked
  // );
  // yield takeLatest(
  //   StorageFacilitiesActionType.DELETE_ROW_BUTTON_CLICKED,
  //   handleDeleteRowButtonClicked
  // );
  // yield takeLatest(
  //   StorageFacilitiesActionType.DELETE_MODAL_BUTTON_CLICKED,
  //   handleDeleteModalButtonClicked
  // );
  // yield takeLatest(
  //   StorageFacilitiesActionType.CREATE_HEADER_BUTTON_CLICKED,
  //   handleCreateHeaderButtonClicked
  // );
  // yield takeLatest(
  //   StorageFacilitiesActionType.CREATE_SIDE_BLOCK_BUTTON_CLICKED,
  //   handleCreateSideBlockButtonClicked
  // );
  // yield takeLatest(
  //   StorageFacilitiesActionType.UPDATE_SIDE_BLOCK_BUTTON_CLICKED,
  //   handleUpdateSideBlockButtonClicked
  // );
}
