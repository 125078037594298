import { call, takeEvery, takeLatest } from "redux-saga/effects";
import { AuthActionType } from "./types/actionType";
import {
  handleLogin,
  handleLogOut,
  initializeApp,
  handleCheckUser,
  handleUpdateProfileButtonClicked,
} from "./workers";

export function* authWatcher() {
  yield takeLatest(AuthActionType.LOGIN_BUTTON_CLICKED, handleLogin);
  yield takeLatest(AuthActionType.LOG_OUT_BUTTON_CLICKED, handleLogOut);
  yield takeLatest(AuthActionType.CHECK_USER_BY_TOKEN, handleCheckUser);
  yield takeEvery(
    AuthActionType.UPDATE_PROFILE_BUTTON_CLICKED,
    handleUpdateProfileButtonClicked
  );
  yield call(initializeApp);
}
