import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarKey } from "notistack";
import { Inner, Wrapper, Message, IconClose, Container } from "../../styles";
import { IconSuccess, IconContainerSuccess } from "./styles";

interface SuccessProps {
  id: SnackbarKey;
  message: string;
}

export const SuccessAlert = forwardRef<HTMLDivElement, SuccessProps>(
  (props, ref) => {
    const { id, message } = props;
    const { closeSnackbar } = useSnackbar();

    const onClose = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <Wrapper ref={ref}>
        <Container bgRgbColor="value_30" borderColor="value_34">
          <Inner>
            <IconContainerSuccess>
              <IconSuccess />
            </IconContainerSuccess>
            <Message color="value_34">{message}</Message>
          </Inner>
          <IconClose onClick={onClose} backgroundColor="value_34" />
        </Container>
      </Wrapper>
    );
  }
);
