import { RE_REPLACE_NUMBER } from '@/shared/utils/regExp';

export const getUpdateDtoByFormData = (formData: any) => {
  const a = {
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    phoneNumber: formData.phoneNumber?.replace(RE_REPLACE_NUMBER, "") || null,
    dateOfBirth: formData.dateOfBirth || null,
  };
  return a;
};
