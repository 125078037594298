import styled, { DefaultTheme } from "styled-components";
import colorOpacity from "@/shared/utils/colorOpacity";

const HIDDEN_WITH = 0;
const VISIBLE_WIDTH = 870;
const SIDEBAR_Z_INDEX = 2;

interface WrapperProps {
  $isOpen: boolean;
  theme: DefaultTheme;
}

export const styles = {
  backdrop: {
    backgroundColor: (theme: DefaultTheme) =>
      colorOpacity(theme.colors.value_18, 0.25),
    zIndex: (theme: DefaultTheme) => theme.zIndex.drawer + SIDEBAR_Z_INDEX,
  },
};

export const Wrapper = styled.aside<WrapperProps>`
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: ${({ $isOpen, theme }) =>
    $isOpen
      ? theme.coefficient * VISIBLE_WIDTH
      : theme.coefficient * HIDDEN_WITH}px;
  top: ${({ theme }) => theme.coefficient * 72}px;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer + 3};
  background-color: ${({ theme }) => theme.colors.value_01};
  transition: width 400ms;
  box-shadow: -4px 4px 20px
    ${({ theme }) => colorOpacity(theme.colors.value_02, 0.05)};
`;
