export enum TableActionType {
  EDIT = "edit",
  DELETE = "delete",
  DUPLICATE = "duplicate",

  // temporary
  REQUEST_BIOMARKER = "request_biomarker",
  PLACE_ORDER = "place_order",
  SHOW_PRODUCT_INFO = "show_product_info",
  CUSTOM_REQUEST = "custom_request",
  ADD_SHIPPING_LOCATION = "add_shipping_location",
  DELETE_SHIPPING_LOCATION = "delete_shipping_location",
}
