import { sendRequest } from "@/shared/api/requestSender";
import { API_URL } from "@/shared/api/apiUrl";
import { HttpMethod, IChat } from "@/shared/types";

import { IGetManyChatsParams } from "../model/types/interfaces";

export const sendGetChatRequest = (
  body: Partial<IChat> | unknown
) =>
  sendRequest({
    path: `${API_URL.contactUs.getOne}`,
    method: HttpMethod.POST,
    data: body,
  });

export const sendUpdateChatRequest = (
  body: Partial<IChat> | unknown
) =>
  sendRequest({
    path: `${API_URL.contactUs.updateOne}`,
    method: HttpMethod.POST,
    data: body,
  });

export const sendChatMessageAuthorizedRequest = (
  body: Partial<IChat> | unknown
) =>
  sendRequest({
    path: `${API_URL.contactUs.createMessageAuthorized}`,
    method: HttpMethod.POST,
    data: body,
  });

export const sendDeleteChatRequest = (chatId: number) =>
  sendRequest({
    path: `${API_URL.contactUs.deleteOne}/${chatId}`,
    method: HttpMethod.DELETE,
  });

export const sendGetManyChatsRequest = (body: IGetManyChatsParams) =>
  sendRequest({
    path: API_URL.contactUs.getMany,
    method: HttpMethod.POST,
    data: body,
  });
