import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  CLEAR_AREA_ITEMS: `${SLICE_NAME}/clear_area_items`,
  AREA_ITEM_CLICKED: `${SLICE_NAME}/area_item_clicked`,
  GET_MANY_AREA_ITEMS: `${SLICE_NAME}/get_many_area_items`,
  GET_ALL_RESEARCH_AREAS: `${SLICE_NAME}/get_all_research_areas`,
  GET_ALL_BODY_SYSTEMS: `${SLICE_NAME}/get_all_body_systems`,
  RETRIEVE_MANY_BIOMARKERS: `${SLICE_NAME}/retrieve_many_biomarkers`,
  RETRIEVE_MANY_BIOMARKERS_BY_BODY_SYSTEM: `${SLICE_NAME}/retrieve_many_biomarkers_by_body_system`,
} as const;
