import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  GET_USER: `${SLICE_NAME}/get_user`,
  CREATE_USER: `${SLICE_NAME}/create_user`,
  UPDATE_USER: `${SLICE_NAME}/update_user`,
  DELETE_USER: `${SLICE_NAME}/delete_user`,
  GET_MANY_USERS: `${SLICE_NAME}/get_many_users`,
  UPDATE_USER_AVATAR: `${SLICE_NAME}/update_user_avatar`,
  DELETE_USER_AVATAR: `${SLICE_NAME}/delete_user_avatar`,
} as const;
