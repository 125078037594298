import {
  NAVIGATION_TABS_BO,
  NAVIGATION_TABS_CA,
} from "@/shared/config/navigation";
import { ITabItem, IUser, UserRole } from "@/shared/types";

export const getNavigationTabsByUserRole = (
  role: UserRole
): Array<ITabItem> | [] => {
  switch (role) {
    case UserRole.SUPER_ADMIN:
    case UserRole.ADMIN:
      return NAVIGATION_TABS_BO;
    case UserRole.CLIENT_MANAGER:
    case UserRole.CLIENT_USER:
      return NAVIGATION_TABS_CA;
    default:
      return [];
  }
};

export const getCurrentUser = (user: IUser) => {
  const {
    id,
    role,
    email,
    lastName,
    firstName,
    avatarUrl,
    dateOfBirth,
    phoneNumber,
  } = user;

  return {
    id,
    role,
    email,
    lastName,
    firstName,
    avatarUrl,
    dateOfBirth,
    phoneNumber,
  };
};
