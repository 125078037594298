import styled from "@emotion/styled";

interface ContainerProps {
  borderColor?: string;
  bgRgbColor?: string;
}

interface MessageProps {
  color?: string;
}

interface IconProps {
  backgroundColor?: string;
}

// export const IconCloseI = styled.div`
//   top: ${({ theme }) => theme.coefficient * 24}px;
//   right: ${({ theme }) => theme.coefficient * 40}px;
//   height: ${({ theme }) => theme.coefficient * 24}px;
//   width: ${({ theme }) => theme.coefficient * 24}px;
//   background-image: url("${({ theme }) => theme.images.close_var_1}");
//   background-size: auto;
//   background-repeat: no-repeat;
//   background-position: center;
// `;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.value_01};
  height: ${({ theme }) => theme.coefficient * 52}px;
  min-height: ${({ theme }) => theme.coefficient * 52}px;
  min-width: ${({ theme }) => theme.coefficient * 300}px;
  border-radius: ${({ theme }) => theme.coefficient * 6}px;
`;

export const Container = styled.div<ContainerProps>`
  border-radius: 6px;
  border: 1px solid
    ${({ theme, borderColor }) => theme.colors[borderColor || ""]};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.coefficient * 8}px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme, bgRgbColor }) => theme.rgb[bgRgbColor || ""]};
  padding: 0 ${({ theme }) => theme.coefficient * 20}px;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: ${({ theme }) => theme.coefficient * 8}px;
`;

export const IconContainer = styled.div`
  & div div {
    display: flex;
    align-items: center;
    width: ${({ theme }) => theme.coefficient * 32}px;
    height: ${({ theme }) => theme.coefficient * 32}px;
  }
`;

export const Message = styled.div<MessageProps>`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme, color }) => theme.colors[color || ""]};
  overflow-wrap: word-wrap;
`;

export const IconClose = styled.div<IconProps>`
  top: ${({ theme }) => theme.coefficient * 24}px;
  right: ${({ theme }) => theme.coefficient * 40}px;
  height: ${({ theme }) => theme.coefficient * 24}px;
  width: ${({ theme }) => theme.coefficient * 24}px;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor || ""]};
  background-image: ${({ theme }) => `url(${theme.images.close_var_2})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
`;
