import { call, put, select } from "redux-saga/effects";

import { AlertStatusMessage, IOrder, ToggleActionType } from '@/shared/types';

import { IGetManyOrdersParams } from "../model/types/interfaces";
import { selectParams } from "../model/selectors";
import {
  modifyOrdersData,
} from "./helpers";
import {
  sendManageOrderRequest,
  sendGetOrderRequest,
  getManyOrders, sendAssignOrderRequest,
} from './senders';
import { commonSlice } from "@/entities/common";
import { ordersSlice } from "../model/slice";
import { asideSagaActions, asideSlice } from '@/widgets/side-block';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AssignOrderPayload,
  GetOrderPayload,
  GetOrderResponse,
  UpdateOrderPayload,
} from '@/entities/client-orders/api/types/types';
import { getFullAddress } from '@/entities/client-companies/api/helpers';
import { t } from 'i18next';
import { alertsSagaActions } from '@/entities/alerts';

export function* handleRetrieveManyOrders() {
  const { setOrders } = ordersSlice;
  const { setLoading } = commonSlice;
  const body: IGetManyOrdersParams = yield select(selectParams);

  yield put(setLoading({ isLoading: true }));

  const { items, count } = yield call(getManyOrders, body);
  const modifiedOrders: Array<IOrder> = yield call(
    modifyOrdersData,
    items
  );

  yield put(setOrders({ orders: { items: modifiedOrders, count } }));
  yield put(setLoading({ isLoading: false }));
}

export function* handleRetrieveOrder({ payload }: PayloadAction<GetOrderPayload>) {
  const { orderId } = payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;

  const response: GetOrderResponse = yield call(sendGetOrderRequest, orderId);
  const order: IOrder = response.data.data;
;
  const fullAddress = getFullAddress(order?.shippingLocation?.address1, order?.shippingLocation?.address2, order?.shippingLocation?.city, order?.shippingLocation?.state, order?.shippingLocation?.zip) || ''

  const modifiedOrder = {
    ...order,
    fullAddress,
  };

  yield put(setActiveEntity({ activeEntity: modifiedOrder }));
  yield put(setLoadingAside({ isLoading: false }));
}

export function* handleAssignOrder({ payload }: PayloadAction<AssignOrderPayload>) {
  const { orderId } = payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;

  const response: GetOrderResponse = yield call(sendAssignOrderRequest, orderId);
  const order: IOrder = response.data.data;

  const fullAddress = getFullAddress(order?.shippingLocation?.address1, order?.shippingLocation?.address2, order?.shippingLocation?.city, order?.shippingLocation?.state, order?.shippingLocation?.zip) || ''

  const modifiedOrder = {
    ...order,
    fullAddress,
  };

  yield put(setActiveEntity({ activeEntity: modifiedOrder }));
  yield put(setLoadingAside({ isLoading: false }));
  yield call(handleRetrieveManyOrders);
}

export function* handleManageOrder({ payload }: PayloadAction<UpdateOrderPayload>) {
  const { orderId, formData } = payload;
  const { setLoadingAside } = asideSlice;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  yield call(sendManageOrderRequest, orderId, formData);

  yield put(setLoadingAside({ isLoading: false }));
  yield put(
    updateAlert({
      status: AlertStatusMessage.SUCCESS,
      message: `${t("order_updated_successfully")}`,
    })
  );
  yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
}
