import { combineReducers } from "@reduxjs/toolkit";

import {
  authReducer,
  usersReducer,
  chatsReducer,
  commonReducer,
  alertsReducer,
  ordersReducer,
  samplesReducer,
  vendorsReducer,
  invoicesReducer,
  biomarkersReducer,
  researchAreasReducer,
  clientCompaniesReducer,
  storageFacilitiesReducer,
} from "@/entities";

import { tableReducer } from "@/widgets/table";
import { asideReducer } from "@/widgets/side-block";
import { dialogReducer } from "@/widgets/dialog";

import { selectReducer } from "@/features";

export const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  aside: asideReducer,
  table: tableReducer,
  chats: chatsReducer,
  orders: ordersReducer,
  dialog: dialogReducer,
  select: selectReducer,
  common: commonReducer,
  alerts: alertsReducer,
  samples: samplesReducer,
  vendors: vendorsReducer,
  invoices: invoicesReducer,
  biomarkers: biomarkersReducer,
  researchAreas: researchAreasReducer,
  clientCompanies: clientCompaniesReducer,
  storageFacilities: storageFacilitiesReducer,
});
