import { ILoincBiomarker, RootState } from '@/shared/types';
import {
  IAreaItemsData,
  IResearchAreasData,
  ILoincBiomarkersData, IGetManyBiomarkersParams,
} from './types/interfaces';

export const selectResearchAreas = (state: RootState): IResearchAreasData =>
  state.researchAreas.data;

export const selectAreaItems = (state: RootState): IAreaItemsData | null =>
  state.researchAreas.areaItems;

export const selectBodySystems = (state: RootState): IResearchAreasData =>
  state.researchAreas.bodySystems;

export const selectLoincBiomarkers = (
  state: RootState
): ILoincBiomarkersData => state.researchAreas.loincBiomarkers;

export const selectParams = (state: RootState): IGetManyBiomarkersParams =>
  state.researchAreas.params;

export const selectActiveBiomarker = (state: RootState): ILoincBiomarker | null =>
  state.researchAreas.activeLoincBiomarker

export const selectActiveAreaName = (state: RootState): string =>
  state.researchAreas.activeAreaName
