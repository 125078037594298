import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleCreateUser,
  handleUpdateUser,
  handleDeleteUser,
  handleRetrieveUser,
  handleUpdateUserAvatar,
  handleDeleteUserAvatar,
  handleRetrieveManyUsers,
} from './workers';

export function* watcher() {
  yield takeLatest(ActionType.GET_USER, handleRetrieveUser);
  yield takeLatest(ActionType.CREATE_USER, handleCreateUser);
  yield takeLatest(ActionType.UPDATE_USER, handleUpdateUser);
  yield takeLatest(ActionType.DELETE_USER, handleDeleteUser);
  yield takeLatest(ActionType.GET_MANY_USERS, handleRetrieveManyUsers);
  yield takeLatest(ActionType.UPDATE_USER_AVATAR, handleUpdateUserAvatar);
  yield takeLatest(ActionType.DELETE_USER_AVATAR, handleDeleteUserAvatar);
}
