import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME, InitialStateType, CaseReducers } from "./types/types";
import { EntityType } from "@/shared/types";

const INITIAL_STATE: InitialStateType = {
  settings: {
    currentEntity: EntityType.USER,
  },
};

export const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSettings: (draft, { payload }) => {
      draft.settings = payload.settings;
    },
  },
});

export const tableSlice = slice.actions;
export const tableReducer = slice.reducer;
