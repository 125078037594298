import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleCreateLoincRange,
  handleRetrieveBiomarker,
  handleDeleteLoincRanges,
  handleRetrieveManyBiomarkers,
} from "./workers";

export function* watcher() {
  yield takeLatest(
    ActionType.RETRIEVE_MANY_BIOMARKERS,
    handleRetrieveManyBiomarkers
  );
  yield takeLatest(ActionType.RETRIEVE_BIOMARKER, handleRetrieveBiomarker);
  yield takeLatest(ActionType.CREATE_LOINC_RANGE, handleCreateLoincRange);
  yield takeLatest(ActionType.DELETE_LOINC_RANGES, handleDeleteLoincRanges);
  // yield takeLatest(ActionType.TABLE_ROW_CLICKED, handleTableRowClicked);
  // yield takeLatest(
  //   ActionType.SHOW_ALL_TOGGLE_SWITCHED,
  //   handleShowAllToggleSwitched
  // );
  // yield takeLatest(
  //   ActionType.VIEW_ROW_BUTTON_CLICKED,
  //   handleViewRowButtonClicked
  // );
  // yield takeLatest(
  //   ActionType.REQUEST_ROW_BUTTON_CLICKED,
  //   handleRequestRowButtonClicked
  // );
}
