import { GetManySamplesStatus } from "@/shared/types";
import { DateTime } from "luxon";

function getCashFormat(value: number): number {
  return parseFloat(value.toFixed(2));
}

export const getCreateDtoByFormData = (formData: any) => {
  const a = {
    accession: formData.accession,
    biobankId: formData.biobankId,
    collectionDate: formData.collectionDate
      ? formatDate(formData.collectionDate)
      : undefined,
    donorDob: formData.donorDob ? formatDate(formData.donorDob) : undefined,
    donorEthnicity: formData.donorEthnicity,
    donorGender: formData.donorGender,
    donorZip: formData.donorZip,
    expirationDate: formData.expirationDate
      ? formatDate(formData.expirationDate)
      : undefined,
    invoiceId: +formData.invoiceId,
    loincBiomarker: formData.loincBiomarker,
    loincCode: formData.loincBiomarker?.id,
    specimenPrice: getCashFormat(+formData.specimenPrice),
    status: formData.status ? formData.status : GetManySamplesStatus.AVAILABLE,
    storageCondition: formData.storageCondition,
    storageContainer: formData.storageContainer,
    storageFacilityId: +formData.storageFacilityId,
    storageLocation: formData.storageLocation,
    volumeNumber: +formData.volumeNumber,
    volumeUnits: formData.volumeUnits,
  };
  return a;
};

export const getUpdateDtoByFormData = (formData: any) => {
  const a = {
    accession: formData.accession,
    biobankId: formData.biobankId,
    collectionDate: formData.collectionDate || null,
    donorDob: formData.donorDob  || null,
    donorEthnicity: formData.donorEthnicity,
    donorGender: formData.donorGender,
    donorZip: formData.donorZip,
    expirationDate: formData.expirationDate || null,
    invoiceId: +formData.invoiceId,
    loincBiomarker: formData.loincBiomarker,
    loincCode: formData.loincBiomarker?.id,
    specimenPrice: getCashFormat(+formData.specimenPrice),
    status: formData.status,
    storageCondition: formData.storageCondition,
    storageContainer: formData.storageContainer,
    storageFacilityId: +formData.storageFacilityId,
    storageLocation: formData.storageLocation,
    volumeNumber: +formData.volumeNumber,
    volumeUnits: formData.volumeUnits,
  };
  return a;
};

export const parseDate = (
  isoString: string,
  timeZone = "UTC+2"
): string | null => {
  try {
    const utcDateTime = DateTime.fromISO(isoString, { zone: "utc" });
    const localDateTimeInMillis = utcDateTime.setZone(timeZone).toMillis();
    const ts = DateTime.fromMillis(localDateTimeInMillis);
    return ts.toFormat("MM/dd/yyyy");
  } catch (error) {
    console.error("Invalid date string or time zone:", error);
    return null;
  }
};

const formatDate = (formatDate: string): string | null => {
  const localDateTime = DateTime.fromFormat(formatDate, "MM/dd/yyyy");
  const utcDateTime = localDateTime.setZone("utc");

  return utcDateTime.toISO({ includeOffset: false }); // e.g., 2024-07-16T10:36:13.385Z
};
