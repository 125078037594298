import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ISamplesData, IGetManySamplesParams } from "./interfaces";

export const SLICE_NAME = "samples";

export type InitialStateType = {
  data: ISamplesData;
  params: IGetManySamplesParams;
  availableSamples: any;
};

type SamplesDataPayload = {
  data: ISamplesData;
};

type AvailableSamplesDataPayload = {
  data: any;
};

type GetManySamplesParamsPayload = {
  params: IGetManySamplesParams;
};

export type CaseReducers<State> = {
  setParams: CaseReducer<State, PayloadAction<GetManySamplesParamsPayload>>;
  setSamples: CaseReducer<State, PayloadAction<SamplesDataPayload>>;
  setAvailableSamples: CaseReducer<State, PayloadAction<AvailableSamplesDataPayload>>;
};
