import { all, fork } from "redux-saga/effects";
import {
  authWatcher,
  usersWatcher,
  chatsWatcher,
  ordersWatcher,
  alertsWatcher,
  commonWatcher,
  vendorsWatcher,
  samplesWatcher,
  invoicesWatcher,
  biomarkersWatcher,
  researchAreasWatcher,
  clientCompaniesWatcher,
  storageFacilitiesWatcher,
} from "@/entities";

import { tableWatcher } from "@/widgets/table";
import { asideWatcher } from "@/widgets/side-block";
import { dialogWatcher } from "@/widgets/dialog";

import { selectWatcher } from "@/features";

export function* rootSaga() {
  yield all([fork(authWatcher)]);
  yield all([fork(usersWatcher)]);
  yield all([fork(chatsWatcher)]);
  yield all([fork(ordersWatcher)]);
  yield all([fork(asideWatcher)]);
  yield all([fork(tableWatcher)]);
  yield all([fork(dialogWatcher)]);
  yield all([fork(selectWatcher)]);
  yield all([fork(alertsWatcher)]);
  yield all([fork(commonWatcher)]);
  yield all([fork(vendorsWatcher)]);
  yield all([fork(samplesWatcher)]);
  yield all([fork(invoicesWatcher)]);
  yield all([fork(biomarkersWatcher)]);
  yield all([fork(researchAreasWatcher)]);
  yield all([fork(clientCompaniesWatcher)]);
  yield all([fork(storageFacilitiesWatcher)]);
}
