import { IconButton } from "@mui/material";
import styled from "@emotion/styled";
import colorOpacity from "@/shared/utils/colorOpacity";

const HEADER_HEIGHT = 88;

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.coefficient * HEADER_HEIGHT}px;
  box-shadow: 0 4px 40px
    ${({ theme }) => colorOpacity(theme.colors.value_18, 0.15)};
  font-size: 2.4rem;
  font-weight: 500;
  padding: 0 ${({ theme }) => theme.coefficient * 40}px 0
    ${({ theme }) => theme.coefficient * 40}px;
  position: relative;
  z-index: 1;
`;

export const CustomIconButton = styled(IconButton)`
  &:hover {
    background-color: ${({ theme }) => theme.rgb.value_02};
  }
`;

// export const IconClose = styled.div`
//   top: ${({ theme }) => theme.coefficient * 24}px;
//   right: ${({ theme }) => theme.coefficient * 40}px;
//   height: ${({ theme }) => theme.coefficient * 30}px;
//   width: ${({ theme }) => theme.coefficient * 30}px;
//   background-image: url("${({ theme }) => theme.images.close_var_2}");
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-color: ${({ theme }) => theme.colors.value_29};
//   border-radius: 50%;
// `;
