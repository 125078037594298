import styled from "@emotion/styled";

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${({ theme }) => `
    ${theme.coefficient * 24}px 
    ${theme.coefficient * 40}px 
    0px
    ${theme.coefficient * 40}px
  `};
`;
