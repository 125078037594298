import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

import {
  IVendor,
  IApiError,
  ToggleActionType,
  AlertStatusMessage,
} from "@/shared/types";

import { commonSlice } from "@/entities/common";
import { alertsSagaActions } from "@/entities/alerts";

import { asideSagaActions, asideSlice } from "@/widgets/side-block";
import { dialogSagaActions, dialogSlice } from "@/widgets/dialog";

import { selectParams } from "../model/selectors";
import { vendorsSlice } from "../model/slice";
import {
  IVendorModified,
  IGetManyVendorsParams,
} from "../model/types/interfaces";
import {
  sendGetVendorRequest,
  sendCreateVendorRequest,
  sendUpdateVendorRequest,
  sendDeleteVendorRequest,
  sendGetManyVendorsRequest,
} from "./senders";
import {
  modifyVendorData,
  modifyVendorsData,
  getCreateDtoByFormData,
  getUpdateDtoByFormData,
} from "./helpers";
import {
  GetVendorPayload,
  GetVendorResponse,
  CreateVendorPayload,
  UpdateVendorPayload,
  DeleteVendorPayload,
  GetManyVendorsPayload,
  GetManyVendorsResponse,
} from "./types/types";

export function* handleCreateVendor(
  action: PayloadAction<CreateVendorPayload>
) {
  const { formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const createDto: Partial<IVendor> = yield call(
    getCreateDtoByFormData,
    formData
  );

  try {
    yield call(sendCreateVendorRequest, createDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("vendor_created_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyVendors);
  }
}

export function* handleUpdateVendor(
  action: PayloadAction<UpdateVendorPayload>
) {
  const { vendorId, formData } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleSideBlock } = asideSagaActions;

  const updateDto: Partial<IVendor> = yield call(
    getUpdateDtoByFormData,
    formData
  );

  try {
    yield call(sendUpdateVendorRequest, vendorId, updateDto);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("vendor_updated_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleSideBlock({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyVendors);
  }
}


export function* handleDeleteVendor(
  action: PayloadAction<DeleteVendorPayload>
) {
  const { vendorId } = action.payload;
  const { updateAlert } = alertsSagaActions;
  const { toggleDialog } = dialogSagaActions;

  try {
    yield call(sendDeleteVendorRequest, vendorId);
    yield put(
      updateAlert({
        status: AlertStatusMessage.SUCCESS,
        message: `${t("vendor_deleted_successfully")}`,
      })
    );
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
    yield call(handleRetrieveManyVendors);
  }
}

export function* handleRetrieveVendor(action: PayloadAction<GetVendorPayload>) {
  const { vendorId } = action.payload;
  const { setActiveEntity } = commonSlice;
  const { setLoadingAside } = asideSlice;
  const { setLoadingDialog } = dialogSlice;

  try {
    const response: GetVendorResponse = yield call(
      sendGetVendorRequest,
      vendorId
    );
    const vendor: IVendor = response.data.data;
    const modifiedVendor: IVendorModified = yield call(
      modifyVendorData,
      vendor
    );
    yield put(setActiveEntity({ activeEntity: modifiedVendor }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoadingAside({ isLoading: false }));
    yield put(setLoadingDialog({ isLoading: false }));
  }
}

export function* handleRetrieveManyVendors(
  action?: PayloadAction<GetManyVendorsPayload>
) {
  const { setVendors, setParams } = vendorsSlice;
  const { setLoading } = commonSlice;

  if (action?.payload?.params) {
    yield put(setParams({ params: action.payload.params }));
  }

  try {
    yield put(setLoading({ isLoading: true }));
    const bodyParams: IGetManyVendorsParams = yield select(selectParams);
    const response: GetManyVendorsResponse = yield call(
      sendGetManyVendorsRequest,
      bodyParams
    );
    const { items, count } = response.data.data;
    const modifiedVendors: Array<IVendorModified> = yield call(
      modifyVendorsData,
      items
    );
    yield put(setVendors({ data: { items: modifiedVendors, count } }));
  } catch (err) {
    yield call(handleError, err);
  } finally {
    yield put(setLoading({ isLoading: false }));
  }
}

function* handleError(err: any) {
  const { updateAlert } = alertsSagaActions;
  const error = err as IApiError;

  if (error?.response?.data?.error?.message?.length) {
    for (const text of error.response.data.error.message) {
      yield put(
        updateAlert({ status: AlertStatusMessage.ERROR, message: text })
      );
    }
  }
}
