import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { IVendorsData, IGetManyVendorsParams } from "./interfaces";

export const SLICE_NAME = "vendors";

export type InitialStateType = {
  data: IVendorsData;
  params: IGetManyVendorsParams;
};

type VendorsDataPayload = {
  data: IVendorsData;
};

type GetManyVendorsParamsPayload = {
  params: IGetManyVendorsParams;
};

export type CaseReducers<State> = {
  setParams: CaseReducer<State, PayloadAction<GetManyVendorsParamsPayload>>;
  setVendors: CaseReducer<State, PayloadAction<VendorsDataPayload>>;
};
