import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import {
  GetCompanyPayload,
  CreateClientCompanyPayload,
  DeleteClientCompanyPayload,
  UpdateClientCompanyPayload,
  CreateShippingLocationPayload,
  UpdateShippingLocationPayload,
  DeleteShippingLocationPayload,
} from './types/types';

const retrieveManyShippingLocations = createAction(ActionType.RETRIEVE_MANY_SHIPPING_LOCATIONS);
const retrieveManyCompanies = createAction(ActionType.RETRIEVE_MANY_COMPANIES);
const retrieveCompany = createAction<GetCompanyPayload>(ActionType.RETRIEVE_COMPANY);
const updateCompany = createAction<UpdateClientCompanyPayload>(ActionType.UPDATE_COMPANY);
const createCompany = createAction<CreateClientCompanyPayload>(ActionType.CREATE_COMPANY);
const deleteCompany = createAction<DeleteClientCompanyPayload>(ActionType.DELETE_COMPANY);
const updateShippingLocation = createAction<UpdateShippingLocationPayload>(ActionType.UPDATE_SHIPPING_LOCATION);
const createShippingLocation = createAction<CreateShippingLocationPayload>(ActionType.CREATE_SHIPPING_LOCATION);
const deleteShippingLocation = createAction<DeleteShippingLocationPayload>(ActionType.DELETE_SHIPPING_LOCATION);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      retrieveManyShippingLocations,
      retrieveManyCompanies,
      retrieveCompany,
      updateCompany,
      createCompany,
      deleteCompany,
      updateShippingLocation,
      createShippingLocation,
      deleteShippingLocation,
    },
    dispatch
  );
};

export const sagaActions = {
  retrieveManyShippingLocations,
  retrieveManyCompanies,
  retrieveCompany,
  updateCompany,
  createCompany,
  deleteCompany,
  updateShippingLocation,
  createShippingLocation,
  deleteShippingLocation,
};
