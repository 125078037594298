import styled from "styled-components";

export const Wrapper = styled.nav`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 20px ${({ theme }) => theme.rgb.value_03};
`;

export const LogoContainer = styled.div`
  display: flex;
  min-width: ${({ theme }) => theme.coefficient * 150}px;
  height: ${({ theme }) => theme.coefficient * 52}px;
  margin: 0 ${({ theme }) => theme.coefficient * 40}px 0 0;
  cursor: pointer;
  border: none;
`;

export const Logo = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ theme }) => `url(${theme.images.appLogo})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;
