import { NavBar } from "@/widgets";
import { Content, UpContainer, Wrapper } from "./styles";

type ClientAreaLayoutProps = {
  isMobile: boolean;
  children: React.ReactNode;
};

export function ClientAreaLayout({
  isMobile,
  children,
}: ClientAreaLayoutProps): JSX.Element {
  return (
    <Wrapper>
      {isMobile ? (
        <>
          <Content>THIS IS MOBILE</Content>
        </>
      ) : (
        <>
          <UpContainer>
            <NavBar />
          </UpContainer>
          <Content>{children}</Content>
        </>
      )}
    </Wrapper>
  );
}
