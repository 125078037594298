import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { BaseLayout } from "@/app/layouts";
import { UserRole } from "@/shared/types/enums/UserRole.enum";
import { ROUTER_PATHS } from "@/shared/config/router-paths";
import { selectAuthParams } from "@/entities/auth/model/selectors";
import {
  selectActiveTab,
  selectCurrentUser,
} from "@/entities/common/model/selectors";

import { AuthRouter } from "./auth/AuthRouter";
import { BackOfficeRouter } from "./back-office/BackOfficeRouter";
import { ClientAreaRouter } from "./client-area/ClientAreaRouter";

export function AppRouterProvider(): JSX.Element {
  const navigate = useNavigate();
  const { activeScreen, authParams, currentUser } = {
    authParams: useSelector(selectAuthParams),
    currentUser: useSelector(selectCurrentUser),
    activeScreen: useSelector(selectActiveTab),
  };

  const getDefaultPathByUserRole = (role?: UserRole): string => {
    switch (role) {
      case UserRole.SUPER_ADMIN:
      case UserRole.ADMIN:
        return ROUTER_PATHS.backOffice.defaultPath;
      case UserRole.CLIENT_MANAGER:
      case UserRole.CLIENT_USER:
        return ROUTER_PATHS.clientArea.defaultPath;
      default:
        return ROUTER_PATHS.auth.defaultPath;
    }
    // ToDo add other Vendor Operator
    // return ROUTE_PATH.clientArea.specimenSearch.path;
  };

  const getRouterByUserRole = (role?: UserRole) => {
    switch (role) {
      case UserRole.SUPER_ADMIN:
      case UserRole.ADMIN:
        return <Route path="/*" element={<BackOfficeRouter />} />;
      case UserRole.CLIENT_MANAGER:
      case UserRole.CLIENT_USER:
        return <Route path="/*" element={<ClientAreaRouter />} />;
      default:
        return <Route path="/*" element={<AuthRouter />} />;
    }
  };

  useEffect(() => {
    if (!authParams.isAuth) {
      navigate(ROUTER_PATHS.auth.defaultPath);
      return;
    }
    if (activeScreen) {
      navigate(activeScreen.path);
    } else {
      navigate(getDefaultPathByUserRole(currentUser.role));
    }
  }, [authParams, navigate, activeScreen, currentUser]);

  return (
    <BaseLayout role={currentUser.role}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            index
            element={<Navigate to={ROUTER_PATHS.defaultPath} replace />}
          />
          {getRouterByUserRole(currentUser.role)}
        </Routes>
      </Suspense>
    </BaseLayout>
  );
}
