import React, { useEffect } from "react";

import { IGetManyParams } from "@/shared/types";

import { CustomTable } from "@/shared/components/customTableGrid";

type TableAdapterProps = {
  data: any;
  count: number;
  subRow: React.FC;
  config: object;
  params: IGetManyParams;
  getData: any;
  columns: object;
  rowCells: React.FC;
  setParams: any;
  isLoading: boolean;
  activeEntity: any;
  setTableEntity: any;
  tableRowClicked: any;
};

export function TableAdapter({
  data,
  count,
  subRow,
  config,
  params,
  getData,
  columns,
  rowCells,
  setParams,
  isLoading,
  activeEntity,
  setTableEntity,
  tableRowClicked,
}: TableAdapterProps): React.ReactElement {
  const handleIsRowActive = (rowEntity: any) => {
    return rowEntity?.id === activeEntity?.id;
  };

  const onClickRowCallback = (rowEntity: any) => {
    if (tableRowClicked) tableRowClicked({ rowItem: rowEntity });
  };

  const onGetDataCallback = (params: IGetManyParams) => {
    setParams(params);
    getData();
  };

  const onSortCallback = ({ params }: any) => {
    setParams(params);
    getData();
  };

  const onPaginationCallback = (_: any, params: any) => {
    setParams(params);
    getData();
  };

  useEffect(() => {
    setTableEntity();
  }, [setTableEntity]);

  return (

    <CustomTable
      skip={params?.skip}
      rows={data}
      count={count}
      limit={params?.limit}
      config={config}
      onSort={onSortCallback}
      columns={columns}
      onRequest={onGetDataCallback}
      isClickRow={() => tableRowClicked}
      isLoading={isLoading}
      onClickRow={onClickRowCallback}
      isRowActive={handleIsRowActive}
      renderSubRow={subRow}
      onPagination={onPaginationCallback}
      renderBodyCell={rowCells}
    />
  );
}
