import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";

import { IEntity } from "@/shared/types";

import { ActionType } from "./types/actionType";
import {
  TableRowPayload,
  ControlButtonPayload,
  ControlTogglePayload,
  TableActionButtonPayload,
} from "./types/types";

const tableRowClicked = createAction<TableRowPayload<IEntity>>(
  ActionType.TABLE_ROW_CLICKED
);

const tableActionButtonClicked = createAction<
  TableActionButtonPayload<IEntity>
>(ActionType.TABLE_ACTION_BUTTON_CLICKED);

const controlToggleSwitched = createAction<ControlTogglePayload>(
  ActionType.CONTROL_TOGGLE_SWITCHED
);

const controlCreateButtonClicked = createAction<ControlButtonPayload>(
  ActionType.CONTROL_CREATE_BUTTON_CLICKED
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      tableRowClicked,
      tableActionButtonClicked,
      controlToggleSwitched,
      controlCreateButtonClicked,
    },
    dispatch
  );
};

export const sagaActions = {
  tableRowClicked,
  tableActionButtonClicked,
  controlToggleSwitched,
  controlCreateButtonClicked,
};
