import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { ActionType } from "./types/ActionType";
import { CreatePayload, SavePayload, ToggleAsidePayload } from "./types/types";

const toggleSideBlock = createAction<ToggleAsidePayload>(
  ActionType.TOGGLE_SIDE_BLOCK
);

const createButtonClicked = createAction<CreatePayload>(
  ActionType.CREATE_BUTTON_CLICKED
);

const saveButtonClicked = createAction<SavePayload>(
  ActionType.SAVE_BUTTON_CLICKED
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      toggleSideBlock,
      saveButtonClicked,
      createButtonClicked,
    },
    dispatch
  );
};

export const sagaActions = {
  toggleSideBlock,
  saveButtonClicked,
  createButtonClicked,
};
