import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import {
  CreateStorageFacilityPayload,
  DeleteStorageFacilityPayload,
  UpdateStorageFacilityPayload,
  ClickedRowItemPayload,
} from "./types/types";
import { useDispatch } from "react-redux";

const retrieveManyStorageFacilities = createAction(
  ActionType.RETRIEVE_MANY_STORAGE_FACILITIES
);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      retrieveManyStorageFacilities,
    },
    dispatch
  );
};

export const sagaActions = {
  retrieveManyStorageFacilities,
};
