import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IGetManyClientCompaniesParams, IClientCompaniesData } from "./interfaces";

export const SLICE_NAME = "clientCompanies";

export type InitialStateType = {
  data: IClientCompaniesData;
  shippingLocations: IClientCompaniesData;
  params: IGetManyClientCompaniesParams;
};

type ClientCompaniesDataPayload = {
  clientCompanies: IClientCompaniesData;
};

type ShippingLocationsDataPayload = {
  shippingLocations: IClientCompaniesData;
};

type GetManyClientCompaniesParamsPayload = {
  params: IGetManyClientCompaniesParams;
};

export type CaseReducers<State> = {
  setParams: CaseReducer<State, PayloadAction<GetManyClientCompaniesParamsPayload>>;
  setClientCompanies: CaseReducer<State, PayloadAction<ClientCompaniesDataPayload>>;
  setShippingLocations: CaseReducer<State, PayloadAction<ShippingLocationsDataPayload>>;
};
