import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  RETRIEVE_MANY_SHIPPING_LOCATIONS: `${SLICE_NAME}/retrieve_many_shipping_locations`,
  RETRIEVE_MANY_COMPANIES: `${SLICE_NAME}/retrieve_many_companies`,
  RETRIEVE_COMPANY: `${SLICE_NAME}/retrieve_company`,
  UPDATE_COMPANY: `${SLICE_NAME}/update_company`,
  CREATE_COMPANY: `${SLICE_NAME}/create_company`,
  DELETE_COMPANY: `${SLICE_NAME}/delete_company`,
  UPDATE_SHIPPING_LOCATION: `${SLICE_NAME}/update_shipping_location`,
  CREATE_SHIPPING_LOCATION: `${SLICE_NAME}/create_shipping_location`,
  DELETE_SHIPPING_LOCATION: `${SLICE_NAME}/delete_shipping_location`,
} as const;
